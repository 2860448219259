import request from '@/services'
import { useMutation } from 'react-query'

export const useAddSession = (options?: any) =>
  useMutation(body =>
    request('/sessionInfo', {
      method: 'POST',
      body,
    }),
    options
  )

export const useEditSession = (options?: any) =>
  useMutation(body =>
    request('/sessionInfo', {
      method: 'PUT',
      body,
    }),
    options
  )

export const useDeleteSession = (options?: any) =>
  useMutation((body: { id: number }) =>
    request('/sessionInfo', {
      method: 'DELETE',
      body,
    }),
    options
  )
