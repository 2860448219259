import React from 'react'
import styled from 'styled-components'

type LabelItemProps = {
  label: string
  children: React.ReactChild | React.ReactChild[] | any
  className?: string
}

const LabelItem = React.memo<LabelItemProps>(props => {
  const { label, children, className } = props

  return (
    <LabelItemStyled className={className}>
      <label>{label}</label>
      {children}
    </LabelItemStyled>
  )
})

const LabelItemStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
  > label {
    color: ${p => p.theme.darkGrey};
    font-size: 12px;
  }
`

export default LabelItem
