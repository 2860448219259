import { SVGProps } from 'react'
import cx from 'classnames'

const NoteSVG = ({ className = '', ...props }: SVGProps<any>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <path
      d="M8 0a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8zm-.102 12.697a.677.677 0 1 1 .001-1.354.677.677 0 0 1 0 1.354zm2.904-6.588c-.04.724-.261 1.294-1.37 2.403-.563.563-.918.985-.954 1.355a.563.563 0 1 1-1.12-.11c.076-.78.648-1.41 1.278-2.04C9.644 6.71 9.66 6.39 9.678 6.05a1.447 1.447 0 0 0-.412-1.08 1.756 1.756 0 0 0-1.27-.54h-.002a1.682 1.682 0 0 0-1.677 1.68.562.562 0 1 1-1.125 0c0-.75.29-1.452.819-1.982a2.785 2.785 0 0 1 1.98-.823 2.887 2.887 0 0 1 2.09.89c.503.527.758 1.207.72 1.914z"
      fill="#000"
      fillRule="nonzero"
      opacity=".65"
    />
  </svg>
)

export default NoteSVG
