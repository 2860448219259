import request, {
  MutationOptions,
  useRequest,
  Mutation,
  useMakeMutation,
} from '@/services'
import { DeviceType } from '@/constants'
import { Device } from './device.types'

interface UseImportDeviceOptions extends MutationOptions {
  deviceType: DeviceType
}

export const useImportDevice = (options: UseImportDeviceOptions) => {
  const { deviceType, ...rest } = options
  const { loading, mutate } = useRequest(`/device/${deviceType}/import`, {
    ...rest,
    method: 'POST',
    headers: {},
  })

  const handler = (data: { file: File }) => {
    const formData = new FormData()
    formData.append('file', data.file)
    return mutate(formData)
  }

  return [loading, handler] as Mutation
}

export const useUpdateDevice = (options: MutationOptions = {}): Mutation =>
  useMakeMutation(
    (body: { deviceType: string } & Device) =>
      request('/device/{deviceType}/{id}', {
        method: 'PUT',
        body,
      }),
    options
  )
