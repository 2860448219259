import React from 'react'
import { atom, useRecoilState } from 'recoil'
import { Alarm } from '@/alarm/use-alarm'
import usePrevious from '@/hooks/usePrevious'

export type AlarmItem = {
  deviceType: string
  id?: number
  detail?: Alarm
}

type ContextType = {
  map: google.maps.Map | null
  isLoaded?: boolean
  handleMapLoad: (map: google.maps.Map) => any
  handleOpenedInfoBox: (item: any) => void
  handleActivedMarker: (item: any) => void
  cancelActivedMarker: () => void
  handleClosedAction: () => void
  handleAlarmItem: (item: any) => void
  alarmItem?: AlarmItem
  deviceId?: string // deviceType-id:string
  handleDeviceId?: (value: string | null) => void
}

const MapContext = React.createContext<ContextType>({
  map: null,
  handleMapLoad: (map: google.maps.Map) => ({}),
  handleOpenedInfoBox: (item: any) => {},
  handleActivedMarker: (item: any) => {},
  handleAlarmItem: (item: AlarmItem) => {},
  cancelActivedMarker: () => {},
  handleClosedAction: () => {},
  handleDeviceId: (value: string | null) => {},
})

export const MapDeviceIdState = atom<string | null>({
  key: 'InnmaxDeviceIdState',
  default: null,
})

export const MapAlarmItemState = atom<AlarmItem | null>({
  key: 'InnmaxMapAlarmItemState',
  default: null,
})

export function GoogleMapProvider(props: any) {
  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [openedInfoBox, setOpenedInfoBox] = React.useState<any>(null)
  const [activedMarker, setActivedMarker] = React.useState<any>(null)
  const [, setDeviceId] = useRecoilState(MapDeviceIdState)
  const [, setAlarmItem] = useRecoilState(MapAlarmItemState)

  const handleMapLoad = React.useCallback((mapInstance: google.maps.Map) => {
    setMap(mapInstance)
    setIsLoaded(true)
    setActivedMarker(null)
    setOpenedInfoBox(null)
  }, [])

  const prevOpenedInfoBox: any = usePrevious(openedInfoBox)
  const prevActivedMarker: any = usePrevious(activedMarker)

  const removeAnimation = React.useCallback((marker: any) => {
    const url = (marker.getIcon() as any)?.url
    const el = document.querySelector(`img[src="${url}"]`)
    if (el) {
      const _el = el.parentNode as HTMLElement
      setTimeout(() => _el.classList.remove('icon-animation'))
    } else {
      setTimeout(() => removeAnimation(marker), 500)
    }
  }, [])

  React.useEffect(() => {
    if (prevOpenedInfoBox) {
      prevOpenedInfoBox?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedInfoBox])

  React.useEffect(() => {
    if (prevActivedMarker) {
      removeAnimation(prevActivedMarker)
      // prevActivedMarker?.setAnimation(null)
      // handle cancel actived marker
      if (!activedMarker) {
        setAlarmItem(null)
        setDeviceId(null)
        setOpenedInfoBox(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activedMarker])

  let value = React.useMemo(() => {
    return {
      map,
      isLoaded,
      handleMapLoad,
      handleOpenedInfoBox: (item: any) => setOpenedInfoBox(item),
      handleActivedMarker: (m: any) => setActivedMarker(m),
      handleAlarmItem: setAlarmItem,
      cancelActivedMarker: () => setActivedMarker(null),
      handleClosedAction: () => setActivedMarker(null),
      handleDeviceId: setDeviceId,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isLoaded, handleMapLoad])

  return <MapContext.Provider value={value} {...props} />
}

export function useGoogleMap() {
  const context = React.useContext(MapContext)
  if (!context) {
    throw new Error('useGoogleMap must be within a MapProvider')
  }

  return context
}

export default useGoogleMap
