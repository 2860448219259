export enum DeviceType {
  LIGHT = 'light',
  BOX = 'switchbox',
  TALKIE = 'visualdoorbell',
  CAMERA = 'camera',
  WDETECT = 'waterlevel',
  LOCATION = 'location',
  ATTACHED = 'attached',
  DISPLAYSCREEN = 'displayscreen',
  GATEWAY = 'gateway',
  INVERTER = 'inverter',
  AMMETER = 'ammeter', //智慧電表
  CHILLER = 'chiller', //冷水機
  AIRCONDITIONER = 'airConditioner', //冷氣機
}
