import InternalGoogleMap, { MapProps } from './GoogleMap'
import Marker, { MapMarkerProps } from './Marker'
import { useGoogleMap, GoogleMapProvider, AlarmItem, MapAlarmItemState, MapDeviceIdState } from './GoogleMapProvider'
import { MarkerImageType, MarkerIconKey } from '../../components/Marker'

type InternalGoogleMapType = typeof InternalGoogleMap

interface GoogleMapInterface extends InternalGoogleMapType {
  Provider: typeof GoogleMapProvider
  Marker: typeof Marker
  useGoogleMap: typeof useGoogleMap
}

const GoogleMap = InternalGoogleMap as GoogleMapInterface

GoogleMap.Provider = GoogleMapProvider
GoogleMap.Marker = Marker
GoogleMap.useGoogleMap = useGoogleMap

export type {
  MapProps,
  MapMarkerProps,
  MarkerImageType,
  MarkerIconKey,
  AlarmItem,
}

export {
  MapAlarmItemState,
  MapDeviceIdState,
}

export default GoogleMap
