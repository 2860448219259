import request, { Mutation, MutationOptions, useMakeMutation } from '@/services'

export const useAddCaseField = (options: MutationOptions = {}): Mutation =>
  useMakeMutation(
    (body: any) =>
      request('/sessionInfo/carrefour', {
        method: 'POST',
        body,
      }),
    options
  )

export const editCaseField = (values: any) =>
  request('/sessionInfo/carrefour', { method: 'PUT', body: values })

export const useUpdateCaseField = (options: MutationOptions = {}) => {
  return useMakeMutation(editCaseField, options)
}

export const deleteCaseField = (values: any) =>
  request(`/sessionInfo/carrefour`, { method: 'DELETE', body: values })

export const useDeleteCaseField = (options: MutationOptions = {}) => {
  return useMakeMutation(deleteCaseField, options)
}
