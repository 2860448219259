import theme from '@assets/theme'

export const tooltipPointFormat = (point, isLineChart, unit = '%') => {
  return (
    '<div style="margin-top:4px; font-size:12px;font-weight:500;color: #4a4a4a;">' +
    `<span style="display:inline-block; background-color:${point.color}; ${
      point.colorIndex === 1 && isLineChart
        ? 'width:20px; height:10px; border-radius:5px; margin-right: 10px;'
        : 'width:20px; height:10px; border-radius:5px; margin-right: 10px;'
    }  margin-left:8px;"></span>` +
    `${point.series.name} <span style="display:inline-block; margin-left:10px;">${point.y} ${unit}</span>`+
    '</div>'
  )
}

export const tooltipHeaderFormat = val => {
  const date = val.points.length > 0 ? val.points[0].key : ''

  return (
    `<span style="margin-bottom:8px;font-size:13px;color:#4a4a4a;font-weight:500">` +
    date +
    '</span>'
  )
}

export const initDualChart = {
  legend: {
    enabled: true,
    itemStyle: theme.chart.legend.item,
    itemHoverStyle: theme.chart.legend.itemHover,
    title: {
      style: theme.chart.legend.title
    },
  },
  credits: {
    enabled: false,
  },
  colors: ['#b7d6e4', '#c0973f', '#1aadce'],
  plotOptions: {
    column: {
      pointWidth: 10,
      state: { hover: { brightness: 0 } },
    },
    line: {
      marker: { radius: 4 },
    },
  },
  chart: {
    zoomType: 'xy',
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      lineColor: theme.chart.xAxis.lineColor,
      crosshair: true,
      labels: {
        style: theme.chart.xAxis.labels,
      },
    },
  ],
  yAxis: [
    {
      gridLineColor: theme.chart.yAxis.gridLineColor,
      labels: {
        format: '{value}',
        style: theme.chart.yAxis.labels,
      },
      title: {
        enabled: false,
      },
      opposite: true,
    },
    {
      gridLineColor: theme.chart.yAxis.gridLineColor,
      labels: {
        format: '{value}',
        style: theme.chart.yAxis.labels,
      },
      title: {
        margin: 40,
        style: theme.chart.yAxis.title,
        useHTML: true,
      },
    },
  ],
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: theme.tooltip.bg,
    borderRadius: 5,
    borderColor: theme.tooltip.bg,
    formatter: function () {
      return this.points.reduce(function (s, point) {
        return s + tooltipPointFormat(point, true)
      }, tooltipHeaderFormat(this))
    },
  },
}

export const initColumnChart = {
  credits: {
    enabled: false,
  },
  colors: ['#0ab1c1', theme.yellow200],
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  xAxis: {
    crosshair: true,
    labels: {
      style: {
        color: theme.tooltip.color,
        fontSize: '13px;',
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        color: theme.tooltip.color,
        fontSize: '14px;',
      },
    },
    title: {
      margin: 40,
      style: {
        color: theme.tooltip.color,
      },
      useHTML: true,
    },
  },
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: theme.tooltip.bg,
    borderRadius: 4,
    formatter: function () {
      return this.points.reduce(function (s, point) {
        return s + tooltipPointFormat(point)
      }, tooltipHeaderFormat(this))
    },
  },
  plotOptions: {
    column: {
      pointWidth: 10,
      state: { hover: { brightness: 0 } },
      // pointPadding: 2,
      // borderWidth: 0,
    },
  },
}

export default initDualChart
