import { useLoadScript } from '@react-google-maps/api'
import env from '@/env'

export default function useLoadGoogleMap() {
  return useLoadScript(scriptOptions)
}

const scriptOptions = {
  googleMapsApiKey: env.googleMapsApiKey,
  libraries: ['places', 'drawing', 'visualization'] as (
    | 'places'
    | 'drawing'
    | 'visualization'
    | 'geometry'
  )[],
  region: 'zh-TW',
}
