import { SVGProps } from 'react'
import cx from 'classnames'

const Dashboard = ({ className, ...props }: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('menu-icon-dashboard', className)}
    {...props}>
    <path
      d="M12 2C6.487 2 2 6.487 2 12s4.487 10 10 10 10-4.487 10-10S17.513 2 12 2zm0 1.667A8.32 8.32 0 0 1 20.333 12 8.32 8.32 0 0 1 12 20.333 8.32 8.32 0 0 1 3.667 12 8.32 8.32 0 0 1 12 3.667zm0 .833a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667zM7.286 6.453a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667zm9.47.888a.904.904 0 0 0-1.269-.005l-3.07 3.05A1.67 1.67 0 0 0 10.333 12a1.667 1.667 0 1 0 3.282-.417l3.07-3.07a.872.872 0 0 0-.014-1.255l.085.083zM5.333 11.167a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666zm13.334 0a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666zM7.287 15.88a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667zm9.427 0a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667z"
      fill="#878787"
      fillRule="nonzero"
    />
  </svg>
)

export default Dashboard
