import React from 'react'
import { point, Marker as LeafletMarker } from 'leaflet'
import { Marker, useMapEvents } from 'react-leaflet'
import { Menu, Item, useContextMenu, animation } from 'react-contexify'
import { DeviceType } from '@/constants'
import { DeviceInfo } from '@lib/device'
import { ActionTypes } from '@/auth'
import { LeafletMap } from '@components/innmaxmap'
import useRouteAuth from '@/routes/useRouteAuth'
import InfoWindowPopup from './InfoWindowPopup'
import ContextWindowPopup from './ContextWindowPopup'
import getMarkerIcon from './MarkerIcon'
import 'react-contexify/dist/ReactContexify.css'

const MENU_ID = 'menu_id'

export type MapMarkerProps = {
  item: DeviceInfo | any
  draggable?: boolean
  deletable?: boolean
  editable?: boolean
  popup?: boolean
  tooltip?: boolean
  focus?: boolean
  children?: React.ReactNode
  onDragEnd?: (item: any) => any
  onDelete?: (item: any) => any
  onPopupClose?: (item: any) => any
  isMultipleVideo?: boolean
  showInfoWindow?: boolean
  showContextWindow?: boolean
  onLeftClick?: (v: any) => void
  onRightClick?: (v: any) => void
  open?: null | undefined | boolean
  type?: 'InfoWindow' | 'ContextWindow' | 'InfoWindowTooltip'
  autoClosePopup?: boolean
  closePopupOnClick?: boolean
  focusDismiss3s?: boolean
}

function MarkerComponent({
  tooltip = true,
  popup = true,
  draggable = false,
  deletable = false,
  editable = false,
  focus = false,
  isMultipleVideo = false,
  item,
  onDragEnd,
  onDelete,
  onPopupClose,
  onLeftClick,
  onRightClick,
  showInfoWindow = item.deviceType === DeviceType.LOCATION ? false : true,  //若是地點就不預設開啟,
  showContextWindow = false,
  open = false,
  type = 'InfoWindow',
  autoClosePopup = true,
  closePopupOnClick = true,
  focusDismiss3s = false,
  ...props
}: MapMarkerProps) {
  const markerRef: any = React.useRef(null)

  const [dismiss3s, setDismiss3s] = React.useState(focusDismiss3s)
  const [popupOpen, setPopupOpen] = React.useState<boolean>(false)
  const [showTitleTooltip, setShowTitleTooltip] = React.useState<boolean>(false)

  const [windowType, setWindowType] = React.useState<
    'InfoWindow' | 'ContextWindow' | 'InfoWindowTooltip'
  >(type)

  const deviceItem = React.useMemo(
    () => ({
      ...item,
      ...(item.isBuilding ? { deviceType: DeviceType.LOCATION } : {}),
    }),
    [item]
  )

  const { show, hideAll } = useContextMenu({
    id: MENU_ID,
    props: {
      item,
    },
  })

  const [, checkIsAuthenticated] = useRouteAuth()

  useMapEvents({
    zoomstart: () => {
      hideAll()
    },
  })

  const handleMarkerDelete = (e: any) => {
    if (onDelete) {
      onDelete(e.props.item)
    }
  }

  const eventHandlers = React.useMemo(
    () => ({
      contextmenu(event: any) {
        onRightClick && onRightClick(item)
        if (deletable) {
          show(event.originalEvent)
        } else {
          const actionType = ActionTypes.UPDATABLE
          if (actionType && !checkIsAuthenticated(actionType)) {
            return null
          }
          setWindowType('ContextWindow')
          setTimeout(() => {
            const marker: LeafletMarker = markerRef.current as any
            marker.openPopup()
          })
        }
      },
      mouseover() {
        setShowTitleTooltip(true)
      },
      mouseout() {
        setShowTitleTooltip(false)
      },
      click({ latlng, originalEvent, ...e }: any) {
        onLeftClick && onLeftClick(item)
      },
      dragend() {
        const marker: LeafletMarker = markerRef.current as any

        if (marker != null) {
          if (onDragEnd) {
            const latlng = marker.getLatLng()
            onDragEnd({ ...item, lat: latlng.lat, lon: latlng.lng })
          }
        }
      },
      popupopen() {
        const marker = markerRef.current as any
        if (marker && marker._icon) {
          marker._icon?.classList?.add('icon-animation')
        }
        setPopupOpen(true)
      },
      popupclose() {
        const marker = markerRef.current as any
        if (marker && marker._icon) {
          marker._icon?.classList?.remove('icon-animation')
        }
        setDismiss3s(false)
        setTimeout(() => setWindowType(type))
        onPopupClose && onPopupClose(item)
        setPopupOpen(false)
      },
    }),
    [] // eslint-disable-line
  )

  const icon = React.useMemo(() => {
    const originIconOptions = markerRef.current?.getIcon().options || {}

    return getMarkerIcon(
      deviceItem,
      editable ? { ...originIconOptions, popupAnchor: [120, 48] } : {}
    )
  }, [deviceItem, editable])

  const renderMarker = React.useCallback((marker: any) => {
    const _marker = marker?.target || marker
    const url: string = _marker?._icon?.src
    const bindClass = (className: any) =>
      setTimeout(
        () =>
          _marker._icon?.classList?.add(
            'innmaxmap-marker',
            'leaflet',
            item.deviceType,
            className
          ),
        500
      )
    if (/(#alarm)$/.test(url)) {
      bindClass('alarm')
    } else {
      bindClass('normal')
    }
  }, []) // eslint-disable-line

  const handleClosePopup = React.useCallback(() => {
    const marker = markerRef.current as any
    if (marker) {
      marker.closePopup()
    }
  }, [markerRef])

  React.useEffect(() => {
    const marker = markerRef.current as any
    if (focus && marker) {
      marker.openPopup()
    }
    if (!focus) {
      marker?.closePopup()
    }
  }, [focus])

  React.useEffect(() => {
    const marker = markerRef.current as any

    if (item && marker && editable) {
      marker.openPopup()
    }
  }, [item, editable])

  React.useEffect(() => {
    const marker = markerRef.current as any
    setTimeout(() => renderMarker(marker))
  }, [item]) // eslint-disable-line

  React.useEffect(() => {
    if (open === null) {
      return
    }
    const marker = markerRef.current as any
    if (marker) {
      open && setDismiss3s(focusDismiss3s)
      if (open && popupOpen) {
        return marker._icon?.classList?.add('icon-animation')
      }
      open ? marker.openPopup() : marker.closePopup()
    }
  }, [markerRef, focusDismiss3s, open]) // eslint-disable-line

  React.useEffect(() => {
    const marker = markerRef.current as any
    if (dismiss3s && marker) {
      setTimeout(() => {
        marker._icon?.classList?.remove('icon-animation')
        setDismiss3s(false)
      }, 3000)
    }
  }, [dismiss3s])

  return (
    <>
      {deletable && (
        <Menu id={MENU_ID} style={{ zIndex: 999 }} animation={animation.fade}>
          <Item onClick={handleMarkerDelete}>移除定位</Item>
        </Menu>
      )}

      {item.lat && (
        <Marker
          ref={markerRef}
          draggable={draggable}
          zIndexOffset={1500}
          icon={icon}
          position={[item.lat, item.lon]}
          eventHandlers={eventHandlers}
          {...props}>
          <LeafletMap.Tooltip
            className="skip-toggle-dialog"
            direction="top"
            offset={point(0, -28)}
            permanent={showTitleTooltip}>
            {deviceItem.name || deviceItem.displayName}
          </LeafletMap.Tooltip>
          {
            windowType === 'InfoWindow' && showInfoWindow ? (
              <InfoWindowPopup
                item={deviceItem}
                onClose={handleClosePopup}
                autoClose={autoClosePopup}
                closeOnClick={closePopupOnClick}
                {...props} />
            ) : windowType === 'ContextWindow' && showContextWindow ? (
              <ContextWindowPopup
                item={deviceItem}
                onClose={handleClosePopup}
                autoClose={autoClosePopup}
                closeOnClick={closePopupOnClick}
                {...props} />
            ) : null
          }
        </Marker>
      )}
    </>
  )
}

export default React.memo(MarkerComponent)
