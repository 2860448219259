import { SVGProps } from 'react'
import cx from 'classnames'

const FilterCardSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    className={cx('icon-smallcard', className)}
    {...props}>
    <g
      transform="translate(1.5 1.5)"
      stroke="#FFF"
      strokeWidth="1.2"
      fill="none"
      fillRule="evenodd">
      <path
        strokeLinecap="round"
        d="M0 6.5h7.429M11.143 6.5H13M0 1.857h1.857M5.568 1.857H13M0 11.143h2.786M6.5 11.143H13"/>
        <circle cx="3.714" cy="1.857" r="1.857"/>
        <circle cx="9.286" cy="6.5" r="1.857"/>
        <circle cx="4.643" cy="11.143" r="1.857"/>
    </g>
  </svg>
)

export default FilterCardSVG
