import { publicUrl } from '@/env'
import {
  compose,
  isNil,
  ifElse,
  is,
  allPass,
  path,
  anyPass,
  T,
  always,
} from 'ramda'
import { DeviceStatus } from '@/device'
import { DeviceType } from '@/constants'
const { cond } = require('ramda')

type MarkerIcon = {
  icon?: string | any
  normal: string | any
  warn: string | any
  low?: string | any
  off?: string | any
  active?: string | any
  maintain?: string | any
  select?: string | any
}

export enum MarkerIconKey {
  icon = 'icon',
  normal = 'normal',
  warn = 'warn',
  low = 'low',
  off = 'off',
  active = 'active',
  maintain = 'maintain',
  select = 'select',
}

export enum MarkerImageType {
  PNG = '.png',
  SVG = '.svg',
}

export class Marker {
  imgType: MarkerImageType = MarkerImageType.PNG
  imgPath = `${publicUrl}/img/marker`

  constructor(
    type: MarkerImageType = MarkerImageType.PNG,
    path: string = `${publicUrl}/img/marker`
  ) {
    this.imgType = type
    this.imgPath = path
  }

  icon(device: DeviceType, key: MarkerIconKey): string {
    return `${this.imgPath}/${device}/${key}${this.imgType}`
  }

  all(): { [key: string]: MarkerIcon } {
    const markers: { [key: string]: MarkerIcon } = {}
    Object.entries(DeviceType).forEach(([key, value]) => {
      const obj = {
        normal: `${this.imgPath}/${value}/normal${this.imgType}`,
        warn: `${this.imgPath}/${value}/warn${this.imgType}`,
        maintain: `${this.imgPath}/${value}/maintain${this.imgType}`,
        low: `${this.imgPath}/${value}/low${this.imgType}`,
        off: `${this.imgPath}/${value}/off${this.imgType}`,
        select: `${this.imgPath}/${value}/select${this.imgType}`,
      } as MarkerIcon
      markers[key as DeviceType] = obj
      markers[value as DeviceType] = obj
    })
    return markers
  }
}

export const MarkerIcon: { [key: string]: MarkerIcon } = new Marker(
  MarkerImageType.SVG
).all()

//在路燈群組的 marker, 不顯示警報icon
export function getMarkerIcon(
  x: any,
  isInGroup?: boolean,
  inDeviceList?: boolean
) {
  const deviceType: DeviceType = x.deviceType

  const status = x.isAlarm ? 'alarm' : 'normal'
  const badge = `#${x.id},#${status}`

  return `${new Marker(MarkerImageType.PNG).icon(deviceType, MarkerIconKey.normal)}${badge}`

  // const key: MarkerIconKey = cond([
  //   [(v: DeviceStatus) => v.isRepair, always('maintain')],
  //   [(v: DeviceStatus) => v.isAlarm || v.isDeviceFail, always('warn')],
  //   isInGroup
  //     ? [(v: DeviceStatus) => v.isSelected, always('select')]
  //     : [(v: DeviceStatus) => v.isAlarm, always('warn')],
  //   [
  //     (v: DeviceStatus) => v.isLight,
  //     (v: DeviceStatus) => {
  //       return v.brightness === 0
  //         ? 'off'
  //         : v.brightness > 0 && v.brightness < 51
  //         ? 'low'
  //         : 'normal'
  //     },
  //   ],
  //   [
  //     (v: DeviceStatus) => v.isBuilding,
  //     (v: DeviceStatus) => {
  //       deviceType = DeviceType.LOCATION
  //       return v?.deviceCounts?.deviceAlarmCount > 0 ? 'warn' : 'normal'
  //     },
  //   ],
  //   [T, always('normal')],
  // ])(x)

  // const status = x.isAlarm ? 'alarm' : 'normal'

  // const badge = `#${x.id},#${status}`

  // 如裝置為附掛設備，透過CSS去新增badge
  // const badge = `#${x.id},${
  //   x.subDeviceCount
  //     ? x.isSubAlarm || x.isSubDeviceFail
  //       ? '#alarm'
  //       : '#normal'
  //     : ''
  // }`

  // return `${new Marker(MarkerImageType.PNG).icon(deviceType, key)}${inDeviceList ? '' : badge}`
}

export const isMarkerNil = anyPass([
  ifElse(
    is(Array),
    () => false,
    allPass([compose(isNil, path(['lon'])), compose(isNil, path(['lat']))])
  ),
  isNil,
])

export default Marker
