import React from 'react'

type Props = {
  visible?: boolean
  onRequestOpen?: () => void
}

export default function useVisible<T>({
  visible = false,
  onRequestOpen,
}: Props = {}) {
  const [data, setData] = React.useState<T>()
  const [_visible, setVisible] = React.useState(visible)

  const handleVisible = (data?: any) => {
    setVisible(prev => !prev)
    setData(data)

    if (onRequestOpen) {
      onRequestOpen()
    }
  }

  const handleClose = () => {
    setVisible(false)
  }

  const handleOpen = (data?: any) => {
    setVisible(true)
    setData(data)
  }

  return {
    data,
    visible: _visible,
    toggle: handleVisible,
    open: handleOpen,
    close: handleClose,
  }
}
