import React from 'react'
import type { MenuProps } from 'antd'
import { Layout, Menu, Dropdown, Button } from 'antd'
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined'
import styled from 'styled-components'
import { pathOr } from 'ramda'
import { useAuth } from '@/auth'
import { usePageHeader } from 'src/hooks/usePageHeader'
import { useTranslation } from 'react-i18next'

type Props = {
  title?: string | React.ReactNode
}
type MenuItem = Required<MenuProps>['items'][number]

function Navbar({ title }: Props) {
  const { logout, user } = useAuth()

  const handleLogout = () => logout()

  const { t } = useTranslation()

  const items: MenuItem[] = React.useMemo(() => {
    return [
      {
        label: t('common:logout'),
        key: 'SignOut',
        className: 'navbar-menu-item',
      },
    ]
  }, [t])

  return (
    <Header>
      <div>{usePageHeader().title || title}</div>
      <div className="inline-flex items-center">
        {usePageHeader().content}
        <StyledDropdown
          trigger={['click']}
          placement="bottomRight"
          overlay={() => (
            <StyledDropdownMenu items={items} onClick={handleLogout} />
          )}>
          <Button type="link" className="ant-dropdown-link p-0">
            <LoginUser>
              <span>{pathOr('', ['nickname'], user)}</span>
              <CaretDownOutlined className="icon-caret-down" />
            </LoginUser>
          </Button>
        </StyledDropdown>
      </div>
    </Header>
  )
}

const StyledDropdown = styled(Dropdown)`
  .ant-select-selection {
    border: 0;
    height: 100%;
  }

  .ant-select-selection__rendered {
    line-height: unset;
  }

  .ant-select-dropdown-important {
    display: none;
  }
`

const Header = styled(Layout.Header)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  &.ant-layout-header {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${p => p.theme.layoutHeader.padding};
    // padding-left: 84px;
    // padding-right: 20px;
    background: transparent;
    // background: ${({ theme }) => theme.secondary};
    line-height: initial;
  }
`

const StyledDropdownMenu = styled(Menu)`
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;

  .ant-dropdown-menu-item.navbar-menu-item {
    font-size: 12px;
    display: flex;
    text-align: center;
    background: ${p => p.theme.darkGrey};
    color: ${p => p.theme.light};
    padding: 4px 9px;
    min-width: 64px !important;

    &:hover {
      background: ${p => p.theme.yellow100} !important;
      color: ${p => p.theme.light} !important;
      span {
        color: ${p => p.theme.dark} !important;
      }
    }
  }
`

const LoginUser = styled.div`
  display: flex;
  align-items: center;
  color: ${p => p.theme.darkGrey};
  font-size: 14px;
  font-weight: normal;

  .icon-caret-down {
    position: relative;
    top: 2px;
    margin-left: 4px !important;
    // margin-right: 10px !important;
    font-size: 14px !important;
  }
`

export default Navbar
