import { SVGProps } from 'react'
import cx from 'classnames'

const ProblemSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    className={cx('icon-problem', className)}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M8 1a7 7 0 0 0-7 7 7 7 0 0 0 7 7 7 7 0 0 0 7-7 7 7 0 0 0-7-7z"
        stroke="#FD9487"
        strokeWidth="1.4"
      />
      <path
        d="M8 10.454a1.184 1.184 0 1 1 0 2.367 1.184 1.184 0 0 1 0-2.367zm0-6.927c.538 0 1.044.293 1.109.798l.007.112v4.218c0 .574-.54.91-1.116.91-.538 0-1.044-.293-1.109-.798l-.007-.112V4.437c0-.574.54-.91 1.116-.91z"
        fill="#FD9487"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default ProblemSVG
