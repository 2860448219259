import { SVGProps } from 'react'
import cx from 'classnames'
import theme from '@assets/theme'

const LockSVG = ({
  className,
  color = theme.icon.primary,
  ...props
}: SVGProps<any>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('svg-icon icon-lock', className)}
    {...props}>
    <path
      d="M19.315 6.612c-.406-1.001-.98-1.896-1.704-2.658a7.903 7.903 0 0 0-2.522-1.802 7.53 7.53 0 0 0-6.18.002 7.886 7.886 0 0 0-2.522 1.8 8.4 8.4 0 0 0-1.704 2.658 8.626 8.626 0 0 0-.626 3.248v11.02c0 .9.696 1.632 1.554 1.632h12.771c.858 0 1.554-.732 1.55-1.632v-9.726l.004-.448V9.86a8.568 8.568 0 0 0-.621-3.248zM9.532 3.628h.005l.03-.012a5.821 5.821 0 0 1 2.43-.525c.842 0 1.662.179 2.433.52a6.457 6.457 0 0 1 2.016 1.44 6.84 6.84 0 0 1 1.38 2.156c.34.84.511 1.73.511 2.653v.694H5.657v-.696c0-.914.172-1.805.512-2.651a6.809 6.809 0 0 1 1.38-2.154 6.285 6.285 0 0 1 1.983-1.425zm8.813 8.522v8.728a.109.109 0 0 1-.005.033H5.662a.109.109 0 0 1-.004-.033V12.15h12.687zm-7.086 3.6v2.098a.743.743 0 0 0 1.486 0v-2.103c.394-.253.63-.68.63-1.153a1.376 1.376 0 0 0-2.75 0c.002.467.243.907.634 1.158z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
)

export default LockSVG
