import { Link } from 'react-router-dom'
import H1 from './H1'

type PageHeaderItem = string | { name: string; path: string }

const PageHeader = ({
  sub = '',
  title = '',
}: {
  sub?: string | PageHeaderItem[]
  title?: string
}) => {
  return (
  <H1 className="mb-0">
    {sub ? (
      typeof sub === 'string' ? (
        <>
          <span className="sub-title">
            {sub} {title ? '>' : ''}
          </span>{' '}
          {title}
        </>
      ) : (
        <>
          {sub.map(s =>
            typeof s === 'string' ? (
              <span key={s}>
                <span key={s} className="sub-title">
                  {s} {'>'}
                </span>{' '}
              </span>
            ) : (
              <span key={s.name}>
                <Link
                  to={s.path}
                  key={s.name}
                  className="sub-title cursor-pointer">
                  {s.name} {'>'}
                </Link>{' '}
              </span>
            )
          )}

          {title}
        </>
      )
    ) : (
      title
    )}
  </H1>
)
}

export default PageHeader
