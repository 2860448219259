import styled from 'styled-components'

const H1 = styled.h1`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  color: ${p => p.theme.font.primary};

  .sub-title {
    color: ${p => p.theme.font.secondary};
  }
`

export default H1
