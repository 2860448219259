import { SVGProps } from 'react'
import cx from 'classnames'

const GreenPowerSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    className={cx('icon-greenpower', className)}
    {...props}>
    <g
      strokeWidth="1.3"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path
        d="M12.006 21.672c-2.877-1.055-4.29-4.595-3.83-6.48C9.41 10.149 14.293 9.668 16.375 6c3.152 2.267 4.345 6.915 2.933 11.09-.952 2.81-3.64 3.749-5.079 4.058"
        stroke="#FFCC2B"
        fill="#FFCC2B"/>
      <path
        d="M12.728 26c-2.166-6.153 1.049-10.827 2.873-14.844"
        stroke="#FFCC2B"/>
      <path
        d="M12.503 18.338c.732-2.67 2.117-5.02 3.098-7.182"
        stroke="#000"/>
      <path
        d="M10.522 8.814H3.217C2.545 8.814 2 9.35 2 10.01v11.961c0 .661.545 1.196 1.217 1.196h6.696M14.174 23.167h11.565c.673 0 1.218-.535 1.218-1.196v-11.96c0-.661-.545-1.197-1.218-1.197h-4.87M26.957 12.403h1.826c.672 0 1.217.535 1.217 1.196v4.784c0 .661-.545 1.196-1.217 1.196h-1.826" 
        stroke="#FFCC2B"/>
    </g>
  </svg>
)

export default GreenPowerSVG
