import { PAGE_SIZE } from 'src/containers/ReportManagement/utils'

const randomNumbers = () => {
  const numbers = []
  for (let i = 1; i < Number(new Date().getDate() + 1); i++) {
    numbers.push({
      id: i,
      dateStr: `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${i}`,
      value: Math.floor(Math.random() * 9999)
    })
  }
  return numbers
}

const fake = {
  "powerPlantsData": [
    {
      text: `
        <div style="transform: rotate(-270deg); display:flex; flex-direction:column; align-items:center;" >
          <p style="writing-mode: vertical-rl; font-size: 20px;">
          用電量
          </p>
          <p style="font-size: 20px;">kW</p>
        </div>
      `,
      type: 'column',
      name: '發電量 kWh',
      values: randomNumbers()
    },
    {
      text: `
        <div style="transform: rotate(270deg); display:flex; flex-direction:column; align-items:center;" >
          <p style="writing-mode: vertical-rl; font-size: 20px;">
          用電金額
          </p>
          <p style="font-size: 20px;">NTD</p>
        </div>
      `,
      type: 'areaspline',
      name: '用電金額 NTD',
      values: randomNumbers()
    }
  ],
  totalPages: Math.ceil(randomNumbers().length / PAGE_SIZE)
}

export default fake
