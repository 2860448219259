import { SVGProps } from 'react'
import cx from 'classnames'

const EllipsisSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    className={cx('icon-ellipsis', className)}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z"/>
      <g
        fill="#FFF"
        fillRule="nonzero">
        <path d="M2 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0zM7 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0zM12 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/>
      </g>
    </g>
  </svg>
)

export default EllipsisSVG
