import React from 'react'
import styled from 'styled-components'
import { pathOr } from 'ramda'
import { ColumnSetting } from '@/auth'
import Box from '@components/Box'
import Button from '@components/Button'
import { IconClose } from '@components/Icons'
import Tooltip from '@components/ui/Tooltip'
import Flex from '@components/ui/Flex'
import Badge from './Badge'
import Column from './Column'
import history from '@/routes/history'
import { useTranslation } from 'react-i18next'
import { DeviceType } from '@lib/constants'
import { useLazyQueryDevice } from '@/device'
import { AIR_CONDITIONER_MANAGEMENT, AMMETER_MANAGEMENT, CHILLER_MANAGEMENT } from '@lib/routes'

type Props = {
  data: any
  preference: ColumnSetting
  title?: string
  onClose?: (item: any) => any
}

export default function InfoWindow({
  data,
  preference = {},
  title,
  onClose,
}: Props) {
  const { t } = useTranslation()

  const { data: deviceData, queryDevice } = useLazyQueryDevice()

  const device = React.useMemo(
    () => ({
      ...deviceData,
      ...deviceData?.extra,
    }),
    [deviceData]
  )

  const handleClick = React.useCallback(() => {
    let url
    switch(data.deviceType) {
      case DeviceType.CHILLER:
        url = `${CHILLER_MANAGEMENT}?id=${data.id}`
        break
      case DeviceType.AMMETER:
        url = `${AMMETER_MANAGEMENT}?id=${data.id}`
        break
      case DeviceType.AIRCONDITIONER:
        url = `${AIR_CONDITIONER_MANAGEMENT}?id=${data.id}`
        break
    }
    url && history.push(url)
  }, [data])

  const renderColumn = (column: any) => ({
    name: t(`${device.deviceType}:${column}`),
    value: pathOr(null, [`${column}Str`], device) || pathOr(null, [column], device),
    unit: '',
  })

  const handleClose = (e: any) => {
    e.stopPropagation()
    if (onClose) {
      onClose(data)
    }
  }

  React.useEffect(() => {
    queryDevice({
      id: data.id,
      deviceType: data.deviceType,
      _time: +new Date()
    })
  }, []) //eslint-disable-line

  return (
    <Box className="flex" alignItems="flex-start">
      <InfoBoxWrapper>
        <Header>
          <Flex center middle className="flex-1 " style={{ minWidth: 0 }}>
            <Badge data={data} />
            <Tooltip title={title || device?.displayName}>
              <div className="title flex-1">{title || device?.displayName}</div>
            </Tooltip>
          </Flex>
          <IconClose
            width="18"
            height="18"
            className="cursor-pointer"
            onClick={handleClose}
          />
        </Header>
        <Content>
          {
            Object.keys(preference).map((key: string) => (
              preference[key] && (
                <Column key={key} data={renderColumn(key)} />
              )
            ))
          }
        </Content>
        <Footer>
          <Button type="text" onClick={handleClick}>
            more
          </Button>
        </Footer>
      </InfoBoxWrapper>
    </Box>
  )
}

const InfoBoxWrapper = styled.div`
  width: ${({ theme }) => theme.infowindow.width};
  height: ${({ theme }) => theme.infowindow.height};
  word-break: break-all;
  overflow: hidden !important;

  border: ${({ theme }) => `1px solid ${theme.infowindow.border}`};
  background: #fff;
  border-radius: ${({ theme }) => theme.infowindow.borderRadius};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.infowindow.titleBg};
  color: ${({ theme }) => theme.infowindow.titleColor};
  height: 22px;
  width: 100%;
  padding: 0px 4px;

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Content = styled.div`
  overflow: auto;
  flex: 1;
`

const Footer = styled.div`
  background-color: ${({ theme }) => theme.infowindow.columnBg};
  padding: 2.5px 5px;
  text-align: right;

  button.ant-btn-text > span {
    text-decoration: underline;
  }
`
