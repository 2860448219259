import { SVGProps } from 'react'
import cx from 'classnames'
import theme from '@assets/theme'

const EditSVG = ({
  className,
  color = theme.icon.primary,
  ...props
}: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={cx('svg-icon icon-edit', className)}
    {...props}>
    <defs>
      <path
        d="m14 3.414-10 10V16h2.586l10-10L14 3.414zM21 21a1 1 0 0 1 0 2H3a1 1 0 0 1 0-2zM14.707 1.293l4 4a1 1 0 0 1 0 1.414l-11 11A1 1 0 0 1 7 18H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 .293-.707l11-11a1 1 0 0 1 1.414 0z"
        id="skcyxxkwka"
      />
    </defs>
    <use fill={color} fillRule="nonzero" xlinkHref="#skcyxxkwka" />
  </svg>
)

export default EditSVG
