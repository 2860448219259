// import React from 'react'
import renderer from '@/utils/renderer'
import { envStore } from '@/env'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'leaflet/dist/leaflet.css'
import { createRoot } from 'react-dom/client'

if (envStore.isEnvDev) {
  // const { server } = require('../../mocks')
  // server.start({
  //   serviceWorker: {
  //     url: `${envStore.publicUrl}/mockServiceWorker.js`,
  //   },
  // })
}

const app = renderer()(App)

createRoot(document.getElementById('root') as HTMLElement).render(app)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
