import React from 'react'
import { isNil } from 'ramda'
import Store from 'store'
import history from '@/routes/history'
import type { User } from './auth.types'

export type LogoutFn = (params?: { onSuccess?: () => any }) => any

export type AuthContextType = {
  logout: LogoutFn
  isAuthenticated: boolean
  user: User
  setUser: React.Dispatch<React.SetStateAction<User | null>>
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
}

export const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: false,
  user: {} as User,
  logout: () => ({}),
  setUser: (value: React.SetStateAction<User | null>) => ({}),
  setIsAuthenticated: (value: React.SetStateAction<boolean>) => ({}),
})

const _user = Store.get('carrefour_user')
const _token = Store.get('carrefour_token')

export function AuthProvider(props: any) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    !isNil(_token) && !isNil(_user)
  )
  const [user, setUser] = React.useState<User>(_user)

  const logout = React.useCallback(({ onSuccess } = {}) => {
    Store.remove('carrefour_token')
    Store.remove('carrefour_user')
    setIsAuthenticated(false)

    history.push('/')

    if (onSuccess) {
      onSuccess()
    }
  }, [])

  const value = React.useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      setUser,
      logout,
      user,
    }),
    [isAuthenticated, JSON.stringify(user)] // eslint-disable-line
  )

  return <AuthContext.Provider value={value} {...props} />
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export default useAuth
