import { useQuery } from 'react-query'
import { assocPath, pathOr, values } from 'ramda'
import React from 'react'
import {
  PAGE_SIZE,
  TYPE,
  xAxisName,
} from 'src/containers/ReportManagement/utils'
import { convertData } from '@/services'
import { InverterReport } from '.'
import { formatDate } from '@/utils/webHelper'
import { useTranslation } from 'react-i18next'
import { FakeComparisonMonthReport, FakeComparisonYearReport } from './fake'

export const useInvertersReport = (querys: { [key: string]: any }) => {
  const { data, ...others } = useQuery(
    ['/report/powerplant/analysis', querys],
    {
      // enabled: !isNil(querys),
      enabled: false,
    }
  )

  const { t } = useTranslation()

  const dataSource = React.useMemo(() => {
    // const reportList = path(['data'], data) as InverterReport
    const reportList: InverterReport =
      querys?.type === TYPE.MONTH
        ? FakeComparisonMonthReport.data
        : (FakeComparisonYearReport.data as InverterReport)

    if (!reportList) {
      return { content: [], total: 0, totalPages: 0 }
    }
    const { reportType } = querys
    const currentTime = new Date().getTime()

    const invertersData: any = {}
    const xAxis = reportList.filter(
      c => currentTime > new Date(c.dateStr).getTime()
    )
    xAxis.forEach(v => {
      v.inverters.forEach(data => {
        const chartValues: any[] = pathOr(
          [],
          [data.name, 'values'],
          invertersData
        )
        chartValues.push({
          y: data.value,
          name: v.dateStr,
        })
        invertersData[data.name] = {
          name: data.name,
          values: chartValues,
        }
      })
    })

    let xLabels: (string | number)[] = []

    switch (reportType) {
      case TYPE.YEAR:
        xLabels = xAxis.map(i => {
          const m = new Date(i.dateStr).getMonth()
          return xAxisName(m)
        })
        break
      case TYPE.MONTH:
        xLabels = xAxis.map(i => new Date(i.dateStr).getDate())
        break
      case TYPE.DAY:
        xLabels = xAxis.map(i => formatDate(new Date(i.dateStr).valueOf()))
        break
      default:
    }

    const len = xAxis.length

    return {
      xAxisLabels: xLabels,
      total: len,
      totalPages: Math.ceil(len / PAGE_SIZE),
      powerPlantsData: values(invertersData),
    }
  }, [querys])

  return {
    dataSource,
    ...others,
  }
}

export const useInvertersList = (querys: { [key: string]: any }) => {
  const { data, ...others } = useQuery(
    ['/report/powerplant/analysis', querys],
    {
      // enabled: !isNil(querys),
      enabled: false,
    }
  )

  const { t } = useTranslation()

  const dataSource = React.useMemo(() => {
    // const reportList = path(['data'], data) as InverterReport
    const reportList: InverterReport =
      querys?.type === TYPE.MONTH
        ? FakeComparisonMonthReport.data
        : (FakeComparisonYearReport.data as InverterReport)

    if (!reportList) {
      return {
        content: [],
        total: 0,
        totalPages: 0,
      }
    }

    const currentTime = new Date().getTime()

    let invertersData: any = {}
    const availableReportValue = reportList.filter(
      r => currentTime > new Date(r.dateStr).getTime()
    )
    availableReportValue.forEach(v => {
      invertersData = assocPath(
        [v.dateStr, 'createAtStr'],
        v.dateStr,
        invertersData
      )

      v.inverters.forEach(inverter => {
        invertersData = assocPath(
          [v.dateStr, inverter.inverterSeq],
          inverter.value,
          invertersData
        )
      })
    })

    return convertData({
      converter: x => x,
    })({
      data: values(invertersData),
    })
  }, [querys?.type])

  const columns = React.useMemo(() => {
    // const reportList = path(['data'], data) as InverterReport
    const reportList: InverterReport =
      querys?.type === TYPE.MONTH
        ? FakeComparisonMonthReport.data
        : (FakeComparisonYearReport.data as InverterReport)

    return pathOr([], [0, 'inverters'], reportList).map((device: any) => {
      return {
        title: device.name,
        dataIndex: device.inverterSeq,
        key: device.inverterSeq,
      }
    })
  }, [querys?.type])

  return {
    dataSource,
    columns,
    ...others,
  }
}
