import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'ramda'
import { Row, Col } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'

import Table from '@components/Table'
import Button from '@components/Button'
import { DeviceType } from '@/constants'
import { useAlarmRpt, AlarmList } from '@lib/report'
import { toThousandSeparator } from '@/utils/webHelper'
import { CAMERA_MANAGEMENT, STREETLIGHT_MANAGEMENT } from '@/routes/routes'
import dataSource from './fake'

function AlarmReportList({ query }: any) {
  const { t } = useTranslation()
  const history = useHistory()

  // const { queryAlarmList, dataSource, loading } = useAlarmRpt()

  const columns: ColumnProps<AlarmList>[] = [
    {
      dataIndex: 'createAtStr',
      title: t('report:time'),
    },
    {
      dataIndex: 'sessionName',
      title: '分店',
    },
    {
      dataIndex: 'deviceType',
      title: '設備類型',
    },
    {
      dataIndex: 'displayName',
      title: '顯示名稱',
    },
    {
      dataIndex: 'alarmMessage',
      title: t('report:alarm message'),
    },
  ]

  const handleToDeviceDetail = (device: AlarmList) => {
    switch (device.deviceType) {
      case DeviceType.LIGHT:
        return history.push(`${STREETLIGHT_MANAGEMENT}?id=${device.deviceSeq}`)
      case DeviceType.CAMERA:
        return history.push(`${CAMERA_MANAGEMENT}?id=${device.deviceSeq}`)
      default:
        return ''
    }
  }

  React.useEffect(() => {
    if (isEmpty(query)) {
      return
    }

    // queryAlarmList(query)
  }, [query]) // eslint-disable-line

  return (
    <Row className="mt-42">
      <Col span={24} className="flex justify-between mb-4">
        <span className="font-medium top-3 text-pagination">
          {t('common:total count', {
            total: toThousandSeparator(dataSource?.content?.length),
          })}
        </span>
      </Col>
      <Col span={24}>
        <Table
          rowKey="id"
          dataSource={dataSource}
          // loading={loading}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            total: dataSource?.content.length || 1,
            pageSize: 10,
          }}
        />
      </Col>
    </Row>
  )
}

export default React.memo(AlarmReportList)
