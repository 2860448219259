import { useQuery } from 'react-query'
import { convertSessionData } from './session.utils'
import React from 'react'
import { pathOr, map, mergeAll, prepend } from 'ramda'
import type { Session } from '@/session'
const { compose } = require('ramda')

export const useAllSessions = () => {
  const { data, ...others } = useQuery('/sessionInfo/list/all', {
    cacheTime: Infinity,
  })

  const sessionData = React.useMemo(() => {
    return convertSessionData(data)
  }, [data])

  const findStoreSession = React.useCallback(
    (id: number) => {
      function loopFind(id: number): Session | undefined {
        if (!sessionData.byId) {
          return undefined
        }
        if (!sessionData.byId[id]) {
          return undefined
        }
        const parentId = sessionData.byId[id].parentSessionId
        if (!!parentId && sessionData.byId[parentId].type === 3) {
          return loopFind(parentId)
        }
        return sessionData.byId[id]
      }
      return loopFind(id)
    },
    [sessionData.byId]
  )

  const getFullSessions = React.useCallback(
    (id: number) => {
      function loopFind(id: number, sessions: Session[]): Session[] {
        if (!sessionData.byId) {
          return sessions
        }
        if (!sessionData.byId[id]) {
          return sessions
        }
        const parentId = sessionData.byId[id].parentSessionId
        if (!!parentId) {
          return loopFind(parentId, prepend(sessionData.byId[id], sessions))
        }
        return prepend(sessionData.byId[id], sessions)
      }
      return loopFind(id, [])
    },
    [sessionData.byId]
  )

  return {
    ...others,
    byId: sessionData.byId || {},
    dataSource: sessionData.dataSource,
    data: sessionData.data || [],
    findStoreSession,
    getFullSessions,
  }
}

export const useAvailableSessions = (variables = { type: 'QUERY' }) => {
  const { data, ...others } = useQuery(
    ['/sessionInfo/list/available', variables],
    {
      cacheTime: 0,
    }
  )

  return {
    ...others,
    ...convertSessionData(data),
    byId: compose(
      mergeAll,
      map((x: Session) => ({
        [x.id]: x,
      })),
      pathOr([], ['results', 'data'])
    )(data),
  }
}

export default useAllSessions
