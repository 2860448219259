const primary = '#0074a8'
const primary100 = 'rgba(0, 116, 168, 0.1)'
const secondary = '#e6edf1'
const blue = '#5E9CE5'
const blue500 = 'rgba(94, 156, 229, 0.5)'
const dark = '#000'
const darkGrey = '#4a4a4a'
const darkGrey500 = 'rgba(74, 74, 74, 0.5)'
const whisper = '#e4e4e4'
const grey = '#878787'
const lightBlue = '#b7d6e4'
const darkBlue = '#0e2e3c'
const silverGrey = '#c0c0c0'
const white = '#fff'
const beige = '#fff1b8'
const transparent = 'transparent'
const success = '#53b235'
const error = '#ff634f'
const warning = '#ffcc91'

/**
 * @return { [key: string]: any}
 */
const theme = {
  blue,
  darkBlue,
  danger: '#E17567',
  dark,
  dark100: 'rgba(0, 0, 0, 0.1)',
  darkGrey,
  grey,
  silverGrey,
  lightGrey: '#d8d8d8',
  light: white,
  primary,
  red100: '#ffdad6',
  secondary,
  warning: warning,
  success: success,
  error: error,
  yellow200: '#ffcc2b',
  yellow100: beige,
  yellow: '#ffc247',
  btn: {
    text: white,
    bg: primary,
    ghostBorder: primary,
    ghostHover: primary100,
    outlineHover: primary100,
    linkHover: '#0085c1',
    linkColor: blue,
    info: blue,
    infoHover: blue500,
  },
  modal: {
    mask: 'rgba(0, 0, 0, 0.76)',
    maskDarken: 'rgba(0, 0, 0, 0.76)',
    bg: white,
    title: primary,
  },
  whisper: whisper,
  transparent: transparent,
  table: {
    head: grey,
    shape: 'none',
    bg: white,
    bgHover: '#fffcf1',
    borderBottom: whisper,
    borderHover: transparent,
    spacing: 0,
    rowColor: darkGrey,
    moTitle: grey, //mobile table
    moOddBg: 'rgba(0, 0, 0, 0.1)',
    moEvenBg: white,
    moColumnColor: darkGrey,
  },
  slider: '#c0973f',
  sliderBg: '#c0973f',
  pagination: primary,
  block: {
    text: white,
    title: darkGrey,
    content: '#f4f3f3',
  },
  searchIcon: primary,
  font: {
    primary,
    secondary: grey,
  },
  icon: {
    primary: darkGrey500,
    primaryHover: darkGrey,
    secondary: white,
  },
  menu: {
    bg: '#faf9f9',
    color: grey,
    hoverColor: primary,
    mainActiveBg: secondary,
    mainActiveColor: primary,
    subActiveBg: secondary,
    subActiveColor: primary,
  },
  lightBlue,
  checkbox: {
    border: silverGrey,
    bg: '#dff5ff',
    indeterminate: beige,
  },
  darkGrey500,
  tabs: {
    default: silverGrey,
    active: primary,
    bg: white,
    activeBg: '#cd9c00',
  },
  tooltip: {
    color: white,
    bg: '#fffcf1',
  },
  message: {
    color: dark,
    bg: beige,
    fontSize: '18px',
    fontWeight: 400,
  },
  gamboge: '#b68116',
  menuInlineTooltip: {
    bg: white,
    color: grey,
    active: primary,
  },
  group: {
    active: { bg: '#dff5ff' },
  },
  input: {
    bg: white,
    color: darkGrey,
    border: '#d9d9d9',
  },
  white: white,
  beige: beige,
  logoText: darkGrey,
  form: {
    fontSize: '16px',
    label: darkGrey,
    oddBg: whisper,
    evenBg: white,
    moOddBg: whisper,
    moEvenBg: white,
  },
  layout: {
    padding: '0 32px 16px 96px',
  },
  layoutHeader: {
    padding: '0 32px 0 96px',
  },
  layoutContent: {
    height: 'calc(100vh - 64px)',
  },
  treeview: {
    icon: darkGrey500,
    iconHover: darkGrey,
    mapIcon: '#7fbad4',
    color: darkGrey,
  },
  infowindow: {
    border: primary,
    borderRadius: '4px',
    titleBg: primary,
    titleColor: white,
    columnBg: '#cce4ee',
    width: '143px',
    height: '136px',
  },
  chart: {
    xAxis: {
      lineColor: darkGrey,
      labels: {
        color: darkGrey,
        fontSize: '14px',
      },
    },
    yAxis: {
      gridLineColor: darkGrey,
      labels: {
        color: darkGrey,
        fontSize: '14px',
      },
      title: {
        color: darkGrey,
      }
    },
    legend: {
      item: {
        color: darkGrey,
        fontSize: '16px',
      },
      itemHover: {
        color: darkGrey,
      },
      title: {
        color: darkGrey,
        fontSize: '16px',
      }
    }
  }
}

module.exports = theme
