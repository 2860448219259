import { SVGProps } from 'react'
import cx from 'classnames'

const Energy = ({ className, ...props }: SVGProps<any>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('menu-icon-energy', className)}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M7.034 6.386H2.87a.88.88 0 0 0-.87.89v8.894c0 .491.39.89.87.89h3.598M13.934 17.06h4.262c.408 0 .738-.399.738-.89V7.275c0-.491-.33-.89-.738-.89h-3.66M19.826 9.054h1.304c.48 0 .87.398.87.89V13.5a.88.88 0 0 1-.87.89h-1.304"
        stroke="#878787"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m11.462 9.784 1.026-5.817a.2.2 0 0 0-.364-.144L6.04 13.181a.2.2 0 0 0 .197.307l3.466-.505a.2.2 0 0 1 .226.233l-1.026 5.817a.2.2 0 0 0 .364.144l6.084-9.358a.2.2 0 0 0-.197-.307l-3.466.505a.2.2 0 0 1-.226-.233z"
        fill="#878787"
      />
    </g>
  </svg>
)

export default Energy
