import { SVGProps } from 'react'
import cx from 'classnames'

const Equipment = ({ className, ...props }: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('menu-icon-equipment', className)}>
    <path
      d="M12 19.559V16.26m-8-.062h16V4H4v12.198zm16-3.667H4m13.26 7.22H6.74"
      stroke="#878787"
      strokeWidth="1.7"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Equipment
