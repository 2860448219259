import React from 'react'
import { useTranslation } from 'react-i18next'
import { concat, pathOr, pluck, slice } from 'ramda'
import { Row, Col } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import Table from '@components/Table'
import { DeviceType } from '@/constants'
import { useAnalysisList, useInvertersList } from '@lib/report'
import { toThousandSeparator } from '@/utils/webHelper'
import { usePowerList } from '@lib/power'

function ReportList({ exporting, query, onExportReport, onTableChange }: any) {
  const { t } = useTranslation()

  const { dataSource, isLoading, columns = [] } = useInvertersList(query)

  const [currentPage, setCurrentPage] = React.useState(1)

  const tableColumns: ColumnProps<any>[] = concat(
    [
      {
        dataIndex: 'createAtStr',
        title: t('report:time'),
        onCell: () => ({
          className: 'text-pagination',
        }),
      },
    ],
    columns.map((c: any) => ({
      ...c,
      onHeaderCell: () => ({
        className: 'text-primary',
      }),
    }))
  )

  return (
    <Row className="mt-40">
      <Col span={24} className="flex mb-4">
        <span className="text-white mr-24">發電量比較 (kWh)</span>
        <span className="font-medium top-3 text-pagination">
          {t('common:total count', {
            total: toThousandSeparator(dataSource?.content?.length),
          })}
        </span>
      </Col>
      <Col span={24}>
        <Table
          className="separate-cell"
          rowKey="createAtStr"
          dataSource={{
            ...(dataSource as any),
            page: currentPage,
            content: slice(
              (currentPage - 1) * 10,
              currentPage * 10,
              dataSource.content
            ),
          }}
          loading={isLoading}
          columns={tableColumns}
          onChange={p => setCurrentPage(p.current || 1)}
          pagination={{
            showSizeChanger: false,
            total: dataSource?.content.length || 1,
            pageSize: 10,
          }}
        />
      </Col>
    </Row>
  )
}

export default React.memo(ReportList)
