import styled, { css } from 'styled-components'
import { Input } from 'antd'
import React from 'react'
import { SearchProps, InputRef } from 'antd/lib/input'

const StyledInput = styled(Input)`
  &.ant-input-affix-wrapper {
    background-color: ${p => p.theme.input.bg} !important;
    border: 1px solid ${p => p.theme.input.border};
    border-radius: 4px;

    .ant-input[type='text'] {
      font-size: 16px;
      line-height: 1.375;
      color: ${p => p.theme.input.color} !important;
      background-color: ${p => p.theme.transparent} !important;
    }

    &:hover,
    &:focus {
      border-color: ${p => p.theme.input.border};
    }

    &:focus,
    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
  }

  &.ant-input[type='text'] {
    font-size: 16px;
    line-height: 1.375;
    color: ${p => p.theme.input.color} !important;
  }

  &.ant-input {
    background-color: ${p => p.theme.input.bg} !important;
    border: 1px solid ${p => p.theme.input.border};
    border-radius: 4px;

    :hover,
    :focus {
      border-color: ${p => p.theme.input.border};
    }

    :focus {
      box-shadow: none;
    }
  }

  .ant-input-clear-icon {
    line-height: 0;
    border-radius: 100%;
    color: rgba(0, 0, 0, 0.25);
    background: ${p => p.theme.light};
  }

  &.sks-search {
    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      border-radius: 4px;

      .ant-input {
        font-size: 16px;
      }

      &:hover,
      &:focus {
        border-color: ${p => p.theme.input.border};
      }
      &:focus {
        box-shadow: none;
      }
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border-color: ${p => p.theme.input.border};
    }
  }

  &.placeholder-sm {
    .ant-input {
      ::placeholder {
        font-size: 13px;
      }
    }
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${p => p.theme.danger};
  }

  &.ant-input-group-addon > .ant-input-search-button {
    border-left: 0 !important;
    > .anticon-search {
      color: ${p => p.theme.primary};
    }
  }
`
const StyledInputSearch = styled(Input.Search)`
  .ant-input-wrapper {
    position: relative;
    transition: width 0.3s;
    > .ant-input {
      border-radius: 4px;
    }
    .ant-input-suffix {
      position: absolute;
      right: 35px;
      z-index: 2;
    }
    > .ant-input-group-addon {
      position: absolute;
      top: 0px;
      right: 35px;
      left: auto !important;
      z-index: 999;
      border-color: transparent;
      background: transparent;

      > .ant-input-search-button {
        background: transparent;
        border-color: transparent !important;
        > .anticon-search {
          color: ${p => p.theme.primary};
        }
      }
    }
  }
`

const StyledCollapseInputSearch = styled(StyledInputSearch)<{
  collapse?: boolean | number
}>`
  .ant-input-wrapper {
    position: relative;
    transition: width 0.3s;
    ${p =>
      p.collapse
        ? css`
            width: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
          `
        : css``}

    .ant-input {
      border-radius: 4px;
      transition: opacity 0.01s;
      ${p =>
        p.collapse
          ? css`
              border: 0px;
              visibility: hidden;
              opacity: 0;
            `
          : css``}
    }

    .ant-input-affix-wrapper {
      ${p =>
        p.collapse
          ? css`
              border: 0px;
              visibility: hidden;
            `
          : css``}
    }

    .ant-input-suffix {
      position: absolute;
      right: 35px;
      z-index: 2;
    }

    .ant-input-group-addon {
      position: absolute;
      top: 0px;
      right: 35px;
      left: auto !important;
      z-index: 999;
      border-color: transparent;
      background: transparent;

      > .ant-input-search-button {
        background: transparent;
        border-color: transparent !important;
        > .anticon-search {
          color: ${p => p.theme.primary};
        }
      }
    }
  }
`

const CollapseSearch = React.memo<SearchProps>(props => {
  const { onSearch, ...others } = props

  const containerRef = React.useRef<HTMLSpanElement>(null)
  const inputRef = React.useRef<InputRef>(null)

  const [collapse, setCollapse] = React.useState(true)

  React.useEffect(() => {
    const detectClickOutside = (e: MouseEvent) => {
      if (
        !containerRef.current?.contains(e.target as any) &&
        !inputRef.current?.input?.value
      ) {
        setCollapse(true)
      }
    }
    window.addEventListener('click', detectClickOutside)
    return () => {
      window.removeEventListener('click', detectClickOutside)
    }
  }, [])

  return (
    <span ref={containerRef}>
      <StyledCollapseInputSearch
        collapse={collapse ? 1 : 0}
        ref={inputRef}
        onSearch={(v, e) => {
          if (collapse) {
            e?.stopPropagation()
            e?.preventDefault()
            setCollapse(false)
          } else {
            onSearch && onSearch(v, e)
          }
        }}
        {...others}
      />
    </span>
  )
})

export default Object.assign(StyledInput, {
  Search: StyledInputSearch,
  CollapseSearch,
})
