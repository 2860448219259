import { SVGProps } from 'react'

const AddCircleSVG = (props: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M8 0C3.581 0 0 3.581 0 8c0 4.419 3.581 8 8 8 4.419 0 8-3.581 8-8 0-4.419-3.581-8-8-8zm4.86 12.86c-.63.632-1.366 1.13-2.185 1.474-.847.36-1.747.541-2.675.541-.928 0-1.828-.181-2.675-.54-.819-.346-1.553-.841-2.186-1.473-.631-.63-1.128-1.367-1.473-2.185-.36-.849-.541-1.749-.541-2.677 0-.928.181-1.828.54-2.675.346-.819.841-1.553 1.473-2.186.63-.631 1.367-1.128 2.185-1.473.849-.36 1.749-.541 2.677-.541.928 0 1.828.181 2.675.54.819.346 1.553.841 2.186 1.473.631.63 1.128 1.367 1.473 2.185.36.849.541 1.749.541 2.677 0 .928-.181 1.828-.54 2.675-.346.819-.841 1.555-1.474 2.186zm-1.422-5.415H8.57V4.578c0-.31-.251-.562-.562-.562-.311 0-.563.251-.563.562v2.867H4.562c-.31 0-.562.252-.562.563 0 .31.252.562.563.562h2.882v2.852c0 .31.252.562.563.562.31 0 .562-.251.562-.562V8.57h2.867c.311 0 .563-.251.563-.562 0-.311-.252-.563-.563-.563z"
                transform="translate(-696 -633) translate(240 110) translate(40 515) translate(416 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default AddCircleSVG
