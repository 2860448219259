import React from 'react'
import {
  pathOr,
  head,
  isNil,
  path,
  ifElse,
  identity,
  of,
  join,
  props,
} from 'ramda'
import { convertData, useLazyQuery } from '@/services'
import type { QueryVariables } from '@/services'
import { useLightEnums } from '@/light'
import { toMoment, dateToYYYYMMDD } from '@/utils/webHelper'
import { Device, Chiller, Gateway, Inverter } from './device.types'
import { useAllSessions } from '@/session'
import { useTranslation } from 'react-i18next'
const { compose } = require('ramda')

function useConvertData<T extends Device>() {
  const { byId, getFullSessions, findStoreSession } = useAllSessions()

  const { t } = useTranslation()

  const {
    activeStateEnumByKey,
    deviceTypeEnumByKey,
    getDeviceStatus,
  } = useLightEnums()

  return (data: any) =>
    convertData<T>({
      converter: x => {
        const deviceStatusStr = getDeviceStatus(x)
        const secondSessionId = getFullSessions(x.sessionId).length === 2 ? x.sessionId : pathOr('', [x.sessionId, 'parentSessionId'], byId)

        return {
          ...x,
          deviceStatusStr,
          deviceTypeStr:
            pathOr('', [x.deviceType], deviceTypeEnumByKey) ||
            t('common:not setting'),
          activeStr: pathOr('', [x.active], activeStateEnumByKey),
          installTime: toMoment(x.installTime),
          installTimeStr: dateToYYYYMMDD(x.installTime),
          expiredDate: toMoment(x.expiredDate),
          expiredDateStr: dateToYYYYMMDD(x.expiredDate),
          sessionName: pathOr('', [secondSessionId, 'name'], byId),
          sessionId: secondSessionId,
          storeSessionId: findStoreSession(x.sessionId)?.id,
          fullSessiosName: getFullSessions(x.sessionId).map(s => s.name),
          secondSessionId,
          gps: `${pathOr([], ['lat'], x)},${pathOr([], ['lon'], x)}`
        }
      },
    })(data)
}

const endpoint = '/device/{deviceType}/{id}'

export const useLazyQueryDevice = (options: QueryVariables = {}) => {
  const { refetch, data, ...rest } = useLazyQuery(endpoint, {
    ...options,
    select: res => path(['data'], res),
  })

  const convertData = useConvertData()

  const [_data, setData] = React.useState<any>(null)

  React.useEffect(() => {
    setData(
      compose(head, pathOr([], ['content']), convertData, (v: any) => ({
        data: ifElse(isNil, identity, of)(v),
      }))(data)
    )
  }, [data])

  return {
    ...rest,
    data: _data,
    setData,
    loading: rest.isLoading,
    loadingData: rest.isLoading,
    queryDevice: refetch,
  }
}
