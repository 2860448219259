import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { MenuProps } from 'antd'
import { Menu, Layout } from 'antd'
import { is, pathOr, takeLast } from 'ramda'
import styled from 'styled-components'
import cx from 'classnames'
// import { AlarmModes } from '@/graphql/alarm'
import * as Icons from '@icons'
import { useProfile } from '@/system'
import history from '@/routes/history'
// import { useAlarmDrawer } from '@/hooks/useAlarmDrawer'
import { useRoutes, Route, RouteItem } from '@/routes'
import * as routes from 'src/routes'

type MenuItem = Required<MenuProps>['items'][number]

const { has } = require('ramda')

const style = { height: '100%', borderRight: 0 }

function SideMenu() {
  const [collapsedMenu, setCollapsedMenu] = React.useState(true)
  const { t } = useTranslation()

  const location = useLocation()

  const { _routes } = useRoutes(routes)

  const { profile } = useProfile()

  const [defaultOpenKey, pathname] = location.pathname.split('/').slice(1)

  const [openKeys, setOpenKeys] = React.useState([])

  const currentRoute = React.useCallback(() => {
    const pathName = location.pathname === '/' ? '/dashboard' : location.pathname
    const isMatch = (regex: string) => pathName.match(new RegExp(regex, 'gi'))
    const items = _routes.map((route: any) => (
      route.isHideOnMenu ? (
        isMatch(route.pathNoParams) &&
        _routes.filter((r : any) => r.code === route.parentCode).map((i: any) => ({
          ...i,
          parentId: i.id
        }))
      ) : (
        !route.isSubMenu ? (
          isMatch(route.path) ? [{
            ...route,
            parentId: route.id
          }] : []
        ) : (
          route.children.filter((c: any) => isMatch(c.path)).map((i: any) => ({
            ...i,
            parentId: route.id
          }))
        )
      )
    ))
    return pathOr([], [0, 0], items.filter((i: any) => i?.length))
  }, [_routes]) // eslint-disable-line

  const currentRouteKey = currentRoute()

  const handleOpenChange = (openKeys: React.Key[]) => {
    setOpenKeys(takeLast(1, openKeys) as any) // ramda對ts的支援似乎還不夠好...型別會錯
  }

  const handleTitleClick = (item: any) => {
    // handleModeChange(AlarmModes.ALL)()
    // setRectangle(null)
    history.push(`${item.path}`)
  }

  const handleLogoClick = () => {
    // handleModeChange(AlarmModes.ALL)()
    // setRectangle(null)
    history.push('/')
  }

  const handleMenuClick = () => {
    // handleModeChange(AlarmModes.ALL)()
  }

  const renderIcon = (item: RouteItem) => {
    let Icon: any = item.icon

    if (is(String, Icon) && has([Icon])(Icons)) {
      const Component = (Icons as any)[Icon]
      Icon = <Component />
    }
    if (is(Function, Icon)) {
      Icon = <Icon />
    }

    return Icon
  }

  const renderMenuItem = (item: RouteItem) => {
    if (!item.readable) {
      return
    }

    return {
      key: item.id,
      disabled: item.disabled,
      className: 'side-menu-item',
      label: (
        <Link to={`${item.path}`} onClick={handleMenuClick}>
          {item.path ? t(`menu:${item.path}`) : item.title}
        </Link>
      ),
    } as MenuItem
  }

  const menuItems: MenuItem[] = React.useMemo(() => {
    return _routes.map((item: Route, idx: number) => {
      if (!item.readable || item.isHideOnMenu) {
        return null
      }

      if (!item.isSubMenu) {
        const to = `${item.path}`
        return {
          key: item.id,
          icon: renderIcon(item),
          disabled: item.disabled,
          className: `main-menu ${pathOr(null, ['parentId'], currentRouteKey) === item.id && 'ant-menu-item-active'}`,
          label: collapsedMenu ? null : (
            <Link to={to}>
              {item.mainKey ? t(`menu:${item.mainKey}`) : item.title}
            </Link>
          ),
        }
      }

      let subMenuItem = item.children
        ? {
            key: item.id,
            icon: renderIcon(item),
            disabled: item.disabled,
            // className: 'sub-menu',
            className: `sub-menu ${pathOr(null, ['parentId'], currentRouteKey) === item.id && 'ant-sub-menu-item-active'}`,
            label: !collapsedMenu && (
              <span>
                {item.mainKey ? t(`menu:${item.mainKey}`) : item.title}
              </span>
            ),
            children: item.children.map(x => renderMenuItem(x)),
          }
        : {
            key: item.id,
            disabled: item.disabled,
            className: item.disabled
              ? 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-selected ant-menu-submenu-disabled'
              : 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-selected',
            label: (
              <div className="ant-menu-submenu-title">
                {renderIcon(item)}
                <span className="inline-flex">{item.title}</span>
              </div>
            ),
            onTitleClick: item.disabled
              ? () => {}
              : () => handleTitleClick(item),
          }

      return subMenuItem
    })
  }, [_routes]) //eslint-disable-line

  const handleCollapsedMenu = () => {
    setCollapsedMenu(i => !i)
  }

  React.useEffect(() => {
    if (collapsedMenu) {
      setOpenKeys([])
    }
  }, [collapsedMenu])

  return (
    <Sider
      width={collapsedMenu ? 60 : 200}
      onMouseEnter={handleCollapsedMenu}
      onMouseLeave={handleCollapsedMenu}>
      <StyledMenu
        items={[
          {
            key: 'logo',
            className: 'side-menu-logo',
            label: (
              <Logo
                style={{ height: 96 }}
                onClick={handleLogoClick}
                className="cursor-pointer">
                <img src={profile.url} alt={profile.name} />
                {collapsedMenu ? null : (
                  <div className="logo-text">{profile.name}</div>
                )}
              </Logo>
            ),
          },
          ...menuItems.filter(i => i),
        ]}
        mode="inline"
        className={cx({ 'is--collapsed': collapsedMenu })}
        openKeys={openKeys}
        defaultSelectedKeys={[pathname || defaultOpenKey]}
        defaultOpenKeys={[defaultOpenKey]}
        onOpenChange={handleOpenChange}
        style={style}></StyledMenu>
    </Sider>
  )
}

const StyledMenu = styled(Menu)`
  background: ${p => p.theme.menu.bg};

  &.is--collapsed {
    .ant-menu-submenu-arrow {
      visibility: hidden !important;
    }
  }

  .ant-menu-item.main-menu {
    padding: 0px 16px !important;
  }

  .ant-menu-submenu-title {
    color: ${p => p.theme.menu.color};
    padding: 0px 16px !important;

    &:hover {
      color: ${p => p.theme.menu.mainActiveColor};
      background: ${p => p.theme.menu.mainActiveBg};

      .menu-icon-dashboard,
      .menu-icon-report,
      .menu-icon-authority,
      .menu-icon-system {
        path {
          fill: ${p => p.theme.menu.mainActiveColor};
        }
      }

      .menu-icon-energy {
        g {
          path:first-child {
            stroke: ${p => p.theme.menu.mainActiveColor};
          }
          path:nth-child(2) {
            fill: ${p => p.theme.menu.mainActiveColor};
          }
        }
      }

      .menu-icon-equipment {
        path {
          stroke: ${p => p.theme.menu.mainActiveColor};
        }
      }
    }
  }

  &.ant-menu-inline {
    .ant-menu-item {
      color: #fff;
      font-size: 14px;

      &:hover {
        color: ${p => p.theme.menu.subActiveColor};
        background: ${p => p.theme.menu.subActiveBg};
      }
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: ${p => p.theme.menu.subActiveBg};
    }

    .ant-menu-inline
      .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      background: ${p => p.theme.menu.color};
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      width: 100%;
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::before {
      background: ${p => p.theme.menu.color};
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title:hover
      .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-inline
      > .ant-menu-submenu-title:hover
      .ant-menu-submenu-arrow::after {
      background: ${p => p.theme.menu.color};
    }

    // .ant-menu-submenu-selected,
    .ant-menu-item-active,
    .ant-sub-menu-item-active {
      color: ${p => p.theme.menu.mainActiveColor} !important;
      background: ${p => p.theme.menu.subSelectedBg} !important;

      > span.ant-menu-title-content > a {
        color: ${p => p.theme.menu.mainActiveColor} !important;
      }

      .menu-icon-dashboard,
      .menu-icon-report,
      .menu-icon-authority,
      .menu-icon-system {
        path {
          fill: ${p => p.theme.menu.mainActiveColor};
        }
      }

      .menu-icon-energy {
        g {
          path:first-child {
            stroke: ${p => p.theme.menu.mainActiveColor};
          }
          path:nth-child(2) {
            fill: ${p => p.theme.menu.mainActiveColor};
          }
        }
      }

      .menu-icon-equipment {
        path {
          stroke: ${p => p.theme.menu.mainActiveColor};
        }
      }

      > .ant-menu-submenu-title {
        color: ${p => p.theme.menu.mainActiveColor} !important;
      }
    }
  }

  .main-menu {
    &.ant-menu-item {
      a {
        font-size: 14px;
        font-weight: normal;
        color: ${p => p.theme.menu.color};
      }

      i.icon-media > svg > g {
        stroke: ${p => p.theme.menu.color};
      }
    }

    &.ant-menu-item:hover,
    &.ant-menu-item-selected {
      background: ${p => p.theme.menu.mainActiveBg} !important;

      a {
        color: ${p => p.theme.menu.mainActiveColor};
      }

      &:after {
        display: none;
      }
    }
  }

  .sub-menu {
    color: #fff;

    // &.ant-menu-submenu-selected,
    &.ant-sub-menu-item-active {
      > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
      > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
        background: ${p => p.theme.menu.mainActiveColor} !important;
      }
    }

    .ant-menu-sub.ant-menu-inline {
      background: transparent;
    }
  }

  .side-menu-item {
    &.ant-menu-item {
      text- &:active {
        background: ${p => p.theme.menu.bg};
      }

      > span > a {
        color: ${p => p.theme.menu.color};
        font-weight: normal;

        &:hover {
          color: ${p => p.theme.menu.hoverColor};
        }
      }

      &.ant-menu-item-selected {
        background: ${p => p.theme.menu.subActiveBg};

        span > a {
          color: ${p => p.theme.menu.subActiveColor} !important;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  .side-menu-logo {
    height: 96px;
    line-height: 1rem;
    padding: 0px !important;
    margin: 0px !important;

    &:hover {
      color: inherit !important;
      background: inherit !important;
    }

    &.ant-menu-item-selected {
      background: transparent !important;
    }
    ::after {
      border-right: none !important;
    }
  }
`

const Sider = styled(Layout.Sider)`
  position: absolute;
  height: 100%;
  z-index: 999;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);

  &.ant-layout-sider {
    background: ${p => p.theme.menu.bg};
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  text-align: center;
  font-weight: 500;

  .logo-text {
    font-size: 13px;
    color: ${p => p.theme.logoText};
  }

  img {
    width: 100%;
    height: auto;
    max-width: 127px;
    max-height: 60px;
    margin-bottom: 2px;
  }
`

export default SideMenu
