import * as React from 'react'
import { GoogleMap, GoogleMapProps } from '@react-google-maps/api'
import { mergeRight } from 'ramda'
import { ControlPosition, MapCenter } from './map.types'
import useLoadGoogleMap from './useGoogleMap'
import useGoogleMap from './GoogleMapProvider'

export interface MapProps extends GoogleMapProps {
  children?: React.ReactNode
  onClick?: (e: any) => void

  loading?: boolean
  renderLoading?: any
  zoom?: number
  defaultCenter?: MapCenter
  center?: MapCenter
  mapContainerStyle?: React.CSSProperties
  options?: any
  showControl?: boolean
}

export const defaultMapCenter = {
  lat: 24.028781192118768,
  lng: 120.56263625859233,
}

export const defaultZoom = 13

export const defaultMapStyle = { height: '728px', width: '100%' }

export const defaultMapOptions: google.maps.MapOptions = {
  zoomControlOptions: { position: ControlPosition.LEFT_BOTTOM },
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  controlSize: 28,
  styles: []
}

const InternalGoogleMap: React.ForwardRefRenderFunction<HTMLElement, MapProps> = (
  (props, ref: React.Ref<any>) => {
    const {
      children,
      loading = false,
      renderLoading,
      zoom = defaultZoom,
      defaultCenter = defaultMapCenter,
      center,
      mapContainerStyle = {},
      options = {},
      showControl = true,
      onClick,
      ...restMapProps
    } = props;

    const mapRef = React.useRef<any>(null)
    const { isLoaded, loadError } = useLoadGoogleMap()
    const { handleClosedAction, handleMapLoad } = useGoogleMap()
    const [mapCenter, setMapCenter] = React.useState<MapCenter>(defaultCenter)

    React.useEffect(() => {
      center && setMapCenter(center)
    }, [center])

    if (loadError) {
      return <div>Map cannot be loaded right now, sorry.</div>
    }

    if (loading || !isLoaded) {
      return renderLoading ? renderLoading : <div>loading</div>
    }

    const handleClickedMap = (e?: google.maps.MapMouseEvent) => {
      handleClosedAction()

      if (onClick) {
        onClick(e)
      }
    }

    const handleLoaded = (map: google.maps.Map) => {
      const _map = document.querySelectorAll<HTMLElement>('#map')!
      setTimeout(() => {
        _map.forEach(el => el.style.visibility = 'visible')
      }, 500)
      handleMapLoad(map)
      mapRef.current = map
    }

    const handleMapCenter = () => {
      if (mapRef?.current) {
        const currentMapCenter = mapRef.current.getCenter().toJSON()
        setMapCenter(currentMapCenter)
      }
    }

    const mapOptions = {
      ...defaultMapOptions,
      zoomControl: showControl
    }

    return (
      <GoogleMap
        {...restMapProps}
        id="map"
        zoom={zoom}
        center={mapCenter}
        options={mergeRight(mapOptions, options)}
        mapContainerStyle={mergeRight(defaultMapStyle, mapContainerStyle)}
        onLoad={handleLoaded}
        onClick={handleClickedMap}
        onDragEnd={handleMapCenter}
        ref={ref}>
        {children}
      </GoogleMap>
    )
  }
)

export default React.forwardRef<HTMLElement, MapProps>(InternalGoogleMap)
