const RightOutline = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <path
          d="M4.697 13.995a.69.69 0 0 0 .428-.15l6.622-5.326a.683.683 0 0 0 0-1.064L5.153 2.156a.683.683 0 0 0-.855 1.064l5.932 4.767-5.961 4.794a.683.683 0 0 0 .428 1.214z"
          fill="silver"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default RightOutline
