import React from 'react'
import { assocPath, identity, ifElse, is, of, isEmpty, keys } from 'ramda'
import { FormInstance } from 'antd/lib/form'
import { TablePaginationConfig } from 'antd/lib/table'
import type {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface'
import { DeviceStatusEnum } from '../../desktop/src/components/DeviceStatusSelect'
import { getRangeDate } from '@/utils/webHelper'

export type TableChangeHandler<T = any> = (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<T> | SorterResult<T>[],
  extra: TableCurrentDataSource<T>
) => void

export type UseSearchProps = {
  form?: FormInstance
  initial?: { [key: string]: any }
  dateRangeField?: [string, string]
  dateFormat?: string
  transform?: (values: any) => any
}

export default function useSearch<T = any>({
  form,
  initial = {},
  dateFormat,
  dateRangeField = ['start', 'end'],
  transform = values => values,
}: UseSearchProps = {}) {
  const [query, setQuery] = React.useState<any>({
    pageNum: 0,
    pageSize: 10,
    ...initial,
  })
  const [pageNum, setPageNum] = React.useState(1)

  const handleChange =
    <T = any>(field: string | string[]) =>
    (value: T, converter: (value: T) => any = (value: T) => value) => {
      const _field = ifElse(is(Array), identity, of)(field)

      setQuery(assocPath(_field, converter(value), query))
    }

  const handleSearch = () => {
    if (!form) {
      return
    }

    const { date = [], deviceStatus = '', ...values } = form.getFieldsValue()
    const [startDate, endDate] = getRangeDate(date, dateFormat)
    const [startField, endField] = dateRangeField

    setPageNum(1)
    setQuery((prev: any) => {
      // 清空前一次的設備狀態
      keys(DeviceStatusEnum).forEach(statusKey => {
        if (keys(prev).includes(statusKey)) {
          delete prev[statusKey]
        }
      })

      const q = {
        ...prev,
        ...values,
        pageNum: 0,
        [startField]: startDate,
        [endField]: endDate,
        reactQueryCache: new Date().getTime(),
        ...(isEmpty(deviceStatus) ? {} : { [deviceStatus]: true }),
      }

      if (transform) {
        return transform(q)
      }
      return q
    })
  }

  const handleTableChange: TableChangeHandler<T> = ({ current, pageSize }) => {
    if (!!current) {
      setPageNum(current)
      setQuery((prev: any) => {
        const q = {
          ...prev,
          current: Number(current) - 1,
          size: pageSize,
          pageNum: Number(current) - 1,
        }

        return q
      })
    }
  }

  return {
    query,
    pageNum,
    setQuery,
    setPageNum,
    handleChange,
    handleSearch,
    handleTableChange,
  }
}
