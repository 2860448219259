import { useQuery, UseQueryOptions } from 'react-query'
import { path, pathOr } from 'ramda'
import type { Device } from '@/device'
import { useAllSessions } from '@/session'
import React from 'react'
import { useConvertData, useDeviceStatusEnums } from './convert'

/**
 *
 * @param deep:是否搜尋區域深層
 */
export function useDevices<T extends Device>(
  variables?: {
    [key: string]: any
  },
  options?: UseQueryOptions
) {
  const { data, ...rest } = useQuery(
    ['/device/{deviceType}/{pageNum}/{pageSize}', { deep: true, ...variables }],
    { cacheTime: 0, ...options }
  )

  const dataSource = useConvertData<T>()(data)

  return {
    ...rest,
    dataSource,
    data,
    loading: rest.isLoading,
  }
}

/**
 * 取得沒有分頁的裝置列表
 * @param variables
 * @param options
 * @returns
 */
export function useDeviceList<T extends Device>(
  variables?: {
    [key: string]: any
  },
  options?: UseQueryOptions
) {
  const { convertToActiveStr, convertToWorkStatusStr } = useDeviceStatusEnums()
  const { byId } = useAllSessions()

  const { data, ...rest } = useQuery(
    ['/device/{deviceType}', { deep: true, ...variables }],
    { cacheTime: 0, ...options }
  )

  const dataSource: T[] = React.useMemo(() => {
    return pathOr([], ['data'], data).map((x: any) => ({
      ...x,
      activeStr: convertToActiveStr(path(['active'], x)),
      workStatusStr: convertToWorkStatusStr(path(['workStatus'], x)),
      sessionName: pathOr('', [x.sessionId, 'name'], byId),
    }))
  }, [byId, convertToActiveStr, convertToWorkStatusStr, data])

  return {
    ...rest,
    dataSource,
    loading: rest.isLoading,
  }
}
