import React from 'react'
import { atom, useSetRecoilState, useRecoilValue } from 'recoil'

export const pageHeaderState = atom<{
  title?: string | React.ReactNode
  content: string | React.ReactNode
}>({
  key: 'pageHeaderState',
  default: {
    content: '',
  },
})

export function usePageHeader() {
  return useRecoilValue(pageHeaderState)
}

export function useSetPageHeaderContent(content: string | React.ReactNode) {
  const setHeader = useSetRecoilState(pageHeaderState)

  React.useLayoutEffect(() => {
    setHeader(prev => ({ ...prev, content }))

    return function cleanup() {
      setHeader({ content: '' })
    }
  }, [content]) // eslint-disable-line
}

export function useSetPageHeaderTitle(title: string | React.ReactNode) {
  const setHeader = useSetRecoilState(pageHeaderState)

  React.useLayoutEffect(() => {
    setHeader(prev => ({ ...prev, title }))

    return function cleanup() {
      setHeader(prev => ({ ...prev, title: undefined }))
    }
  }, [title]) // eslint-disable-line
}

export function PageHeader({ children }: any) {
  useSetPageHeaderContent(children)

  return <></>
}
