import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectProps } from 'antd/lib/select'
import Select from '@components/ui/Select'
import { useAllCaseField, CaseField } from '@lib/casefield'
import { path, pathOr } from 'ramda'

interface Props<T = any> extends SelectProps<T> {
  queryAll?: boolean
  placeholder?: string
  valueKey?: string
}

const AllCasesSelect = React.memo(
  ({
    queryAll,
    placeholder,
    valueKey = 'id',
    defaultActiveFirstOption,
    onChange,
    ...props
  }: Props) => {
    const { t } = useTranslation()

    const { dataSource, isFetched } = useAllCaseField()

    React.useEffect(() => {
      if (!isFetched) {
        return
      }
      if (!defaultActiveFirstOption) {
        return
      }
      onChange &&
        onChange(
          path([0, valueKey], dataSource.content),
          path([0], dataSource.content) as any
        )
    }, [isFetched])

    return (
      <Select<CaseField>
        {...props}
        onChange={onChange}
        placeholder={placeholder || t('light:select dist')}>
        {queryAll && (
          <Select.Option key="all" value="">
            全部分店
          </Select.Option>
        )}
        {dataSource.content.map(powerPlant => (
          <Select.Option
            key={powerPlant.id}
            value={pathOr('', [valueKey], powerPlant)}>
            {powerPlant.name}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export default AllCasesSelect
