import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'
import { Form } from 'antd'
import moment from 'moment'
import useSearch from '@/hooks/useSearch'
import { RangePicker } from '@components/DatePicker'
import Button from '@components/Button'
import Select from '@components/Select'
import PowerPlantSelect from 'src/components/AllCasesSelect'
import { DeviceType } from '@/constants'
import { useExportAlarmRpt } from '@lib/report'
import { getRangeDate, downloadFile } from '@/utils/webHelper'
import { rules } from '@/utils/form'
import Layout from '@components/Layout'
import {
  TYPE,
  searchYearSelectItems,
  searchMonthSelectItems,
  ReportSearchType,
  getLastMonthValue,
} from '../utils'
import { Asterisk } from '@components/Form'
import AlarmReportList from './AlarmReportList'
import LabelItem from 'src/components/LabelItem'

// prettier-ignore
enum TabItems { REPORT = 0, CHART = 1 }

function AlarmReport() {
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [isError, setIsError] = React.useState<boolean>()
  const [currentTab, setCurrentTab] = React.useState(TabItems.REPORT)
  const [reportType, setReportType] = React.useState<number>(TYPE.MONTH)
  const [initialVariables, seInitialVariables] = React.useState<any>({})
  const [queryVariables, setQueryVariables] = React.useState<any>({})

  const { handleSearch, handleTableChange } = useSearch({
    form,
    transform: (variables: any) => {
      form.validateFields().then(value => {
        const { startDate, endDate, year, month, ...others } = variables

        let cDate = {}

        switch (reportType) {
          case TYPE.YEAR:
            cDate = { conditionDate: year }
            break
          case TYPE.MONTH:
            const currentYear = moment().year()
            cDate = { conditionDate: `${currentYear}/${month}` }
            break
          default:
            break
        }

        const params = {
          ...others,
          start: variables.startDate,
          end: variables.endDate,
          year,
          month,
          date: value.date,
          ...cDate,
        }
        setQueryVariables(params)
      })
    },
  })

  const [exporting, handleExport] = useExportAlarmRpt({
    variables: queryVariables,
    onSuccess: (blob: any) => {
      downloadFile({
        blob,
        filename: 'report',
      })
    },
  })

  const handleExportReport = () => {
    if (isEmpty(queryVariables)) {
      return
    }

    form.validateFields().then(values => {
      handleExport()
    })
  }

  const handleTabChange = (target: number) => () => {
    setCurrentTab(target)
    setIsError(false)

    const deviceType = target === TabItems.CHART ? DeviceType.LIGHT : ''
    const reportType = TYPE.MONTH

    setQueryVariables({
      ...initialVariables,
      deviceType,
      reportType,
    })

    setReportType(reportType)
    form.resetFields()
    form.setFieldsValue({ deviceType, reportType })
  }

  React.useEffect(() => {
    if (currentTab === TabItems.REPORT) {
      const data = getQueryVariables()

      const params = { current: 0, size: 10, ...data }
      setQueryVariables(params)
      seInitialVariables(params)
    }
  }, []) // eslint-disable-line

  //定義查詢參數
  const getQueryVariables = React.useCallback(() => {
    let values: any = {}

    const data = form.getFieldsValue()
    switch (reportType) {
      case TYPE.YEAR:
        values = { conditionDate: data.year }
        break
      case TYPE.MONTH:
        const currentYear = moment().year()
        values = { conditionDate: `${currentYear}/${data.month}` }
        break
      case TYPE.DAY:
        const [start, end] = data.date ? getRangeDate(data.date, 'x') : []

        values = { start, end }
        break
      default:
        break
    }

    return {
      ...data,
      ...values,
    }
  }, [reportType, form])

  const handleFieldsChange = (changedFields: any) => {
    const type = form.getFieldValue('reportType')

    if (changedFields && changedFields[0]?.name[0] === 'reportType') {
      setReportType(type)
    }

    if (currentTab === TabItems.CHART) {
      const err = form.getFieldError('deviceType')
      const isTypeErr = !isEmpty(err)
      if (isTypeErr) {
        setIsError(isTypeErr)
        return
      }
    }

    if (type === TYPE.DAY) {
      const err = form.getFieldError('date')
      const isDateErr = !isEmpty(err)

      if (isDateErr) {
        setIsError(isDateErr)
        return
      }
    }

    setIsError(false)
  }

  const handleDisabledDate = (current: any) => {
    return current >= moment().endOf('day')
  }

  return (
    <Layout>
      <Form
        form={form}
        layout="inline"
        style={{ justifyContent: 'space-between' }}
        onFieldsChange={handleFieldsChange}
        onFinish={handleSearch}>
        <div className="flex items-end flex-wrap">
          <LabelItem label="分店">
            <Form.Item name="sessionId">
              <PowerPlantSelect allowClear className="w-240" />
            </Form.Item>
          </LabelItem>
          <LabelItem label={t('report:statistics time')}>
            <div className="flex">
              <Form.Item name="reportType" initialValue={reportType}>
                <Select className="w-120">
                  {Object.values(TYPE).map(i => (
                    <Select.Option key={i} value={i}>
                      {ReportSearchType[i]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {reportType === TYPE.YEAR ? (
                <Form.Item name="year" initialValue={moment().year()}>
                  <Select placeholder={t('report:year')} className="w-130">
                    {searchYearSelectItems.map(i => (
                      <Select.Option key={i.value} value={i.value}>
                        {t('report:year', {
                          num: i.label,
                        })}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : reportType === TYPE.MONTH ? (
                <Form.Item name="month" initialValue={getLastMonthValue()}>
                  <Select placeholder={t('report:month')} className="w-130">
                    {searchMonthSelectItems.map(i => (
                      <Select.Option key={i.value} value={i.value}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <>
                  <Asterisk />
                  <Form.Item name="date" rules={[rules.required]}>
                    <RangePicker
                      placeholder={[t('report:startDate'), t('report:endDate')]}
                      format="YYYY.MM.DD"
                      className="ml-4 w-250"
                      disabledDate={handleDisabledDate}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </LabelItem>
          <Button
            color="primary"
            style={{ minWidth: 96 }}
            htmlType="submit"
            // actionType={ActionTypes.READABLE}
          >
            {t('common:search')}
          </Button>
        </div>
        <div className="flex items-end">
          <Button
            className="ml-16"
            size="small"
            color="ghost"
            icon="export"
            // onClick={handleExportReport}
            >
            {t('report:export')}
          </Button>
        </div>
      </Form>
      <AlarmReportList query={queryVariables} />
    </Layout>
  )
}

export default React.memo(AlarmReport)
