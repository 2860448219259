import Modal from '@components/ui/Modal'
import { Gateway, Inverter } from '@lib/device'
import { Tag } from 'antd'
import { append, filter, find, propEq } from 'ramda'
import React from 'react'
import GatewaySelect from 'src/components/GatewaySelect'
import InverterSelect from 'src/components/InverterSelect'
import { CloseCircleFilled } from '@ant-design/icons'
import message from '@lib/utils/message'

type SelectInverterModalProps = {
  onChange?: (inverterSeqs: number[]) => void
}

const SelectInverterModal = React.memo<SelectInverterModalProps>(props => {
  const { onChange } = props

  const [didMount, setDidMount] = React.useState(false)

  const [gateway, setGateway] = React.useState<Gateway | undefined>(undefined)
  const [inverter, setInverter] = React.useState<Inverter | undefined>(
    undefined
  )
  const [selectedInverters, setSelectedInverters] = React.useState<Inverter[]>(
    []
  )
  const [visibleInvertersModal, setVisibleInvertersModal] =
    React.useState(false)

  React.useEffect(() => {
    if (!didMount) {
      return
    }
    onChange &&
      onChange(selectedInverters.map(interter => interter.id as number))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInverters])

  React.useEffect(() => {
    setDidMount(true)
  }, [])

  return (
    <>
      <div
        onClick={() => {
          if (selectedInverters.length < 6) {
            setVisibleInvertersModal(true)
          } else {
            message({ content: '最多選擇 6 筆逆變器' })
          }
        }}
        style={{
          borderRadius: '4px',
          backgroundColor: 'rgba(255,255,255,0.1)',
          minHeight: '32px',
          width: '250px',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          padding: '3px 20px 3px 11px',
          cursor: 'pointer',
          position: 'relative',
        }}>
        {selectedInverters.length === 0 ? (
          <label style={{ color: 'rgba(255,255,255,0.5)', cursor: 'pointer' }}>
            最多選擇 6 筆逆變器
          </label>
        ) : (
          <>
            {selectedInverters.map(inverter => (
              <Tag
                key={inverter.id}
                className="m-3"
                closable
                onClose={() => {
                  setSelectedInverters(inverters =>
                    filter(i => i.id !== inverter.id, inverters)
                  )
                }}>
                {inverter.displayName}
              </Tag>
            ))}
            <CloseCircleFilled
              onClick={e => {
                setSelectedInverters([])
                e.stopPropagation()
              }}
              style={{
                position: 'absolute',
                right: '3px',
                opacity: 0.5,
                top: '8px',
              }}
            />
          </>
        )}
      </div>
      <Modal
        visible={visibleInvertersModal}
        onCancel={() => setVisibleInvertersModal(false)}
        title="選擇逆變器"
        okButtonProps={{ className: 'bg-btn-bg border-0', disabled: !inverter }}
        okText="加入"
        onOk={() => {
          if (!inverter) {
            return
          }
          const existInverter = find(
            propEq('id', inverter.id),
            selectedInverters
          )
          if (existInverter) {
            message({ content: '此逆變器已加入比較' })
            return
          }
          if (selectedInverters.length >= 6) {
            message({ content: '最多選擇 6 筆逆變器' })
            return
          }
          setSelectedInverters(inveters => append(inverter, inveters))
        }}
        cancelButtonProps={{
          className: 'bg-transparent text-btn-linkColor border-0 underline',
        }}>
        <div className="flex flex-col items-center">
          <div className="flex items-center">
            <label className="text-white">閘道器</label>
            <GatewaySelect
              onGatewaySelected={g => {
                setGateway(g)
                setInverter(undefined)
              }}
              showAll
              className="w-260 ml-6"
            />
          </div>
          <div className="flex mt-10 items-center">
            <label className="text-white">逆變器</label>
            <InverterSelect
              className="w-260 ml-6"
              gatewayId={gateway?.id as number}
              powerPlantId={gateway?.powerPlant?.id}
              onInverterSelected={inverter => setInverter(inverter)}
            />
          </div>
        </div>
      </Modal>
    </>
  )
})

export default SelectInverterModal
