import React from 'react'
import { useQuery } from 'react-query'
import { QueryVariables, convertData } from '@/services'
import { MutationOptions, useMakeMutation } from '@/services'
import { path, pathOr } from 'ramda'
import Store from 'store'
import { envStore } from '@/env'
import { CaseField } from './casefield.types'
import { DeviceType } from '@/constants'

export const useAllCaseField = () => {
  const { data, refetch, ...rest } = useQuery([
    '/sessionInfo/carrefour/list'
  ], {
    keepPreviousData: true
  })

  const dataSource = React.useMemo(() => {
    return convertData<CaseField>(
      {
        converter: (x: any) => ({
          ...x,
          deviceType: DeviceType.LOCATION,
          isAlarm: x?.generationStatus === 1,
          isRepair: x?.generationStatus === 2,
        }),
      },
      pathOr([], [], data)
    )
  }, [data?.data]) // eslint-disable-the-line

  return {
    ...rest,
    refetch,
    dataSource,
    data,
  }
}

export const useCaseFields = (variables?: { [key: string]: any }) => {
  const { data, refetch, ...rest } = useQuery([
    '/sessionInfo/carrefour/list/{pageNum}/{pageSize}',
    variables,
  ])

  const dataSource: any = React.useMemo(() => {
    if (!data) {
      return []
    }

    return convertData<any>({ field: ['data'] }, data)
  }, [data])

  return {
    ...rest,
    refetch,
    dataSource,
    data,
  }
}

export const useCaseField = ({ variables = {}, ...options }: any = {}) => {
  const { data, refetch, ...rest } = useQuery(['/sessionInfo/carrefour/{id}', variables], {
    enabled: !!variables?.id,
    suspense: false,
    select: res => path(['data'], res),
    ...options,
  })

  return {
    ...rest,
    refetch,
    data,
  }
}

export const useExportCaseField = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useQuery(
    ['/report/powerPlant/export', { deep: true, ...variables }],
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}

/**
 *
 * @param type: [image|video]
 * @returns
 */
export function uploadFile({ file, type }: any) {
  const formData = new FormData()
  formData.append('file', file)

  return fetch(`${envStore.apiBaseUrl}/upload/sessionService/${type}`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${Store.get(
        'carrefour_token'
      )}` as NonNullable<string>,
    },
  })
    .then(async (response: Response) => {
      let result: any

      if (response.status === 404) {
        return Promise.reject(response.statusText)
      }

      result = await response.json()
      return result
    })
    .catch((err: Error) => {
      console.error(`[uploaded image failed] ${err?.message}`)
      return err
    })
}

export function useUploadFile(options: MutationOptions = {}) {
  return useMakeMutation(uploadFile, options)
}
