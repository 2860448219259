import { SVGProps } from 'react'

const ExportSVG = ({
  className,
  color = 'currentColor',
  ...props
}: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={className}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M18.916 13.669a.84.84 0 0 0-.839.839v4.779a.435.435 0 0 1-.436.436H4.352a.435.435 0 0 1-.436-.436V5.527c0-.242.195-.436.436-.436h5.968a.84.84 0 0 0 0-1.678H4.355a2.114 2.114 0 0 0-2.112 2.111v13.76c0 1.163.947 2.11 2.112 2.11h13.291a2.114 2.114 0 0 0 2.112-2.112v-4.774a.845.845 0 0 0-.842-.84zm-8.76-4.927c-1.008 1.524-1.254 3.021-1.278 3.188a.84.84 0 0 0 .83.958c.412 0 .769-.31.83-.712.007-.056.22-1.322 1.042-2.543 1.08-1.603 2.705-2.421 4.82-2.428.032.002.06.004.084.004a.84.84 0 0 0 .839-.839v-.986l2.355 2.24-2.358 1.964v-.801a.84.84 0 0 0-1.678 0v2.592a.842.842 0 0 0 1.374.647l4.455-3.71a.837.837 0 0 0 .298-.617.838.838 0 0 0-.258-.637l-4.453-4.24a.837.837 0 0 0-1.413.607v2.13c-2.947.246-4.61 1.861-5.49 3.183z"
      />
    </g>
  </svg>
)

export default ExportSVG
