import styled from 'styled-components'

type Props = {
  children?: any
  style?: React.CSSProperties
  className?: string
}

export function Layout({ children, style, className, ...props }: Props) {
  return (
    <LayoutWrapper>
      <Content className={className} style={style} {...props}>
        {children}
      </Content>
    </LayoutWrapper>
  )
}

export const LayoutWrapper = styled.div`
  padding: ${p => p.theme.layout.padding};
  background: ${({ theme }) => theme.secondary};
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  padding: 24px;
  flex: 1;
  min-height: calc(100vh - 112px);
  color: ${p => p.theme.darkGrey};
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
`
export default Layout
Layout.LayoutWrapper = LayoutWrapper
Layout.Content = Content
