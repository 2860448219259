import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import Tooltip from '@components/Tooltip'
import ValueFormatter from 'src/components/ValueFormatter'

type Props = {
  data: {
    name?: string
    value: string | number | null
    unit?: string
  }
  style?: CSSProperties
}

export default function Column({ data, ...props }: Props) {
  return (
    <StyleColumn {...props}>
      <span className="label">
        <Tooltip title={data?.name}>
          {data?.name}
        </Tooltip>
      </span>
      <span className="labelValue">
        <Tooltip title={`${data.value} ${data?.unit}`}>
          {data.value}
          <span className="unit"> {data?.unit}</span>
        </Tooltip>
      </span>
    </StyleColumn>
  )
}

export const StyleColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  :last-child .labelValue {
    border-bottom: 1px solid #fff;
  }

  .unit {
    color: #878787;
  }

  .label {
    color: #4a4a4a;
    background-color: ${({ theme }) => theme.infowindow.columnBg};
    border-bottom: 1px solid #fff;
    flex: 1;
    height: 20px;
    padding: 0 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .labelValue {
    color: #0074a8;
    text-align: right;
    background-color: #fff;
    border-bottom: 1px solid #cce4ee;
    flex: 1;
    height: 20px;
    padding: 0 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
