export const STREETLIGHT_MANAGEMENT = '/street-light-management'

export const SWITCHBOX_MANAGEMENT = '/device/switchbox-management'

export const CAMERA_MANAGEMENT = '/device/camera-management'

export const TALKIE_MANAGEMENT = '/device/talkie-management'

export const WDETECT_MANAGEMENT = '/device/wdetect-management'

export const INVERTER_MANAGEMENT = '/device/inverter-management'

export const GATEWAY_MANAGEMENT = '/device/gateway-management'

export const REPAIR_MANAGEMENT = '/facility/repair-list'

export const CLOSED_REPAIR_MANAGEMENT = '/facility/closed-repair-list'

export const MAINTENANCE_MANAGEMENT = '/facility/maintenance-list'

export const MAP_MANAGEMENT = '/map-management'

export const CASE_DETAIL = '/case'

export const ALARM_REPORT = 'report/alarm-report'

export const CASE_FIELD_CPMPARATIONS_REPORT = 'report/case-field-comparations'

export const CASE_FIELD_MANAGEMENT = '/case-field-management'

export const MAP_POSITIONING = '/system/map-positioning'

export const CHILLER_MANAGEMENT = '/device/chiller-management'

export const AMMETER_MANAGEMENT = '/device/ammeter-management'

export const AIR_CONDITIONER_MANAGEMENT = '/device/airConditioner-management'

export const LAYOUT_SETTING = '/system/layout-setting'
