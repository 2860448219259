import { SVGProps } from 'react'
import cx from 'classnames'

const GreenTreeSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    className={cx('icon-greentree', className)}
    {...props}>
    <g
      strokeWidth="1.3"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path
        stroke="#FFCC2B"
        d="M20.667 12.444V30M21.494 24.703h3.437c2.8 0 5.069-2.64 5.069-5.894 0-3.256-2.27-5.895-5.07-5.895l-.032.002c-.28-2.947-2.621-5.213-5.231-5.213s-4.562 2.266-4.842 5.213M18.23 17.422l2.437 2.49M23.104 17.422l-2.437 2.49"/>
      <path
        d="M9 23.644c-3.866 0-7-2.911-7-6.504C2 13.548 9 3.644 9 3.644s7 9.904 7 13.496c0 2.913-2.06 5.378-4.9 6.207l-1.79.297" 
        stroke="#FFCC2B"
        fill="#FFCC2B"/>
      <path
        stroke="#FFCC2B"
        d="M8.092 11.822V30"/>
      <path
        stroke="#000"
        d="m5.046 17.422 3.046 3.111M10.529 14.311l-2.437 2.49"/>
      <path
        stroke="#FFCC2B"
        d="M19.667 24.702h-5.483"/>
      <path
        stroke="#000"
        d="M8.092 11.822v10.05"/>
    </g>
  </svg>
)

export default GreenTreeSVG
