import React from 'react'
import { PopupProps } from 'react-leaflet'
import { ContextWindow } from '../../components/ContextWindow'
import Popup from './Popup'

type Props = {
  item: any
  onClose: () => void
} & PopupProps

function MapLightBrightnessPopup({ item, onClose, ...props }: Props) {
  return (
    <Popup {...props}>
      <ContextWindow
        data={item}
        // preference={userColumns[item?.deviceType]}
        onCancel={onClose} />
    </Popup>
  )
}

export default React.memo(MapLightBrightnessPopup)
