import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { isEmpty } from 'ramda'
import { Form } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { RangePicker } from '@components/DatePicker'
import Button from '@components/Button'
import Select from '@components/Select'
import { DeviceType } from '@/constants'
import { getRangeDate, downloadFile } from '@/utils/webHelper'
import { rules } from '@/utils/form'
import { Asterisk } from '@components/Form'
import Layout from '@components/Layout'
import {
  TYPE,
  searchYearSelectItems,
  searchMonthSelectItems,
  ReportSearchType,
  getLastMonthValue,
} from '../utils'
import ReportChart from './ReportChart'
import ReportList from './ReportList'
import LabelItem from 'src/components/LabelItem'
import PowerPlantSelect from 'src/components/AllCasesSelect'

// prettier-ignore
enum TabItems { REPORT = 0, CHART = 1 }

function AnalyzeComparations() {
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [isError, setIsError] = React.useState<boolean>()
  const [currentTab, setCurrentTab] = React.useState(TabItems.REPORT)
  const [reportType, setReportType] = React.useState<number>(TYPE.MONTH)
  const [reportYear] = React.useState(moment().year())
  const [query, setQuery] = React.useState<Record<string, any>>({})
  const [initialVariables, seInitialVariables] = React.useState<any>({})

  // const [exporting, handleExport] = useExportAlarmRpt({
  //   variables: queryVariables,
  //   onSuccess: (blob: any) => {
  //     downloadFile({
  //       blob,
  //       filename: 'report',
  //     })
  //   },
  // })

  const handleExportReport = () => {}

  const handleTabChange = (target: number) => () => {
    setCurrentTab(target)
    setIsError(false)

    // const deviceType = target === TabItems.CHART ? DeviceType.LIGHT : ''
    // const reportType = TYPE.MONTH

    // setReportType(reportType)
    // form.resetFields()
    // form.setFieldsValue({ deviceType, reportType })
    // setQuery({})
  }

  // React.useEffect(() => {
  //   if (currentTab === TabItems.REPORT) {
  //     const data = getQueryVariables()

  //     const params = { current: 0, size: 10, ...data }
  //     setQuery(params)
  //     seInitialVariables(params)
  //   }
  // }, []) // eslint-disable-line

  //定義查詢參數
  const getQueryVariables = React.useCallback(() => {
    let values: any = {}

    const data = form.getFieldsValue()
    switch (reportType) {
      case TYPE.YEAR:
        values = { conditionDate: data.year }
        break
      case TYPE.MONTH:
        const currentYear = moment().year()
        values = { conditionDate: `${currentYear}/${data.month}` }
        break
      case TYPE.DAY:
        const [start, end] = data.date ? getRangeDate(data.date, 'x') : []

        values = { start, end }
        break
      default:
        break
    }

    return {
      ...data,
      ...values,
    }
  }, [reportType, form])

  const handleFieldsChange = (changedFields: any) => {
    const type = form.getFieldValue('reportType')

    if (changedFields && changedFields[0]?.name[0] === 'reportType') {
      setReportType(type)
    }

    if (currentTab === TabItems.CHART) {
      const err = form.getFieldError('deviceType')
      const isTypeErr = !isEmpty(err)
      if (isTypeErr) {
        setIsError(isTypeErr)
        return
      }
    }

    if (type === TYPE.DAY) {
      const err = form.getFieldError('date')
      const isDateErr = !isEmpty(err)

      if (isDateErr) {
        setIsError(isDateErr)
        return
      }
    }

    setIsError(false)
  }

  const handleDisabledDate = (current: any) => {
    return current >= moment().endOf('day')
  }

  const handleSearch = React.useCallback(
    (v: any) => {
      form.validateFields().then((v: any) => {
        setQuery({
          ...v,
          ...(reportType === TYPE.DAY
            ? {
                start: v.date[0].startOf('day').valueOf(),
                end: v.date[1].endOf('day').valueOf(),
              }
            : {}),
          conditionDate:
            reportType === TYPE.YEAR ? v.year : `${reportYear}/${v.month}`,
        })
      })
    },
    [form, reportType, reportYear]
  )

  return (
    <Layout>
      <Form
        form={form}
        layout="inline"
        style={{ justifyContent: 'space-between' }}
        onFieldsChange={handleFieldsChange}>
        <div className="flex items-start flex-wrap">
          <LabelItem label="分店">
            <Form.Item name="powerPlant" rules={[rules.required]}>
              <PowerPlantSelect className="w-240" allowClear />
            </Form.Item>
          </LabelItem>
          <LabelItem label="比較項目">
            <Form.Item name="type" rules={[rules.required]}>
              <Select
                mode="multiple"
                className="w-200"
                allowClear
                maxSelectedCount={2}
                placeholder="選擇比較項目">
                <Select.Option value={0}>發電量</Select.Option>
                <Select.Option value={1}>碳排量</Select.Option>
                <Select.Option value={2}>用電金額</Select.Option>
              </Select>
            </Form.Item>
          </LabelItem>
          <LabelItem label={t('report:statistics time')}>
            <div className="flex">
              <Form.Item name="reportType" initialValue={reportType}>
                <Select className="w-144">
                  {Object.values(TYPE).map(i => (
                    <Select.Option key={i} value={i}>
                      {ReportSearchType[i]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {reportType === TYPE.YEAR ? (
                <Form.Item name="year" initialValue={moment().year()}>
                  <Select placeholder={t('report:year')} className="w-144">
                    {searchYearSelectItems.map(i => (
                      <Select.Option key={i.value} value={i.value}>
                        {t('report:year', {
                          num: i.label,
                        })}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
              {reportType === TYPE.MONTH ? (
                <Form.Item name="month" initialValue={getLastMonthValue()}>
                  <Select placeholder={t('report:month')} className="w-144">
                    {searchMonthSelectItems.map(i => (
                      <Select.Option key={i.value} value={i.value}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
              {reportType === TYPE.DAY ? (
                <>
                  <Asterisk />
                  <Form.Item name="date" rules={[rules.required]}>
                    <RangePicker
                      bordered={false}
                      placeholder={[t('report:startDate'), t('report:endDate')]}
                      format="YYYY.MM.DD"
                      className="ml-4 w-250"
                      disabledDate={handleDisabledDate}
                    />
                  </Form.Item>
                </>
              ) : null}
            </div>
          </LabelItem>
          <Button
            color="primary"
            style={{ minWidth: 96, marginTop: '18px' }}
            // onClick={handleSearch}
            >
            {currentTab === TabItems.REPORT
              ? t('report:search report')
              : t('report:search chart')}
          </Button>
        </div>
        {/* TODO: 隱藏尚未實作的匯出功能 */}
        {/* <div className="flex items-start mt-30">
          <Button size="small" color="ghost" icon="export">
            {t('report:export')}
          </Button>
        </div> */}
      </Form>
      <div className="mt-42">
        <TabPane>
          <div
            onClick={handleTabChange(TabItems.CHART)}
            className={cx('tab__item', {
              'is--active': currentTab === TabItems.CHART,
            })}>
            {t('report:chart mode')}
          </div>
          <div
            onClick={handleTabChange(TabItems.REPORT)}
            className={cx('tab__item', {
              'is--active': currentTab === TabItems.REPORT,
            })}>
            {t('report:report mode')}
          </div>
        </TabPane>
      </div>
      {currentTab === TabItems.REPORT && (
        <ReportList
          exporting={false}
          onExportReport={handleExportReport}
          query={query}
        />
      )}
      {currentTab === TabItems.CHART && (
        <ReportChart
          exporting={false}
          onExportReport={handleExportReport}
          query={query}
        />
      )}
    </Layout>
  )
}

const TabPane = styled.div`
  display: inline-flex;
  border: 1px solid ${p => p.theme.primary};
  border-radius: 4px;
  .tab__item {
    min-width: 128px;
    padding: 4px 0px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: ${p => p.theme.btn.bg};
    &.is--active,
    &:hover {
      color: #fff;
      background-color: ${p => p.theme.btn.bg};
    }
  }
`

export default React.memo(AnalyzeComparations)
