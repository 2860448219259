import { SVGProps } from 'react'

const WindSVG = (props: SVGProps<any>) => (
  <svg
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <defs>
      <path id="a" d="M0 0h54.726v23.199H0z" />
      <path id="c" d="M0 0h83.684v23.2H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h96v96H0z" />
      <path
        d="M47.489 36.674H8.527a2.634 2.634 0 0 1 0-5.269h38.962c4.333 0 7.863-3.524 7.863-7.857a4.806 4.806 0 0 0-4.804-4.799h-.925a4.806 4.806 0 0 0-4.804 4.799 2.63 2.63 0 0 1-2.632 2.632 2.634 2.634 0 0 1-2.637-2.632c0-5.554 4.52-10.073 10.073-10.073h.925c5.553 0 10.073 4.52 10.073 10.073 0 7.239-5.893 13.126-13.132 13.126"
        fill="#FFD63C"
      />
      <g transform="translate(5.895 59.176)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M44.653 23.2h-.925c-5.559 0-10.073-4.515-10.073-10.068a2.634 2.634 0 0 1 2.637-2.632 2.63 2.63 0 0 1 2.632 2.632 4.806 4.806 0 0 0 4.804 4.798h.925a4.801 4.801 0 0 0 4.798-4.798c0-4.334-3.523-7.857-7.857-7.857H2.632A2.635 2.635 0 0 1 0 2.632 2.633 2.633 0 0 1 2.632 0h38.962c7.24 0 13.132 5.893 13.132 13.132 0 5.553-4.52 10.067-10.073 10.067"
          fill="#FFD63C"
          mask="url(#b)"
        />
      </g>
      <g transform="translate(5.897 27.362)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          d="M70.56 23.2H2.632a2.634 2.634 0 0 1 0-5.269H70.56c4.334 0 7.857-3.525 7.857-7.858a4.806 4.806 0 0 0-4.798-4.804h-.93a4.802 4.802 0 0 0-4.799 4.804 2.634 2.634 0 0 1-2.637 2.632 2.634 2.634 0 0 1-2.637-2.632C62.616 4.514 67.136 0 72.689 0h.93c5.554 0 10.068 4.514 10.068 10.073 0 7.239-5.888 13.126-13.127 13.126"
          fill="#FFF"
          mask="url(#d)"
        />
      </g>
    </g>
  </svg>
)

export default WindSVG
