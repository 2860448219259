import React, { CSSProperties } from 'react'
import styled from 'styled-components'

type Props = {
  data: any
  style?: CSSProperties
}

export default function Badge({ data, ...props }: Props) {
  const status = React.useMemo(
    () => ({
      isAlarm: data.isAlarm,
    }),
    [data] // eslint-disable-line
  )

  return <StyleBadge className="w-10 h-10 mr-4" {...status} {...props} />
}

export const StyleBadge = styled.div`
  border-radius: 10px;
  background-color: ${(props: any) => (props.isAlarm ? '#e17567' : '#6dd400')};
`
