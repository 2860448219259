import { SVGProps } from 'react'
import cx from 'classnames'

const GreenReductionSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    className={cx('icon-greenreduction', className)}
    {...props}>
    <g
      fill="none"
      fillRule="evenodd">
      <path
        d="M0 0h32v32H0z"/>
      <path
        d="M9.043 9.582H3.768c-.608 0-1.101.5-1.101 1.116V21.86c0 .617.493 1.116 1.101 1.116h4.558M17.783 22.976h6.362c.608 0 1.101-.5 1.101-1.116V10.698c0-.617-.493-1.116-1.101-1.116H18.68"
        stroke="#FFCC2B"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M25.246 12.93H26.9c.608 0 1.101.5 1.101 1.117v4.465c0 .616-.493 1.116-1.101 1.116h-1.653"
        stroke="#FFCC2B"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        fill="#FFCC2B"
        d="m14.591 14.189 1.577-8.856L7.48 18.575l5.292-.764-1.577 8.856 8.689-13.242z"/>
    </g>
  </svg>
)

export default GreenReductionSVG
