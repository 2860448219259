import { flatten, range } from 'ramda'

export const FakeComparisonYearReport = {
  data: range(1, 13).map(m => ({
    dateStr: `2021/${(m + '').padStart(2, '0')}`,
    inverters: [
      {
        name: 'inverter01',
        inverterSeq: 1,
        value: Math.floor(Math.random() * 2000) + 500,
      },
      {
        name: 'inverter02',
        inverterSeq: 2,
        value: Math.floor(Math.random() * 3000) + 200,
      },
      {
        name: 'inverter03',
        inverterSeq: 3,
        value: Math.floor(Math.random() * 1500) + 100,
      },
    ],
  })),
}

export const FakeComparisonMonthReport = {
  data: range(1, 32).map(m => ({
    dateStr: `2021/01/${(m + '').padStart(2, '0')}`,
    inverters: [
      {
        name: 'inverter01',
        inverterSeq: 1,
        value: Math.floor(Math.random() * 2000) + 500,
      },
      {
        name: 'inverter02',
        inverterSeq: 2,
        value: Math.floor(Math.random() * 3000) + 200,
      },
      {
        name: 'inverter03',
        inverterSeq: 3,
        value: Math.floor(Math.random() * 1500) + 100,
      },
    ],
  })),
}

// export const FakeComparisonMonthReport = {
//   data: [
//     {
//       device: {
//         deviceNo: '0001',
//         generation: 1,
//         id: 1,
//         name: '崙東九',
//         status: 1,
//         type: '1',
//         workStatus: 0,
//       },
//       report: range(1, 32).map(m => ({
//         item: `2021/01/${(m + '').padStart(2, '0')}`,
//         value: Math.floor(Math.random() * 2000) + 500,
//       })),
//     },
//     {
//       device: {
//         deviceNo: '0002',
//         generation: 1,
//         id: 2,
//         name: '貝爾威勒',
//         status: 1,
//         type: '1',
//         workStatus: 0,
//       },
//       report: range(1, 32).map(m => ({
//         item: `2021/01/${(m + '').padStart(2, '0')}`,
//         value: Math.floor(Math.random() * 3000) + 200,
//       })),
//     },
//     {
//       device: {
//         deviceNo: '0003',
//         generation: 1,
//         id: 3,
//         name: '金元福冬山廠',
//         status: 1,
//         type: '1',
//         workStatus: 0,
//       },
//       report: range(1, 32).map(m => ({
//         item: `2021/01/${(m + '').padStart(2, '0')}`,
//         value: Math.floor(Math.random() * 1500) + 200,
//       })),
//     },
//   ],
// }
