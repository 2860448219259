import { SVGProps } from 'react'
import cx from 'classnames'
import theme from '@assets/theme'

const LockOFFSVG = ({
  className,
  color = theme.icon.primary,
  ...props
}: SVGProps<any>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('svg-icon icon-lock', className)}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.489 2.083C7.08 1.797 7.584 1.654 8 1.654c1.767 0 3.2 1.31 3.2 2.925v3.075"
        stroke="#E4E4E4"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 14.749h8a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2z"
        fill="#E4E4E4"
      />
    </g>
  </svg>
)

export default LockOFFSVG
