import React from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { pathOr } from 'ramda'
import { QueryVariables, useLazyQuery, convertData } from '@/services'
import { momentToString, toMoment } from '@/utils/webHelper'
import { useAllSessions } from '@/session'

export const useAlarmRpt = (variables?: QueryVariables) => {
  const { data, refetch, ...others } = useLazyQuery(
    ['/report/alarmReport', { deep: true, ...variables }],
    {
      ...variables,
    }
  )

  const { byId } = useAllSessions()

  const { t } = useTranslation()

  const dataSource = convertData<any>({
    converter: x => ({
      ...x,
      createAt: toMoment(x.createAt),
      createAtStr: momentToString(x.createAt, 'YYYY.MM.DD HH:mm:ss'),
      sessionName: pathOr(
        t('report:no area assigned'),
        [x.sessionId, 'name'],
        byId
      ),
    }),
  })(data)

  return {
    ...others,
    refetch,
    loading: others.isLoading,
    queryAlarmList: refetch,
    dataSource,
  }
}

export const useExportAlarmRpt = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useQuery(
    ['/report/alarmReport/export', { deep: true, ...variables }],
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}
