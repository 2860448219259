import { SVGProps } from 'react'
import cx from 'classnames'

const GreenMoneySVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    className={cx('icon-greenmoney', className)}
    {...props}>
    <g
      strokeWidth="1.3"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path
        d="M20.783 4c-4.538 0-8.218 1.32-8.218 2.949 0 1.628 3.68 2.948 8.218 2.948S29 8.577 29 6.95C29 5.32 25.32 4 20.783 4z"
        stroke="#FFCC2B"/>
      <path
        d="M29 6.949v2.948c0 1.629-3.68 2.95-8.217 2.95-.914 0-1.793-.054-2.615-.153"
        stroke="#FFCC2B"/>
      <path
        d="M28.315 11.077c.44.361.685.761.685 1.18v.59c0 1.629-3.679 2.95-8.217 2.95-.374 0-.741-.01-1.101-.027"
        stroke="#FFCC2B"/>
      <path
        d="M28.315 14.026c.44.36.685.76.685 1.18v.59c0 1.628-3.679 2.949-8.217 2.949-.214 0-.426-.003-.636-.009"
        stroke="#FFCC2B"/>
      <path
        d="M28.315 16.974c.44.362.685.761.685 1.181v.59c0 1.628-3.679 2.949-8.217 2.949-.34 0-.675-.008-1.004-.022"
        stroke="#FFCC2B"/>
      <path
        d="M28.315 19.923c.44.361.685.761.685 1.18v.59c0 1.629-3.679 2.95-8.217 2.95-.874 0-1.716-.05-2.505-.14"
        stroke="#FFCC2B"/>
      <path
        d="M18.435 18.744c0 4.56-3.68 8.256-8.218 8.256S2 23.303 2 18.744c0-4.56 3.68-8.257 8.217-8.257 4.538 0 8.218 3.697 8.218 8.257z"
        stroke="#FFCC2B"
        fill="#FFCC2B"/>
      <path
        d="M8.457 20.513v.59c0 .65.525 1.18 1.173 1.18h1.174c.648 0 1.174-.53 1.174-1.18v-.92a1.18 1.18 0 0 0-.802-1.118l-1.917-.642a1.18 1.18 0 0 1-.802-1.119v-.33c0-.65.525-1.18 1.173-1.18h1.174c.648 0 1.174.53 1.174 1.18v.59M10.217 15.795v-1.769M10.217 22.282v1.769"
        stroke="#000"/>
      <path
        stroke="#FFCC2B"
        d="M12.565 6.949v1.769"/>
    </g>
  </svg>
)

export default GreenMoneySVG
