import { SVGProps } from 'react'
import cx from 'classnames'

const Authority = ({ className }: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('menu-icon-authority', className)}>
    <path
      d="M19.555 3C20.352 3 21 3.649 21 4.446v6.83a.722.722 0 1 1-1.445 0v-6.83H4.445v15.108h5.558a.722.722 0 1 1 0 1.446H4.445A1.447 1.447 0 0 1 3 19.554V4.446C3 3.65 3.648 3 4.445 3zm-2.428 9.376a2.653 2.653 0 0 1 1.463 4.861 3.908 3.908 0 0 1 2.376 2.895.725.725 0 0 1-.708.858.723.723 0 0 1-.709-.589 2.463 2.463 0 0 0-4.84 0 .723.723 0 1 1-1.42-.269 3.911 3.911 0 0 1 2.375-2.895 2.653 2.653 0 0 1 1.463-4.862zm0 1.446a1.206 1.206 0 0 0 0 2.41 1.206 1.206 0 0 0 0-2.41zm-7.124.75a.722.722 0 1 1 0 1.446H6.311a.722.722 0 1 1 0-1.447zm0-3.858a.722.722 0 1 1 0 1.447H6.311a.722.722 0 1 1 0-1.447zm2.569-3.857a.722.722 0 1 1 0 1.447H6.31a.722.722 0 1 1 0-1.447z"
      fill="#878787"
      fillRule="nonzero"
    />
  </svg>
)

export default Authority
