import React from 'react'
import cx from 'classnames'
import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'
import styled from 'styled-components'
import { RefProps } from '@lib/types'
import { CalendarOutlined } from '@ant-design/icons'

type Props = DatePickerProps & RefProps

const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    border-radius: 4px;
    border: 1px solid ${p => p.theme.input.border} !important;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 10%);
    background-color: transparent;

    .ant-picker-input > input {
      color: #fff;
    }
  }
`

const DatePickerComponent = React.forwardRef<typeof DatePicker, Props>(
  ({ className = '', ...props }, ref) => (
    <StyledDatePicker
      // forwardRef={ref}
      suffixIcon={<CalendarOutlined style={{ color: '#fff' }} />}
      className={cx('c-datepicker', className)}
      placeholder=""
      {...props}
    />
  )
)

DatePickerComponent.displayName = 'DatePicker'

export default DatePickerComponent
