import React from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { forEach, pathOr } from 'ramda'
import { Select, SelectProps } from '@components/Select'
const { compose } = require('ramda')

export type LightEnum = {
  key: string
  name: string
  type: number
  data: {
    value: string
    desc: string
  }[]
}

export const useLightEnums = () => {
  const { data, ...others } = useQuery('/options/light/static', {
    cacheTime: Infinity,
  })

  const { t } = useTranslation()

  const _data = React.useMemo(() => {
    let activeStateEnumByKey: { [key: string]: string } = {}
    let lampTypeEnumByKey: { [key: string]: string } = {}
    let switchLeakageEnumByKey: { [key: string]: string } = {}
    let controllerTypeEnumByKey: { [key: string]: string } = {}
    let deviceTypeEnumByKey: { [key: string]: string } = {}

    if (data) {
      let lightEnum: { [key: string]: LightEnum } = {}

      const forEachKey = (path: string) => (fn: any) =>
        compose(forEach(fn), pathOr([], [path, 'data']))(lightEnum)

      compose(
        forEach((x: LightEnum) => (lightEnum[x.key] = x)),
        pathOr([], ['data'])
      )(data)
      // prettier-ignore
      forEachKey('ACTIVE_STATE')((x: any) => (activeStateEnumByKey[x.value] = x.desc))
      // prettier-ignore
      forEachKey('LAMP_TYPE')((x: any) => (lampTypeEnumByKey[x.value] = x.desc))
      // prettier-ignore
      forEachKey('SWITCH_LAEKAGE')((x: any) => (switchLeakageEnumByKey[x.value] = x.desc))
      // prettier-ignore
      forEachKey('CONTROLLER_TYPE')((x: any) => (controllerTypeEnumByKey[x.value] = x.desc))

      //新增刪除狀態
      activeStateEnumByKey['-1'] = '刪除'
    }

    deviceTypeEnumByKey['visualdoorbell'] = t('device:talkie')
    deviceTypeEnumByKey['light'] = t('device:light')
    deviceTypeEnumByKey['camera'] = t('device:camera')
    deviceTypeEnumByKey['switchbox'] = t('device:switchbox')
    deviceTypeEnumByKey['wdetect'] = t('device:wdetect')
    deviceTypeEnumByKey['gateway'] = t('device:gateway')
    deviceTypeEnumByKey['inverter'] = t('device:inverter')
    deviceTypeEnumByKey['airConditioner'] = t('device:airConditioner')
    deviceTypeEnumByKey['chiller'] = t('device:chiller')
    deviceTypeEnumByKey['ammeter'] = t('device:ammeter')

    return {
      activeStateEnumByKey,
      lampTypeEnumByKey,
      switchLeakageEnumByKey,
      controllerTypeEnumByKey,
      deviceTypeEnumByKey,
      getDeviceStatus: (data: any) => {
        if (!data) {
          return ''
        }

        return data.isAlarm //ps: 有些設備只有 isAlarm 狀態
          ? t('light:alarm')
          : data.isRepair
          ? t('light:repair')
          : data.isDeviceFail
          ? t('light:broken')
          : t('light:normal')
      },
    }
  }, [JSON.stringify(data)]) // eslint-disable-line

  return {
    ...others,
    ..._data,
  }
}

export const useMakeSelect = ({ key, ...props }: any) => {
  const enumKeys = useLightEnums()

  const data: any = pathOr({}, [key], enumKeys)

  return (
    <Select allowClear {...props}>
      {Object.keys(data)
        .filter(i => i !== '-1')
        .map((x: string) => (
          <Select.Option key={x} value={isNaN(Number(x)) ? x : Number(x)}>
            {data[x]}
          </Select.Option>
        ))}
    </Select>
  )
}

export const ActiveStateSelect = ({ useForm, ...props }: SelectProps) => {
  return useMakeSelect({ key: 'activeStateEnumByKey', ...props })
}

export const SwitchLeakageSelect = ({ useForm, ...props }: SelectProps) => {
  return useMakeSelect({ key: 'switchLeakageEnumByKey', ...props })
}

export const ControllerTypeSelect = ({ useForm, ...props }: SelectProps) => {
  return useMakeSelect({ key: 'controllerTypeEnumByKey', ...props })
}

export const LampTypeSelect = ({ useForm, ...props }: SelectProps) => {
  return useMakeSelect({ key: 'lampTypeEnumByKey', ...props })
}
