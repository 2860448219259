import { useQuery, useMutation } from 'react-query'
import { pathOr } from 'ramda'
import { UserColumns } from './auth.types'
import { request, MutationOptions, QueryVariables, Mutation } from '@/services'

export const updateUserColumnSettingAPI = (body: any) => {
  return request('/memberInfo/columnMap', { method: 'POST', body })
}

export const useUserColumns = ({
  variables,
  ...options
}: QueryVariables = {}) => {
  const { data, isLoading, ...others } = useQuery(
    ['/memberInfo/columnMap', variables],
    {
      ...options,
      cacheTime: 0,
    }
  )

  const personalColumn: UserColumns = pathOr(
    {} as UserColumns,
    ['data'],
    data
  )

  return {
    data: personalColumn,
    personalColumn,
    userColumns: personalColumn,
    checking: isLoading,
    loading: isLoading,
    ...others,
  }
}

export function useUpdateUserColumn(options: MutationOptions) {
  const { mutate, isLoading } = useMutation(updateUserColumnSettingAPI, options)

  return [isLoading, mutate] as Mutation
}
