import styled from 'styled-components'
import { Input } from 'antd'

const TextArea = styled(Input.TextArea)`
  resize: none;
  border: 1px solid ${p => p.theme.input.border};

  &.ant-input:hover,
  &.ant-input:focus,
  &.ant-input:active {
    border-color: ${p => p.theme.input.border};
    box-shadow: none;
  }

  &.ant-input {
    background: ${p => p.theme.input.bg};
    color: ${p => p.theme.input.color};
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: ${p => p.theme.input.bg};
    border-color: ${p => p.theme.danger};
  }
`

export default TextArea
