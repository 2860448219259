import { pathOr } from 'ramda'
import history from '@/routes/history'
import { useAuth, UserPermission, ActionTypes } from '@/auth'

export default function useRouteAuth() {
  const { user } = useAuth()

  const routeAuth = pathOr(
    {},
    ['authByUrl', history.location.pathname],
    user
  ) as UserPermission
  //TODO 開發時, 權限先設 true
  return [routeAuth, (actionType: ActionTypes) => true] as [
    UserPermission,
    (act: string) => boolean
  ]

  // return [routeAuth, (act: ActionTypes) => routeAuth[act]] as [
  //   UserPermission,
  //   (act: string) => boolean
  // ]
}
