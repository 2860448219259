import _GoogleMap from './map/GoogleMap'

import _LeafletMap from './map/LeafletMap'

export * from './components/Marker'

export const GoogleMap = _GoogleMap

export const LeafletMap = _LeafletMap

export * from './map/SmartMap'

export default _GoogleMap
