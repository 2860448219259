import { SVGProps } from 'react'

const InputDownSVG = (props: SVGProps<any>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    style={{ backgroundColor: '#000' }}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#000" d="M0 0h12v12H0z" />
      <path d="M12 12V0H0v12z" />
      <path
        d="M10.858 3.11c.193.173.208.469.035.661L6.425 8.736a.468.468 0 0 1-.402.252h-.024l-.025-.002a.46.46 0 0 1-.402-.25L1.104 3.771a.469.469 0 0 1 .696-.627l4.198 4.662 4.198-4.662a.469.469 0 0 1 .662-.035z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default InputDownSVG
