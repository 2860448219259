import InternalLeafletMap, { MapProps } from './LeaftletMap'
import Marker, { MapMarkerProps } from './Marker'
import Tooltip from './Tooltip'
import { LeafletMapProvider, useLeafletMap } from './LeafletMapProvider'
import { MarkerImageType, MarkerIconKey } from '../../components/Marker'

type InternalLeafletMapType = typeof InternalLeafletMap

interface LeafletMapInterface extends InternalLeafletMapType {
  Provider: typeof LeafletMapProvider
  Marker: typeof Marker
  useLeafletMap: typeof useLeafletMap
  Tooltip: typeof Tooltip
}

const LeafletMap = InternalLeafletMap as LeafletMapInterface

LeafletMap.Provider = LeafletMapProvider
LeafletMap.Marker = Marker
LeafletMap.Tooltip = Tooltip
LeafletMap.useLeafletMap = useLeafletMap

export type { MapProps, MapMarkerProps, MarkerImageType, MarkerIconKey }

export default LeafletMap
