import { SVGProps } from 'react'
import theme from '@assets/theme'

const MapSVG = ({
  color = theme.treeview.mapIcon,
  ...props
}: SVGProps<any>) => (
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <defs>
      <path
        d="M15.333 1.94v9.696a.605.605 0 0 1-.308.526l-4.277 2.424-.01.006a.61.61 0 0 1-.057.027l.067-.033a.614.614 0 0 1-.304.08l.062-.003a.617.617 0 0 1-.335-.061l-4.596-2.28-3.994 2.264a.611.611 0 0 1-.914-.526V4.364c0-.217.117-.418.308-.526l4.277-2.424.012-.007a.61.61 0 0 1 .058-.027l-.07.034a.614.614 0 0 1 .304-.08l-.036.001h.036a.623.623 0 0 1 .273.063l4.596 2.28 3.994-2.264c.407-.23.914.06.914.526zm-9.166.98v8.341l3.666 1.818V4.737L6.167 2.92zm-1.223.064L1.889 4.716v8.3l3.055-1.732v-8.3zm9.167 0-3.056 1.731v8.3l3.056-1.73v-8.3z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <use fill={color} fillRule="nonzero" xlinkHref="#a" />
    </g>
  </svg>
)

export default MapSVG
