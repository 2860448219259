import '@assets/index.css'
import '@assets/locales/i18n'
import './bootstrap'

import React from 'react'
import { Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { QueryClientProvider } from 'react-query'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import zhTW from 'antd/lib/locale/zh_TW'
import { mergeDeepRight } from 'ramda'
import { ThemeProvider } from 'styled-components'
import { GoogleMap, LeafletMap } from '@components/innmaxmap'
import defaultTheme from '@assets/theme'
import { AuthContext, AuthProvider } from '@/auth'
import { createQueryClient } from '@/services'
import { history } from '@/routes'
import createPack from '@/utils/renderer/createPack'
import i18n from 'i18next'

type Props = {
  theme?: { [key: string]: any }
}

const EnhancedApp = ({ theme = {}, children }: any) => {
  const [locale, setLocale] = React.useState(zhTW)

  const Provider = createPack(
    <ThemeProvider theme={mergeDeepRight(defaultTheme, theme)} />,
    <ConfigProvider locale={locale} />,
    <RecoilRoot />,
    <GoogleMap.Provider />,
    <LeafletMap.Provider />
  )

  React.useEffect(() => {
    i18n.on('languageChanged', function () {
      setLocale(i18n.language === 'zhTW' ? zhTW : enUS)
    })
  }, [])

  return (
    <Provider>
      <AuthProvider>
        <AuthContext.Consumer>
          {auth => (
            <QueryClientProvider client={createQueryClient(auth)}>
              <Router history={history}>{children}</Router>
            </QueryClientProvider>
          )}
        </AuthContext.Consumer>
      </AuthProvider>
    </Provider>
  )
}

const renderer =
  (props: Props = {}) =>
  (App: React.FunctionComponent) => {
    return (
      <EnhancedApp {...props}>
        <App />
      </EnhancedApp>
    )
  }

export default renderer
