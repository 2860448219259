import { pathOr, map, mergeAll } from 'ramda'
import moment from 'moment'
import { convertData } from '@/services'
import type { Session } from '.'
const { compose } = require('ramda')

export const convertSessionData = (data: any) => {
  if (!data) {
    return {}
  }

  const dataSource = convertData<Session>({
    converter: x => {
      return {
        ...x,
        createdAt: moment(x.createdAt),
        createdAtStr: moment(x.createdAt).format('YYYY/MM/DD HH:mm:ss'),
      }
    },
  })(data)

  let _data = pathOr([], ['content'], dataSource)

  let byId: { [key: string]: Session } = compose(
    mergeAll,
    map((x: Session) => ({ [x.id]: x }))
  )(_data)

  return {
    byId,
    data: _data as Session[],
    dataSource,
  }
}
