import { SVGProps } from 'react'
import cx from 'classnames'
import theme from '@assets/theme'

const MinusCircleSVG = ({
  className,
  color = theme.icon.primary,
  ...props
}: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    className={cx('svg-icon icon-minus-circle', className)}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M8 0C3.581 0 0 3.581 0 8c0 4.419 3.581 8 8 8 4.419 0 8-3.581 8-8 0-4.419-3.581-8-8-8zm4.86 12.86c-.63.632-1.366 1.13-2.185 1.474-.847.36-1.747.541-2.675.541-.928 0-1.828-.181-2.675-.54-.819-.346-1.553-.841-2.186-1.473-.631-.63-1.128-1.367-1.473-2.185-.36-.849-.541-1.749-.541-2.677 0-.928.181-1.828.54-2.675.346-.819.841-1.553 1.473-2.186.63-.631 1.367-1.128 2.185-1.473.849-.36 1.749-.541 2.677-.541.928 0 1.828.181 2.675.54.819.346 1.553.841 2.186 1.473.631.63 1.128 1.367 1.473 2.185.36.849.541 1.749.541 2.677 0 .928-.181 1.828-.54 2.675-.346.819-.841 1.555-1.474 2.186zm-1.422-5.415H4.561c-.31 0-.562.252-.562.563 0 .31.252.562.563.562H11.437c.311 0 .563-.251.563-.562 0-.311-.252-.563-.563-.563z"
                  transform="translate(-944 -367) translate(240 110) translate(40 216) translate(0 29) translate(664 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default MinusCircleSVG
