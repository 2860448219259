import { SVGProps } from 'react'
import cx from 'classnames'

const PlaySVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={cx('svg-icon icon-play', className)}
    {...props}>
    <g fill="none" fillRule="evenodd" opacity=".5">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-1076 -281) translate(216 180) translate(0 24) translate(0 65) translate(860 12) translate(2 2)">
                  <g>
                    <path
                      d="M0 0H13.333V13.333H0z"
                      transform="translate(3.636 3.636)"
                    />
                    <g fill="#4A4A4A" fillRule="nonzero">
                      <path
                        d="M1.308.548l6.816 4.808c.263.201.263.555 0 .756l-6.816 4.83c-.382.29-.987.06-.987-.378V.924C.323.489.926.256 1.308.549z"
                        transform="translate(3.636 3.636) translate(3.03 .606)"
                      />
                    </g>
                  </g>
                  <circle
                    cx="10"
                    cy="10"
                    r="9.4"
                    stroke="#4A4A4A"
                    strokeWidth="1.2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default PlaySVG
