import { SVGProps } from 'react'
import cx from 'classnames'

const SuccessSVG = ({
  className,
  color = '#0074A8',
  ...props
}: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={cx('icon-success', className)}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M8 0C3.581 0 0 3.581 0 8c0 4.419 3.581 8 8 8 4.419 0 8-3.581 8-8 0-4.419-3.581-8-8-8z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M8 5.595c.747 0 1.353-.606 1.353-1.353 0-.748-.606-1.354-1.353-1.354s-1.353.606-1.353 1.354c0 .747.606 1.353 1.353 1.353zm0 7.915c.615 0 1.193-.334 1.267-.912l.008-.127V7.65c0-.656-.616-1.04-1.275-1.04-.615 0-1.193.335-1.267.912l-.008.128v4.82c0 .656.616 1.04 1.275 1.04z"
      />
    </g>
  </svg>
)

export default SuccessSVG
