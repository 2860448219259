import React from 'react'
import styled from 'styled-components'
import theme from '@assets/theme'
import { jsonSafeStringify } from '@lib/utils/webHelper'

type Props = {
  data: any
  onCancel: () => void
}

export function ContextWindow({
  data,
  onCancel
}: Props) {
  const [currentDevice] = React.useState<any>(data)

  return (
    <InfoBoxWrapper theme={theme}>
      <h3>ContextWindow</h3>
      {jsonSafeStringify(currentDevice)}
    </InfoBoxWrapper>
  )
}

const InfoBoxWrapper = styled.div`
  height: 280px;
  width: 195px;
  margin-left: 15px;
  padding: 7px;
  word-break: break-all;
  border: ${p => `1px solid ${theme.primary}`};
  background: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  // &::-webkit-scrollbar {
  //   width: 4px;
  //   background-color: transparent;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: #d8d8d8;
  // }
`
