import { useQuery } from 'react-query'
import { forEach, pathOr, compose } from 'ramda'
import Store from 'store'
import { useAuth, setUserAuth, MenuAuth, UserPermission } from '.'

export const useMineInfo = () => {
  const { setIsAuthenticated, setUser, user } = useAuth()

  const { refetch, isLoading } = useQuery(['/account/mineInfo'], {
    cacheTime: Infinity,
    refetchOnMount: false,
    enabled: false,
    onError: error => {
      console.log(error)
    },
    onSuccess: (response: any) => {
      if (!response) {
        return
      }

      const permissions = {
        allCameraPermission: pathOr(false, ['data', 'allCameraPermission'], response),
        allSessionPermission: pathOr(false, ['data', 'allSessionPermission'], response),
      }

      let authById: { [key: string]: UserPermission } = {}
      let authByUrl: { [key: string]: UserPermission } = {}

      compose(
        forEach((x: MenuAuth) => {
          authById[x.name] = setUserAuth(x, authById[x.name])
          authByUrl[x.webPath] = setUserAuth(x, authByUrl[x.webPath])
        }),
        pathOr([], ['data', 'roleObj', 'authDetails'])
      )(response)

      const funcList = Object.keys(authById).map(i => authById[i])

      Store.set('carrefour_user', {
        ...Store.get('carrefour_user'),
        authByUrl,
        funcList,
      })

      setIsAuthenticated(true)

      setUser({
        ...user,
        authById,
        authByUrl,
        funcList,
        permissions,
      })
    },
  })

  return { refetch, isLoading }
}
