import React from 'react'
import theme from '@assets/theme'

type Props = {
  style?: React.CSSProperties
  className?: string
  children?: React.ReactNode
}

const Label = ({ style, className, children }: Props) => (
  <span
    style={{
      fontSize: theme.form.fontSize,
      color: theme.form.label,
      ...style,
    }}
    className={className}>
    {children}
  </span>
)

export default Label
