/**
 * 	DAILYREPORT(1, "日報"),
	MONTHLYREPORT(3, "月報"),
	YEARLYREPORT(4, "年報");
 */
  import { isNil, sum, pathOr } from 'ramda'
  import i18n from 'i18next'
  import { formatDate } from '@/utils/webHelper'
  
  export const TYPE = { YEAR: 4, MONTH: 3, DAY: 1 }
  
  export const PAGE_SIZE = 10
  
  export let ReportSearchType = {
    [TYPE.YEAR]: i18n.t('report:yr report'),
    [TYPE.MONTH]: i18n.t('report:month report'),
    [TYPE.DAY]: i18n.t('report:days'),
  }
  
  export let searchMonthSelectItems = [
    { label: 1, value: '01', name: i18n.t('report:Jun') },
    { label: 2, value: '02', name: i18n.t('report:Feb') },
    { label: 3, value: '03', name: i18n.t('report:March') },
    { label: 4, value: '04', name: i18n.t('report:Apr') },
    { label: 5, value: '05', name: i18n.t('report:May') },
    { label: 6, value: '06', name: i18n.t('report:June') },
    { label: 7, value: '07', name: i18n.t('report:Jly') },
    { label: 8, value: '08', name: i18n.t('report:Aug') },
    { label: 9, value: '09', name: i18n.t('report:Sep') },
    { label: 10, value: '10', name: i18n.t('report:Oct') },
    { label: 11, value: '11', name: i18n.t('report:Nov') },
    { label: 12, value: '12', name: i18n.t('report:Dec') },
  ]
  
  export const searchYearSelectItems = ((startYear = 2019) => {
    const currentYear = new Date().getFullYear(),
      years = []
    startYear = startYear || 2019
    while (startYear <= currentYear) {
      years.push({
        label: startYear,
        value: startYear,
      })
      startYear++
    }
    return years
  })()
  
  // current month
  export const getLastMonthValue = () => {
    const month = searchMonthSelectItems.find(
      i => i.label === new Date().getMonth() + 1
    )
    return month.value
  }
  
  export const xAxisLabels = searchMonthSelectItems.map(i => i.name)
  
  export const xAxisName = v => {
    const month = searchMonthSelectItems.find(i => i.label === v + 1)
    return month.name
  }
  
  export const getAverageData = values => {
    const avgList = []
  
    Array.from({ length: values.length }, (_, i) => {
      const range = values.slice(0, i + 1)
      const len = range.filter(i => !isNil(i)).length
  
      const avg =
        len > 0 ? Number((sum(range.map(i => i.y)) / len).toFixed(2)) : null
  
      const data = { name: values[i].name, y: avg ? avg : 0 }
  
      return avgList.push(data)
    })
  
    return avgList
  }
  
  export const getTitle = values => {
    const { reportType, sessionName, year, month } = values
  
    const [start, end] =
      reportType === TYPE.DAY ? pathOr([], ['date'], values) : []
  
    let title,
      xLabel = ''
  
    switch (reportType) {
      case TYPE.YEAR:
        xLabel += `${i18n.t('report:year', { num: year })} `
        break
      case TYPE.MONTH:
        xLabel += `${searchMonthSelectItems.find(i => i.value === month).name} `
        break
      case TYPE.DAY:
        xLabel += `${start ? formatDate(start) : ''} - ${
          end ? formatDate(end) : ''
        } `
        break
      default:
    }
  
    if (sessionName) {
      title = xLabel + sessionName
    }
    if (!sessionName) {
      title = xLabel + i18n.t('common:all area')
    }
  
    return { title, xLabel }
  }
  
  i18n.on('languageChanged', function (lng) {
    ReportSearchType = {
      [TYPE.YEAR]: i18n.t('report:yr report'),
      [TYPE.MONTH]: i18n.t('report:month report'),
      [TYPE.DAY]: i18n.t('report:days'),
    }
  
    searchMonthSelectItems = [
      { label: 1, value: '01', name: i18n.t('report:Jun') },
      { label: 2, value: '02', name: i18n.t('report:Feb') },
      { label: 3, value: '03', name: i18n.t('report:March') },
      { label: 4, value: '04', name: i18n.t('report:Apr') },
      { label: 5, value: '05', name: i18n.t('report:May') },
      { label: 6, value: '06', name: i18n.t('report:June') },
      { label: 7, value: '07', name: i18n.t('report:Jly') },
      { label: 8, value: '08', name: i18n.t('report:Aug') },
      { label: 9, value: '09', name: i18n.t('report:Sep') },
      { label: 10, value: '10', name: i18n.t('report:Oct') },
      { label: 11, value: '11', name: i18n.t('report:Nov') },
      { label: 12, value: '12', name: i18n.t('report:Dec') },
    ]
  })
