import React from 'react'
import Popup from './Popup'
import { useUserColumns } from '@/auth'
import InfoWindow from '../../components/InfoWindow'
import { Device } from '@lib/device'
import { PopupProps } from 'react-leaflet'

type Props = {
  item: Device
  isMultipleVideo?: boolean
  onClose?: (data: any) => void
} & PopupProps

function PopupComponent({
  item,
  isMultipleVideo = false,
  onClose = () => {},
  ...props
}: Props) {
  const { userColumns } = useUserColumns()

  return (
    <Popup {...props} offset={[-20, -80]}>
      <InfoWindow
        data={item}
        preference={userColumns[item.deviceType]}
        onClose={onClose}
      />
    </Popup>
  )
}

export default React.memo(PopupComponent)
