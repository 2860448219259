import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import * as Icons from '@icons'
import type { ActionTypes } from '@/auth'
import useRouteAuth from '@/routes/useRouteAuth'

export interface Props extends Omit<ButtonProps, 'icon' | 'type'> {
  children?: React.ReactNode
  circle?: boolean
  color?:
    | 'primary'
    | 'ghost'
    | 'warning'
    | 'outline'
    | 'danger'
    | 'secondary'
    | 'info'
    | undefined
  icon?: string | React.ReactNode
  onClick?: (evt: React.MouseEvent<HTMLElement>) => any
  actionType?: ActionTypes
  rounded?: boolean
  theme?: object
  type?: 'default' | 'link' | 'icon' | 'text' | undefined
}

const StyledButton = styled(({ circle, rounded, ...props }) => (
  <Button {...props} />
))<Props>`
  &.ant-btn {
    min-width: 96px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;

    .ant-btn-loading-icon {
      display: inline-flex;
    }

    .anticon {
      margin-right: 6px;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &.ant-btn-sm svg {
      width: 16px;
      height: 16px;
    }

    &.is--rounded {
      border-radius: 16px;
    }

    ${p =>
      p.color === 'primary' &&
      css`
        color: ${p => p.theme.btn.text};
        background: ${p => p.theme.primary};
        border: 1px solid ${p => p.theme.primary};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.bgHover};
          background: ${p => p.theme.btn.bgHover};
        }
      `};
    // ghost 代替 outline 格式
    ${p =>
      p.color === 'ghost' &&
      css`
        color: ${p => p.theme.primary};
        background: transparent;
        border: 1px solid ${p => p.theme.btn.ghostBorder};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.primary};
          background: ${p => p.theme.btn.ghostHover};
        }
      `};

    ${p =>
      p.color === 'warning' &&
      css`
        color: #fff;
        background: ${p => p.theme.gamboge};
        border: 1px solid ${p => p.theme.gamboge};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.gamboge};
          background: ${p => p.theme.gamboge};
        }
      `};

    ${p =>
      p.color === 'outline' &&
      css`
        min-width: 104px;
        padding: 0 8px;
        color: ${p => p.theme.btn.bg};
        border: 1.3px solid ${p => p.theme.transparent};
        background: ${p => p.theme.transparent};
        box-shadow: 0 2px 0 ${p => p.theme.transparent};

        &:hover,
        &:focus,
        &:active {
          color: ${p => p.theme.btn.outlineHover};
          background: ${p => p.theme.transparent};
          border: 1.3px solid ${p => p.theme.transparent};
        }

        &.is--warning {
          color: ${p => p.theme.warning};
          border: 1.3px solid ${p => p.theme.transparent};

          &:hover,
          &:focus,
          &:active {
            color: ${p => p.theme.warning};
            background: #f1f1f1;
            border: 1.3px solid ${p => p.theme.transparent};
          }
        }
      `};

    ${p =>
      p.color === 'danger' &&
      css`
        color: ${p => p.theme.danger};
        border: 1.3px solid ${p => p.theme.danger};
        background: #fff;

        &:hover,
        &:focus,
        &:active {
          color: ${p => p.theme.danger};
          background: #fff6e4;
          border: 1.3px solid ${p => p.theme.danger};
        }
      `};

    ${p =>
      p.color === 'secondary' &&
      css`
        height: 24px;
        line-height: 1;
        padding-top: 3px;
        border: 1px solid ${p => p.theme.btn.bg};
        border-radius: 16px;
        color: #fff;
        background: ${p => p.theme.btn.bg};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.bgHover};
          background: ${p => p.theme.btn.bgHover};
        }

        svg {
          width: 16px;
          height: 16px;
        }
      `};

    ${p =>
      p.circle &&
      css`
        border-radius: 16px;
      `};

    ${p =>
      p.type === 'link' &&
      css`
        // min-width: unset !important;
        padding: 0 !important;
        background: transparent;
        color: ${p.theme.primary};
        font-size: unset;

        > span {
          :hover {
            color: ${p.theme.primary};
          }
        }

        &:hover {
          background: transparent;
          color: ${p.theme.primary};
        }
      `};

    ${p =>
      p.type === 'text' &&
      css`
        min-width: unset !important;
        padding: 0 !important;
        background: transparent;
        border-color: transparent;
        color: ${p.theme.btn.linkColor};
        height: auto !important;
        font-size: unset;

        &:hover,
        &:focus,
        &:active,
        &.active {
          color: ${p.theme.btn.linkHover};
          > span {
            color: ${p.theme.btn.linkHover};
          }
        }
      `};

    ${p =>
      p.type === 'icon' &&
      css`
        min-width: auto !important;
        padding: 0 !important;
        background: transparent !important;
        border: 0 !important;
        color: ${p => p.theme.light};
        height: auto !important;
        font-size: unset;
        vertical-align: middle;
        box-shadow: none;

        svg {
          width: unset;
          height: unset;
        }

        &:hover,
        &:focus,
        &:active {
          color: ${p => p.theme.primary} !important;
        }

        .anticon {
          margin-right: 0 !important;
        }
      `};

    ${p =>
      p.size === 'small' &&
      css`
        line-height: 1.2;
        font-size: 14px;
        font-weight: normal;
      `}

    ${p =>
      p.color === 'info' &&
      css`
        color: #fff;
        background: ${p => p.theme.btn.info};
        border: 1px solid ${p => p.theme.btn.info};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.infoHover};
          background: ${p => p.theme.btn.infoHover};
          color: ${p => p.theme.btn.text};
        }
      `};

    ${p =>
      p.rounded &&
      css`
        border-radius: 16px;
      `}

    &.underline {
      > span {
        text-decoration: underline;
      }
    }
  }

  &.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 20px;
  }

  &.ant-btn[disabled] {
    svg > g path:nth-child(2) {
      transition: fill 0.4s ease;
      fill: darkgrey;
    }
  }
`

export { StyledButton as Butotn }

export function ButtonComponent(
  { icon, children, loading, actionType, ...props }: Props,
  ref: React.Ref<any>
) {
  const [, checkIsAuthenticated] = useRouteAuth()

  if (actionType && !checkIsAuthenticated(actionType)) {
    return null
  }

  return (
    <StyledButton {...props} loading={loading}>
      {(() => {
        if (icon && !loading) {
          let Icon

          const isElement = React.isValidElement(icon)

          if (!isElement) {
            Icon = icon
          }

          if (typeof icon === 'string') {
            const nameCapitalized = icon.charAt(0).toUpperCase() + icon.slice(1)
            const iconName = 'Icon' + nameCapitalized
            Icon = (Icons as any)[iconName]
          }

          return (
            <div className="flex items-center">
              {isElement ? (
                icon
              ) : (
                <Icon className="c-icon mr-4 is--secondary" />
              )}
              {children}
            </div>
          )
        }

        return children
      })()}
    </StyledButton>
  )
}

export default React.forwardRef(ButtonComponent)
