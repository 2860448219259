import React from 'react'
import styled from 'styled-components'
import { Empty } from 'antd'
import { MapType } from '@lib/constants/map'
import { LeafletMap, GoogleMap } from '@components/innmaxmap'

export const SmartMap = (props: any) => {
  const options: any = React.useMemo(() => {
    const _props = { ...props }
    if (props.mapType === MapType.Upload) {
      delete _props.zoom
      delete _props.center
    }
    return _props
  }, [props]) //eslint-disable-line

  const EmptyContent = () => (
    <Flex>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="無地圖"
        style={{ color: '#4a4a4a' }}
      />
    </Flex>
  )

  return options.mapType ? (
    options.mapType === MapType.Upload ? (
      !options.hasMap ? <EmptyContent /> : <LeafletMap {...options} />
    ) : options.mapType === MapType.GoogleMap && options.defaultCenter && options.defaultCenter.lat && options.defaultCenter.lng ? (
      <GoogleMap {...options} />
    ) : <EmptyContent />
  ) : options.loading ? (
    options.renderLoading ? options.renderLoading : <div>loading</div>
  ) : <EmptyContent />
}

export const SmartMarker = (props: any) => {
  return props.mapType ? (
    props.mapType === MapType.Upload ? (
      <LeafletMap.Marker {...props} />
    ) : (
      <GoogleMap.Marker {...props} />
    )
  ) : <></>
}

const Flex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
`
