import { InputNumber } from 'antd'
import styled from 'styled-components'

const StyledInputNumber = styled(InputNumber).attrs({
  min: 0 as any, // dont know why error
})`
  &.ant-input-number {
    border-radius: 4px;
    background-color: transparent;
    color: ${p => p.theme.input.darkGrey};
    border: 1px solid ${p => p.theme.input.border} !important;
  }

  &.ant-input-number-focused,
  &.ant-input-number:focus,
  &.ant-input-number:hover {
    border-color: ${p => p.theme.input.border};
  }

  &.ant-input-number-focused,
  &.ant-input-number:focus {
    box-shadow: none;
  }
  .ant-input-number-input {
    background-color: ${p => p.theme.input.bg};
  }
`

export default StyledInputNumber
