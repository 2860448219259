import React from 'react'
import { Tooltip, TooltipProps } from 'react-leaflet'
import styled from 'styled-components'

function TooltipComponent({ ...props }: TooltipProps) {
  return <StyleTooltip {...props}>{props.children}</StyleTooltip>
}

const StyleTooltip = styled(Tooltip)`
  background: #fff1b8;
  border: 1px solid transparent;
  box-shadow: none;

  &.leaflet-tooltip-top::before {
    border-top-color: #fff1b8;
  }
`

export default React.memo(TooltipComponent)
