import packageInfo from './../desktop/package.json'

class EnvStore {
  pageSize = 10
  apiBaseUrl =
    process.env.REACT_APP_API_ROOT ||
    'http://59.120.53.132/SmartPoleSystem/api/v1'

  get isEnvDev(): boolean {
    return process.env.NODE_ENV === 'development'
  }
  get appBaseName(): string {
    return process.env.REACT_APP_BASE_NAME || ''
  }
  get googleMapsApiKey(): string {
    return (
      process.env.REACT_APP_GOOGLE_MAPS_KEY ||
      (this.isEnvDev
        ? 'AIzaSyCbjcCeDkCTTUQX_BLLKV5lf_JIShI1PcY'
        : 'AIzaSyCbjcCeDkCTTUQX_BLLKV5lf_JIShI1PcY')
    )
  }
  get googleReCAPTCHAKey(): string {
    return (
      process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ||
      '6LfVncQUAAAAACx7g4lBiP4_k_N0J7ZApSYcbnd8'
    )
  }
  get googleMapsGeocodingApiBaseUrl(): string {
    return 'https://maps.googleapis.com/maps/api/geocode'
  }
  get publicUrl(): string {
    return process.env.PUBLIC_URL || ''
  }
  get appUrl(): string {
    return `${window.location.protocol}//${window.location.host}${this.appBaseName}`
  }
  get canUseDOM(): boolean {
    return !!(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    )
  }
  get imageUrlPrefix(): string {
    return `${this.publicUrl}/img`
  }
  get remoteImageUrlPrefix(): string {
    return process.env.REACT_APP_PUBLIC_IMAGE || ''
  }
  get mqttServer(): any {
    return {
      url:
        process.env.REACT_APP_MQTT_SERVER_URL ||
        'ws://broker.mqttdashboard.com:8000/mqtt',
      username: process.env.REACT_APP_MQTT_SERVER_USERNAME || '',
      password: process.env.REACT_APP_MQTT_SERVER_PASSWORD || '',
    }
  }
  get minimumClusterSize(): number {
    let minClusterSize = process.env.REACT_APP_MINIMUN_CLUSTER_SIZE
    return minClusterSize ? Number(minClusterSize) : 16
  }
  get defaultClusterSize(): number {
    return Number(process.env.REACT_APP_DEFAULT_CLUSTER_SIZE) || 2
  }
  get clientUrl(): string {
    return this.isEnvDev
      ? 'http://localhost:3000/nid/auth'
      : 'http://59.120.53.132/CampusFCU/nid/auth'
  }

  set setPageSize(pageSize: number | undefined) {
    this.pageSize = pageSize || 50
  }

  set setApiBaseUrl(url: string) {
    this.apiBaseUrl = url
  }

  get appVersion(): string {
    return packageInfo.version || '0.0.0'
  }
}

export const envStore = new EnvStore()

export const endpoints = {
  apiBaseUrl: envStore.apiBaseUrl,
}

export const defaultPageSize = () => envStore.pageSize

export const imageUrlPrefix = envStore.imageUrlPrefix

export const remoteImageUrlPrefix = envStore.remoteImageUrlPrefix

export const mqttServer = envStore.mqttServer

export const publicUrl = envStore.publicUrl

export const clientUrl = envStore.clientUrl

export default envStore
