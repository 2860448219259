import { SVGProps } from 'react'

const CheckedSVG = (props: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#5E9CE5"
        d="M4.395 12.471c-.217-.029-.406-.154-.515-.34l-2.787-4.79c-.193-.33-.076-.75.26-.938.336-.19.763-.074.956.256l2.33 4.008 8.593-8.465c.273-.268.716-.27.99-.002s.276.703.003.973l-9.209 9.072-.033.032c-.133.13-.312.201-.495.201-.032 0-.062-.001-.093-.007z"
      />
    </g>
  </svg>
)

export default CheckedSVG
