import { createGlobalStyle, ThemeProps, css } from 'styled-components'
import theme from './theme'

export const menuTooltips = css`
  .ant-menu-inline-collapsed-tooltip {
    .ant-toolitp-content {
      font-weight: 500 !important;
    }

    .ant-tooltip-arrow-content {
      background-color: ${p => p.theme.menuInlineTooltip.bg} !important;
    }

    .ant-tooltip-inner {
      background: ${p => p.theme.menuInlineTooltip.bg} !important;
      border: 1px solid ${p => p.theme.menuInlineTooltip.bg} !important;
      > a {
        color: ${p => p.theme.menuInlineTooltip.color};
        font-weight: normal;

        &:hover {
          color: ${p => p.theme.menuInlineTooltip.active};
        }
      }
    }
  }
`

export const textUtils = css`
  .text-largest {
    font-size: 18px !important;
  }

  .text-larger {
    font-size: 16px !important;
  }

  .text-default {
    font-size: 14px !important;
  }

  .text-smaller {
    font-size: 13px;
  }

  .text-sm {
    font-size: 12px !important;
  }

  .text-xs {
    font-size: 10px;
  }
  .text-darkBlue {
    color: ${p => p.theme.darkBlue};
  }
`

export const googleMap = css`
  .gm-style .style-info-box {
    width: auto !important;

    > img:first-child {
      display: none;
    }
  }

  .gm-style-cc {
    display: none;
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    div:nth-child(2) {
      display: none;
    }
    .gm-control-active {
      width: 28px !important;
      height: 28px !important;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #f4f3f3 !important;
      img {
        display: none !important;
      }
      &:first-child:after {
        content: '+';
        vertical-align: middle;
        display: inline-block;
        text-align: center;
        font-size: 32px;
        color: #003248;
        line-height: 0;
      }
      &:last-child {
        &:after {
          content: '';
          vertical-align: middle;
          display: inline-block;
          width: 17px;
          height: 1px;
          border-top: solid 3px #003248;
          text-align: center;
        }
      }
    }
  }

  .gm-style .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: transparent;
    box-shadow: unset;
  }

  .gm-ui-hover-effect {
    visibility: hidden !important;
  }

  .gm-style a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }

  .gm-style .gm-style-iw-d {
    overflow: unset !important;
  }

  .gm-style .gm-style-iw-c {
    padding-top: 32px;
    padding-left: 0 !important;
  }

  .gm-style .gm-style-iw > button > img {
    width: 18px !important;
    height: 18px !important;
    position: relative;
    right: 10px;
    top: 5px;
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom
    .gm-control-active:first-child:after {
    width: 18px;
    height: 7px !important;
  }

  .gm-style .gm-style-iw-t::after {
    display: none;
  }

  .gm-bundled-control {
    margin: 0 !important;
    bottom: 100px !important;
    left: 24px !important;

    > .gmnoprint > div {
      height: auto !important;
      background: transparent !important;
      box-shadow: none !important;
      cursor: default !important;
    }

    .gm-control-active {
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #f4f3f3 !important;
    }

    .gm-control-active:first-child + div {
      display: none;
    }

    .gm-control-active:last-child {
      top: 12px !important;
    }
  }
`

export const innmaxMap = css`
  #map {
    visibility: hidden;
  }

  div[role='region'] > div:nth-child(1) > div:nth-child(2) {
    visibility: hidden;
  }

  .gm-style {
    img[src^='/SPE'],
    > div:nth-child(2)
      > div:nth-child(1)
      div[style*='width: 28px; height: 28px; overflow: hidden; position: absolute;'] {
      visibility: hidden;
      opacity: 0;
    }

    .innmaxmap-marker > img {
      width: 28px !important;
      height: 28px !important;
      margin: 0 !important;
      visibility: visible !important;
      opacity: 1 !important;
      padding: 4px !important;
    }
  }

  .leaflet-marker-icon {
    border: 0;
    visibility: hidden;
    opacity: 0;
  }

  .innmaxmap-marker {
    width: 26px;
    height: 26px;
    padding: 4px;
    visibility: visible !important;
    opacity: 1 !important;

    > div > img {
      width: 100% !important;
      height: 100%;
    }

    &.icon-animation.googlemap {
      animation: googlemapbounce 0.9s;
      animation-iteration-count: infinite;
    }

    @keyframes googlemapbounce {
      0% {
        margin-top: 0px;
        animation-timing-function: ease-in;
      }

      50% {
        margin-top: -14px;
        animation-timing-function: ease-out;
      }

      100% {
        margin-top: 0px;
        animation-timing-function: ease-in;
      }
    }

    &.location.googlemap {
      width: 24px !important;
      height: 24px !important;
      border-radius: 0 50% 50% 50%;
      transform: rotate(-135deg);
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
      border: solid 0.5px #fff;
      background-image: linear-gradient(
        138deg,
        #12729e 3%,
        #00a0e9 51%,
        #7deeff 100%
      );

      &:after {
        content: ' ';
        width: 10px;
        height: 10px;
        background: white;
        position: absolute;
        top: 6.9px;
        transform: rotate(-135deg);
        left: 6.8px;
        border-radius: 50%;
        box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
      }

      > img {
        display: none;
      }
    }

    &.location {
      padding: 0;
      border: 0;
      background: unset;

      &.off > div {
        background: #c0c0c0 !important;
        box-shadow: none !important;
      }

      > div {
        width: 24px;
        height: 24px;
        border-radius: 0 50% 50% 50%;
        transform: rotate(-135deg);
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
        border: solid 0.5px #fff;
        background-image: linear-gradient(
          138deg,
          #12729e 3%,
          #00a0e9 51%,
          #7deeff 100%
        );

        &:after {
          content: ' ';
          width: 10px;
          height: 10px;
          background: white;
          position: absolute;
          top: 6.9px;
          left: 6.8px;
          border-radius: 50%;
        }

        > img {
          display: none;
        }
      }
    }

    &.inverter,
    &.chiller,
    &.airConditioner,
    &.ammeter {
      border-radius: 6px;
      border: solid 0.5px #fff;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    }

    &.chiller,
    &.inverter {
      &.normal {
        background-image: linear-gradient(to bottom, #006f34, #a6ff39);
      }

      &.alarm {
        background-image: linear-gradient(to bottom, #a61010, #f00);
      }

      &.off {
        background: #c0c0c0 !important;
        box-shadow: none !important;
      }
    }

    &.airConditioner {
      &.normal {
        background-image: linear-gradient(to bottom, #3b3dd7, #13005b 98%);
      }

      &.alarm {
        background-image: linear-gradient(to bottom, #a61010, #f00);
      }

      &.off {
        background: #c0c0c0 !important;
        box-shadow: none !important;
      }
    }

    &.ammeter {
      &.normal {
        background-image: linear-gradient(to bottom, #0ab1c1 21%, #0b717b);
      }

      &.alarm {
        background-image: linear-gradient(to bottom, #a61010, #f00);
      }

      &.off {
        background: #c0c0c0 !important;
        box-shadow: none !important;
      }
    }
  }
`

const inputCss = css`
  input,
  textarea {
    [type='text'] {
      color: #fff;
    }
  }
`

const GlobalStyles = createGlobalStyle<ThemeProps<typeof theme>>`
  html, body {
    position: relative;
    min-width: 768px;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
      'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-variant: tabular-nums;
    line-height: 1.5;
    font-feature-settings: "tnum","tnum";
    -webkit-font-feature-settings: "tnum";
    -webkit-font-smoothing: subpixel-antialiased;
  }

  #root {
    position: relative;
  }

  /* ant-design overrides */

  .hover-bg-blue {
    &:hover {
      background-color: ${p => p.theme.blue} !important;
      color: #fff  !important;
    }
  }

  .ant-modal {
    animation-duration: 0s !important;
    transition: none !important;
  }

  .ant-spin-dot-item {
    background: ${p => p.theme.grey} !important;
  }

  .ant-spin-text {
    color: ${p => p.theme.yellow} !important;
  }

  .ant-image-preview-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-picker.c-datepicker {
    background-color: ${p => p.theme.input.bg} !important;
    border-radius: 4px;
    padding: 2.4px 11px 2.4px;
    .ant-picker-input input {
      color: ${p => p.theme.darkGrey} !important;
      font-size: 16px;
    }
    .ant-picker-suffix {
      svg {
        width: 12px;
        height: 12px;
      }
    }
    .ant-picker-clear {
      border-radius: 100%;
      overflow: hidden;
      line-height: 0;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }



  /* customize styles */
  .page__search-form {
    .ant-form-item-label {
      padding: 0 0 2px;
    }
  }

  .text-primary {
    color:  ${p => p.theme.primary} !important;
  }

  .text-pagination {
    color:  ${p => p.theme.pagination} !important;
  }

  /* .modal__form .flex:nth-child(even) div[class*=Column] {
      background: ${p => p.theme.dark100};
    } */

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .anticon.c-icon {
    &.icon-minus-circle svg {
      fill: ${p => p.theme.icon.primary};

      &:hover {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    :hover {
      svg {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    svg {
      fill: ${p => p.theme.icon.primary};
    }
  }


  svg.svg-icon  {
    cursor:pointer;
    display:inline-block;

    &.icon-lock:hover{
      path {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.is--small,
    &.icon-loading {
        height: 16px;
        width: 16px;
    }


    &.icon-eye,
    &.icon-process,
    &.icon-info {
      > g path:nth-child(2),
      > g path:nth-child(2),
      use {
        fill: ${p => p.theme.icon.primary};
      }
    }

    &.icon-play:hover {
      > g {
        opacity: 1;
      }
      > g > g > g > g > g > g > g > circle {
        stroke: ${p => p.theme.icon.primaryHover};
      }
      > g > g > g > g > g > g > g > g > g path {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-location:hover,
    &.icon-process:hover {
      > g > path:nth-child(2) {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-eye:hover, &.icon-edit:hover {
      use {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-minus-circle:hover  {
      g g {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-info:hover {
      > g path:nth-child(3) {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.is--primary {
      &.icon-add > g path:nth-child(2) {
        fill: ${p => p.theme.primary};
      }
    }

    &.is--secondary {
      svg {
        height: 16px;
        width: 16px;
      }

      &.icon-add > g path:nth-child(2),
      &.icon-export > g path:nth-child(2),
      &.icon-eye use {
        fill: ${p => p.theme.icon.secondary};
      }

      g path:nth-child(3) {
        fill: ${p => p.theme.icon.secondary};
      }
    }
  }

  .c-link {
    color: #00a0e8;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #00a0e8;
    }
  }

  .c-btn.ant-btn {
    border-radius: 3px;
    font-weight: 600;
    font-size:16px;
  }

  .c-btn--primary.ant-btn {
    color: ${p => p.theme.btn.text};
    border-color: ${p => p.theme.btn.bg};
    background: ${p => p.theme.btn.bg};

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.text};
      background: ${p => p.theme.btn.bgHover};
      border-color: ${p => p.theme.btn.bgHover};
    }
  }

  .c-btn--outline.ant-btn {
    color: ${p => p.theme.btn.bg};
    border: 1.3px solid ${p => p.theme.btn.bg};
    background: #fff;

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.bg};
      background: ${p => p.theme.btn.outlineHover};
      border: 1.3px solid ${p => p.theme.btn.bg};
    }
  }

  .c-btn--danger-ghost {
    color: ${p => p.theme.danger};
    border: 1.3px solid ${p => p.theme.danger};
    background: ${p => p.theme.transparent};

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.danger};
      background: ${p => p.theme.transparent};
      border: 1.3px solid ${p => p.theme.danger};
    }
  }

  .c-btn--danger.ant-btn {
    color: ${p => p.theme.danger};
    border: 1.3px solid ${p => p.theme.transparent};
    background: ${p => p.theme.transparent};

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.danger};
      background: ${p => p.theme.transparent};
      border: 1.3px solid ${p => p.theme.transparent};
    }
  }

  &.c-btn--link {
    background: ${p => p.theme.transparent};
    border: ${p => p.theme.transparent};
    color: ${p => p.theme.danger};

    > span {
      :hover {
        text-decoration: underline;
        color: ${p => p.theme.danger};
      }
    }

    &:hover {
      color: ${p => p.theme.danger};
      background: ${p => p.theme.transparent};
      border: ${p => p.theme.transparent};
    }
  }

  &.c-btn--link--primary {
    background: transparent;
    border:transparent;
    color: ${p => p.theme.primary};

    > span {
      text-decoration: underline;
      :hover {
        color: ${p => p.theme.primary};
      }
    }

    &:hover {
      background: transparent;
      border:transparent;
      color: ${p => p.theme.primary};
    }

  }

  .sks-small-select  .ant-select-selector{
     height: 24px !important;

      .ant-select-selection-item, .ant-select-selection-placeholder {
        line-height: 1.5  !important;
      }
  }

  .sks-small-select   .ant-select-clear {
    top: 45%!important;
  }

  .bg-lightYellow {
    background-color: rgba(255, 206, 43, 0.05) !important;
  }

  tr.gl-row-dragging {
    background: #fffcf1;
    height: 48px;
    display:flex;
    align-items: center;

    td.drag-visible {
      padding-right: 16px;
      padding-left: 16px;
      visibility: visible;

      &.flex-auto {
        flex:1;
        text-align: left;
      }
    }
  }



  ${textUtils}
  ${menuTooltips}
  ${inputCss}

  ${innmaxMap}
  ${googleMap}
`

export default GlobalStyles
