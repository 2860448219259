import { SVGProps } from 'react'

const GatewaySVG = (props: SVGProps<any>) => (
  <svg
    width="46"
    height="48"
    viewBox="0 0 46 48"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <defs>
      <path id="8txlsu16xa" d="M0 0h6.149v9.133H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M23.358 10.017c-6.984 0-12.666 5.682-12.666 12.666s5.682 12.666 12.666 12.666 12.666-5.682 12.666-12.666-5.682-12.666-12.666-12.666m0 27.832c-8.362 0-15.166-6.804-15.166-15.166S14.996 7.517 23.358 7.517s15.166 6.804 15.166 15.166-6.804 15.166-15.166 15.166"
        fill="#FFCC2B"
      />
      <path
        d="M23.358 10.017c-1.698 0-4.165 4.935-4.165 12.666 0 7.731 2.467 12.666 4.165 12.666s4.165-4.935 4.165-12.666c0-7.731-2.467-12.666-4.165-12.666m0 27.832c-4.329 0-6.665-7.813-6.665-15.166 0-7.353 2.336-15.166 6.665-15.166 4.33 0 6.665 7.813 6.665 15.166 0 7.353-2.336 15.166-6.665 15.166"
        fill="#FFCC2B"
      />
      <path
        d="M23.358 20.098c-5.42 0-10.26-1.142-12.947-3.055a1.25 1.25 0 1 1 1.451-2.037c2.21 1.574 6.723 2.592 11.496 2.592 4.774 0 9.287-1.018 11.496-2.592a1.25 1.25 0 1 1 1.451 2.035c-2.684 1.914-7.524 3.057-12.947 3.057M35.579 30.592c-.251 0-.504-.075-.725-.232-2.209-1.574-6.721-2.59-11.496-2.59-4.773 0-9.286 1.016-11.496 2.591a1.254 1.254 0 0 1-1.744-.293 1.253 1.253 0 0 1 .293-1.745c2.686-1.913 7.526-3.054 12.947-3.054 5.422 0 10.262 1.142 12.947 3.055a1.25 1.25 0 0 1-.726 2.268"
        fill="#FFCC2B"
      />
      <path
        d="M23.358 10.017c-6.984 0-12.666 5.682-12.666 12.666s5.682 12.666 12.666 12.666 12.666-5.682 12.666-12.666-5.682-12.666-12.666-12.666m0 27.832c-8.362 0-15.166-6.804-15.166-15.166S14.996 7.517 23.358 7.517s15.166 6.804 15.166 15.166-6.804 15.166-15.166 15.166"
        fill="#FFCC2B"
      />
      <path
        d="M23.358 44.37a1.25 1.25 0 1 1 0-2.5c10.58 0 19.187-8.607 19.187-19.187a1.25 1.25 0 1 1 2.5 0c0 11.958-9.728 21.687-21.687 21.687M41.059 13.714c-.432 0-.852-.224-1.083-.625C34.686 3.927 22.928.775 13.765 6.066a1.25 1.25 0 1 1-1.25-2.166c10.357-5.978 23.648-2.416 29.625 7.94a1.248 1.248 0 0 1-1.081 1.874M13.139 41.632c-.212 0-.427-.054-.624-.167A21.542 21.542 0 0 1 2.41 28.296 21.547 21.547 0 0 1 4.577 11.84a1.25 1.25 0 0 1 2.165 1.25c-5.29 9.162-2.14 20.92 7.023 26.21a1.248 1.248 0 0 1-.626 2.332"
        fill="#FFCC2B"
      />
      <g transform="translate(22.108 38.223)">
        <mask id="thouebsfob" fill="#fff">
          <use xlinkHref="#8txlsu16xa" />
        </mask>
        <path
          d="M4.898 9.133a1.25 1.25 0 0 1-.791-.282L.459 5.865a1.257 1.257 0 0 1-.453-.843c-.032-.33.068-.66.278-.916L3.27.458a1.25 1.25 0 1 1 1.934 1.584L3.01 4.722l2.68 2.193a1.249 1.249 0 0 1-.793 2.218"
          fill="#FFCC2B"
          mask="url(#thouebsfob)"
        />
      </g>
      <path
        d="M41.06 13.714c-.068 0-.137-.005-.205-.017l-4.652-.76a1.25 1.25 0 1 1 .404-2.467l3.42.558.558-3.418a1.25 1.25 0 0 1 2.467.405l-.76 4.651a1.252 1.252 0 0 1-1.233 1.048M7.325 18.124a1.25 1.25 0 0 1-1.169-.809l-1.225-3.239-3.24 1.224a1.247 1.247 0 0 1-1.61-.728 1.248 1.248 0 0 1 .727-1.61l4.41-1.666a1.245 1.245 0 0 1 1.61.727l1.666 4.41a1.248 1.248 0 0 1-1.169 1.691"
        fill="#FFCC2B"
      />
    </g>
  </svg>
)

export default GatewaySVG
