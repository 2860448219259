import { QueryClient, QueryCache, QueryFunctionContext } from 'react-query'
import { ifElse, isNil, identity, has, is, pathOr } from 'ramda'
import { format, request } from '@/services'
import toQueryString from '@/utils/toQueryString'
import { message, destroy } from '@/utils/message'
import { AuthContextType } from '@/auth'
import { persistQueryKeyStorage } from './usePersistQuery'

type Options = [string, { [key: string]: any }]

const hasToken = ifElse(isNil, identity, has('token'))

// Define a default query function that will receive the query key
async function defaultQueryFn({ queryKey }: QueryFunctionContext<Options>) {
  let _queryKey: any = queryKey

  if (is(String, _queryKey)) {
    _queryKey = [_queryKey]
  }

  const [url, { pageNum = 0, pageSize = 10, ..._params } = {}] = _queryKey

  const { fullUrl, params } = format(url, { pageNum, pageSize, ..._params })

  // const controller = new AbortController()
  // const signal = controller.signal

  delete params['pageNum']
  delete params['pageSize']
  delete params['current']
  delete params['size']

  const isQueryUrl = url.indexOf('?') > -1
  const promise = !isQueryUrl
    ? request(`${fullUrl}?${toQueryString(params)}`)
    : request(`${fullUrl}`)

  // promise.cancel = () => signal.abort()

  return promise
}

// async function defaultMutationFn() {}

const onError = ({ error, auth }: { error: any; auth: AuthContextType }) => {
  const { message: msg, error: err, status } = error
  const { logout, user } = auth

  const isTokenExpired = (msg === 'Unauthorized' || err === 'Unauthorized') && status === 401
  const logoutIfNeed = isTokenExpired && hasToken(user)

  if (logoutIfNeed) {
    logout()
    message({
      content: '登入已過期，請重新登入',
      maxCount: 1,
      type: 'error',
      top: 100,
      onClose: () => {
        destroy()
      },
    })
  }
}

type CreateQueryClientOptions = {
  /**
   * default is true
   */
  refetchPersistOnError: boolean
}

export let queryClient: QueryClient

function refetchPersistIfNeed(error: any) {
  const errorCode: number | null = pathOr(null, ['error', 'code'], error)
  if (isNil(errorCode)) {
    return
  }
  const queryKeys = persistQueryKeyStorage.getQueryKey(errorCode)
  queryKeys.forEach(async queryKey => {
    try {
      queryClient.invalidateQueries(queryKey, {
        refetchInactive: true,
      })
    } catch (error) {
      console.error('invalidate queries error:', error)
    }
  })
}

export default function createQueryClient(
  auth: AuthContextType,
  options?: CreateQueryClientOptions
) {
  if (!queryClient) {
    queryClient = new QueryClient({
      defaultOptions: {
        mutations: {
          onError: (error: any) => {
            onError({ auth, error })
            if (options?.refetchPersistOnError || true) {
              refetchPersistIfNeed(error)
            }
          },
        },
        queries: {
          onError: (error: any) => onError({ auth, error }),
          queryFn: defaultQueryFn,
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          refetchInterval: false,
          retry: false,
        },
      },
      queryCache: new QueryCache({
        onError: (error: any) => onError({ auth, error })
      })
    })
  }

  return queryClient
}
