import React from 'react'
import cx from 'classnames'

type Props = {
  style?: React.CSSProperties
  className?: string
}

const Asterisk = ({ style, className }: Props) => (
  <span
    style={style}
    className={cx('text-danger ml-2', className)}
    css={{ position: 'relative', top: 3 }}>
    *
  </span>
)

export default Asterisk
