import request, { Mutation, MutationOptions, useMakeMutation } from '@/services'
import { AirConditioner } from '../device.types'

export const useAddAirConditioner = (options: MutationOptions = {}): Mutation =>
  useMakeMutation(
    (body: AirConditioner) =>
      request('/device/airConditioner', {
        method: 'POST',
        body,
      }),
    options
  )

export const useUpdateAirConditioner = (
  options: MutationOptions = {}
): Mutation =>
  useMakeMutation(
    (body: AirConditioner) =>
      request('/device/airConditioner/{id}', {
        method: 'PUT',
        body,
      }),
    options
  )

export const useDeleteAirConditioner = (
  options: MutationOptions = {}
): Mutation =>
  useMakeMutation(
    (body: AirConditioner) =>
      request('/device/airConditioner/{id}', {
        method: 'DELETE',
        body,
      }),
    options
  )
