import { SVGProps } from 'react'
import cx from 'classnames'

const LargeCardSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    className={cx('icon-largecard', className)}
    {...props}>
    <rect
      x="1.6"
      y="1.6"
      width="12.8"
      height="12.8"
      rx="1"
      stroke="#4A4A4A"
      strokeWidth="1.2"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default LargeCardSVG
