import { SVGProps } from 'react'
import cx from 'classnames'

const Report = ({ className }: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('menu-icon-report', className)}>
    <path
      d="M21.186 3a.814.814 0 0 1 .095 1.623l-.095.006-.815-.001v13.328c0 .415-.31.757-.712.808l-.102.006-4.698-.001 2.267 1.776a.814.814 0 0 1-.92 1.34l-.084-.058L12.5 18.99l-3.622 2.837a.814.814 0 0 1-1.075-.063l-.068-.076a.814.814 0 0 1 .063-1.075l.076-.068 2.266-1.776H5.443a.814.814 0 0 1-.808-.711l-.006-.102-.001-13.328h-.814a.814.814 0 0 1-.095-1.623L3.814 3h17.372zm-2.444 1.628H6.257L6.256 15.33l2.034-3.087a.814.814 0 0 1 .968-.313l.095.043 1.536.817.827-4.1a.815.815 0 0 1 1.323-.463l.074.072 1.631 1.772 1.313-1.273a.814.814 0 0 1 1.076-.051l.075.069c.29.298.305.76.052 1.076l-.07.075-1.911 1.855a.814.814 0 0 1-1.09.039l-.076-.072-1.116-1.213-.727 3.608a.815.815 0 0 1-1.09.6l-.091-.042-1.847-.984-2.228 3.382h11.728V4.629z"
      fill="#878787"
      fillRule="nonzero"
    />
  </svg>
)

export default Report
