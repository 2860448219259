import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultOptionType, SelectProps } from 'antd/lib/select'
import Select from '@components/ui/Select'
import { PowerPlant } from '@lib/power'
import { pathOr, indexBy, prop } from 'ramda'
import { Gateway, useDeviceList } from '@lib/device'
import { DeviceType } from '@lib/constants'

interface Props<T = any> extends SelectProps<T> {
  powerPlantId?: number
  sessionId?: number
  placeholder?: string
  valueKey?: string
  showAll?: boolean
  onGatewaySelected?: (gateway: Gateway) => void
}

const GatewaySelect = React.memo(
  ({
    placeholder,
    valueKey = 'id',
    powerPlantId,
    sessionId,
    showAll = false,
    onChange,
    onGatewaySelected,
    ...props
  }: Props) => {
    const { t } = useTranslation()
    const { dataSource, loading } = useDeviceList<Gateway>(
      {
        deviceType: DeviceType.GATEWAY,
        powerPlant: powerPlantId,
        sessionId,
      },
      {
        enabled: !!powerPlantId || !!sessionId || showAll,
      }
    )

    const gatewayById = React.useMemo(
      () => indexBy(prop('id'), dataSource),
      [dataSource]
    )

    const handleOnChanged = (
      value: number,
      option: DefaultOptionType | DefaultOptionType[]
    ) => {
      onChange && onChange(value, option)
      onGatewaySelected && onGatewaySelected(gatewayById[value])
    }

    return (
      <Select<number>
        {...props}
        onChange={handleOnChanged}
        loading={loading}
        notFoundContent={
          powerPlantId || sessionId || showAll ? (
            <div className="text-white">無閘道器</div>
          ) : (
            <div className="text-white">請先選擇案場</div>
          )
        }
        placeholder={placeholder}>
        {powerPlantId || sessionId || showAll
          ? dataSource.map(gateway => (
              <Select.Option
                key={gateway.id}
                value={pathOr('', [valueKey], gateway)}>
                {gateway.displayName}
              </Select.Option>
            ))
          : []}
      </Select>
    )
  }
)

export default GatewaySelect
