import { useQuery } from 'react-query'
import { assocPath, path, pathOr, values } from 'ramda'
import React from 'react'
import { convertData } from '@/services'
import { ComparisonReport } from '.'
import { formatDate, isNil } from '@/utils/webHelper'
import {
  PAGE_SIZE,
  TYPE,
  xAxisName,
} from 'src/containers/ReportManagement/utils'

export const useComparisonReport = (querys: { [key: string]: any }) => {
  // const { data: _data, ...others } = useQuery(['/report/powerplant/compare', querys], {
  //   enabled: !isNil(querys),
  // })

  const randomNumbers = () => {
    const numbers = []
    for (let i = 1; i < Number(new Date().getDate() + 1); i++) {
      numbers.push(
        {
          id: i,
          dateStr: `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${i}`,
          plantDatas: [
            {
              powerPlantSeq: 1,
              name: '向上店',
              value: Math.floor(Math.random() * 9999)
            },
            {
              powerPlantSeq: 2,
              name: '明德店',
              value: Math.floor(Math.random() * 9999)
            },
            {
              powerPlantSeq: 3,
              name: '虎林店',
              value: Math.floor(Math.random() * 9999)
            }
          ]
        }
      )
    }
    return numbers
  }

  const data = React.useMemo(() => ({
    timestamp: 1656389212562,
    data: randomNumbers(),
    errorMsg: null
  }), [])
  const others = {
    isLoading: false
  }

  const dataSource = React.useMemo(() => {
    const reportList = path(['data'], data) as ComparisonReport

    if (!reportList) {
      return { content: [], total: 0, totalPages: 0 }
    }
    const { reportType } = querys
    const currentTime = new Date().getTime()

    const powerPlantsData: any = {}
    const xAxis = reportList.filter(
      c => currentTime > new Date(c.dateStr).getTime()
    )
    xAxis.forEach(v => {
      v.plantDatas.forEach(data => {
        const chartValues: any[] = pathOr(
          [],
          [data.name, 'values'],
          powerPlantsData
        )
        chartValues.push({
          y: data.value,
          name: v.dateStr,
        })
        powerPlantsData[data.name] = {
          name: data.name,
          values: chartValues,
        }
      })
    })

    let xLabels: (string | number)[] = []

    switch (reportType) {
      case TYPE.YEAR:
        xLabels = xAxis.map(i => {
          const m = new Date(i.dateStr).getMonth()
          return xAxisName(m)
        })
        break
      case TYPE.MONTH:
        xLabels = xAxis.map(i => new Date(i.dateStr).getDate())
        break
      case TYPE.DAY:
        xLabels = xAxis.map(i => formatDate(new Date(i.dateStr).valueOf()))
        break
      default:
    }

    const len = xAxis.length

    return {
      xAxisLabels: xLabels,
      total: len,
      totalPages: Math.ceil(len / PAGE_SIZE),
      powerPlantsData: values(powerPlantsData),
    }
  }, [data, querys])

  return {
    dataSource,
    ...others,
  }
}

export const useComparisonList = (querys: { [key: string]: any }) => {
  // const { data, ...others } = useQuery(['/report/powerplant/compare', querys], {
  //   enabled: !isNil(querys),
  // })

  const randomNumbers = () => {
    const numbers = []
    for (let i = 1; i < Number(new Date().getDate() + 1); i++) {
      numbers.push(
        {
          id: i,
          dateStr: `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${i}`,
          plantDatas: [
            {
              powerPlantSeq: 1,
              name: '向上店',
              value: Math.floor(Math.random() * 9999)
            },
            {
              powerPlantSeq: 2,
              name: '明德店',
              value: Math.floor(Math.random() * 9999)
            },
            {
              powerPlantSeq: 3,
              name: '虎林店',
              value: Math.floor(Math.random() * 9999)
            }
          ]
        }
      )
    }
    return numbers
  }

  const data = React.useMemo(() => ({
    timestamp: 1656395698045,
    data: randomNumbers(),
    errorMsg: null
  }), [])
  const others = {
    isLoading: false
  }

  const report = React.useMemo(() => {
    return pathOr([], ['data'], data).filter((i: any) => (
      new Date().getTime() > new Date(i.dateStr).getTime()
    )) as ComparisonReport
  }, [data])

  const dataSource = React.useMemo(() => {
    const reportList = path(['data'], data) as ComparisonReport

    if (!reportList) {
      return {
        content: [],
        total: 0,
        totalPages: 0,
      }
    }

    const currentTime = new Date().getTime()

    let powerPlantsData: any = {}
    const availableReportValue = reportList.filter(
      r => currentTime > new Date(r.dateStr).getTime()
    )
    availableReportValue.forEach(v => {
      powerPlantsData = assocPath(
        [v.dateStr, 'createAtStr'],
        v.dateStr,
        powerPlantsData
      )

      v.plantDatas.forEach(plant => {
        powerPlantsData = assocPath(
          [v.dateStr, plant.powerPlantSeq],
          plant.value,
          powerPlantsData,
        )
      })
    })

    return convertData({
      converter: x => x,
    })({
      data: values(powerPlantsData),
    })
  }, [data])

  const columns = React.useMemo(() => {
    const reportList = path(['data'], data) as ComparisonReport

    return pathOr([], [0, 'plantDatas'], reportList).map((device: any) => {
      return {
        title: device.name,
        dataIndex: device.powerPlantSeq,
        key: device.powerPlantSeq,
      }
    })
  }, [data])

  return {
    dataSource,
    columns,
    report,
    ...others,
  }
}
