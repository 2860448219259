import React from 'react'
import { useTranslation } from 'react-i18next'
import { length, pathOr, isEmpty } from 'ramda'
import { Row, Col, Spin } from 'antd'
import HighchartsReact from 'highcharts-react-official'
import * as Highcharts from 'highcharts'
import Box from '@components/Box'
import { useAnalysisReport } from '@/report'
import theme from '@assets/theme'

import initDualChart, {
  tooltipHeaderFormat,
  tooltipPointFormat,
} from '../ReportChartConfig'
import { PAGE_SIZE, TYPE } from '../utils'
import moment from 'moment'
import Pagination from '../Pagination'
import fake from './fake'

type AlarmSummary = {
  id: React.Key
  dateStr: string
  alarmCount: number
}

type ChartDataProps = {
  content: AlarmSummary[]
  total: number
  totalPages: number
  xAxis: any[]
  xAxisLabels: any[]
}

type Props = {
  exporting: boolean
  query: Record<string, any>
  onExportReport: () => any
}

const initialChartData = {
  content: [],
  total: 0,
  totalPages: 0,
  xAxis: [],
  xAxisLabels: [],
}

export function ReportChart({ exporting, query, onExportReport }: Props) {
  const { t } = useTranslation()

  const [startIdxChart, setStartIdxChart] = React.useState(0)
  const [currentChart, setCurrentChart] = React.useState(0)
  const [chartData, setChartData] =
    React.useState<ChartDataProps>(initialChartData)
  const [chartTitle, setChartTitle] = React.useState<any>()
  const [chartLabel, setChartLabel] = React.useState<any>()

  const [chartOptions, setChartOptions] = React.useState<Highcharts.Options>({
    ...initDualChart,
    chart: {
      type: 'column',
      height: 320,
      backgroundColor: 'transparent',
    },
    colors: [theme.lightBlue, '#c0973f', theme.primary],
    yAxis: {
      ...initDualChart.yAxis[0],
      title: {
        ...initDualChart.yAxis[0].title,
        margin: 0,
        text: t('report:alarm yAxis Label'),
        useHTML: true,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      ...initDualChart.tooltip,
      formatter() {
        return [''].concat(
          this.points
            ? this.points.map(function (val) {
                return (
                  `<b style="font-size:13px; color:${theme.tooltip.color};font-weight:500"><div style="margin-bottom:8px;">` +
                  val.point.name +
                  '</div></b>' +
                  tooltipPointFormat(val, false, t('report:count'))
                )
              })
            : []
        )
      },
    },
  })

  // const { dataSource, isLoading: loading } = useAnalysisReport(query)
  const dataSource = fake
  const loading = false

  React.useEffect(() => {
    if (isEmpty(query)) {
      return
    }
    switch (query.reportType) {
      case TYPE.MONTH:
        setChartLabel(`${moment().year()}/${query.month}`)
        break
      case TYPE.YEAR:
        setChartLabel(query.year)
        break
      default:
        break
    }
  }, [query])

  const options = React.useMemo(
    () => {
      const endIdx = startIdxChart + PAGE_SIZE
      return {
        ...initDualChart,
        colors: ['#44a3c0', '#ffcc2b'],
        chart: {
          backgroundColor: 'transparent',
        },
        xAxis: [
          {
            ...initDualChart.xAxis[0],
            crosshair: true,
            lineColor: '#8f8f8f',
            categories: pathOr([], ['powerPlantsData', 0, 'values'], dataSource).slice(startIdxChart, endIdx).map((i: any) => i?.dateStr),
            labels: {
              style: {
                color: '#4a4a4a',
              },
            },
          },
        ],
        yAxis: [
          ...pathOr([], ['powerPlantsData'], dataSource).map(
            ({ name, text }, index) => ({
              gridLineColor: '#8f8f8f',
              opposite: index === 1 ? true : false,
              min: 0,
              labels: {
                format: '{value}',
                style: {
                  color: theme.tooltip.color,
                  fontSize: '14px;',
                },
              },
              title: {
                ...initDualChart.yAxis[1].title,
                useHTML: true,
                offset: 0,
                text,
              },
            })
          ),
        ],
        plotOptions: {
          column: {
            pointWidth: 10,
            state: { hover: { brightness: 0 } },
          },
          series: {
            fillColor: {
              linearGradient: [0, 0, 0, 200],
              stops: [
                [0, '#ffcc2b'],
                [1, 'rgba(255, 232, 86, 0.2)'],
              ],
            },
          },
        },
        series: (dataSource.powerPlantsData || []).map((data: any) => ({
          name: data.name,
          data: data.values.slice(startIdxChart, endIdx).map((i: any) => i.value),
          type: data.type,
        })),
        tooltip: {
          ...initDualChart.tooltip,
          formatter: function (): any {
            return (this as any).points.reduce(function (s: any, point: any) {
              const pointCopy = point
              pointCopy.series.name = t(`report:${pointCopy.series.name}`)
              return s + tooltipPointFormat(pointCopy, true, '')
            }, tooltipHeaderFormat(this))
          },
        },
        legend: {
          ...initDualChart.legend,
          x: 40,
          y: 20,
          align: 'left',
          verticalAlign: 'bottom',
        }
      }
    },
    [dataSource, currentChart] // eslint-disable-line
  )

  const handleNextPage = React.useCallback(() => {
    const idx = currentChart + 1

    setStartIdxChart(PAGE_SIZE * idx)
    setCurrentChart(idx)
  }, [currentChart])

  const handlePrevPage = React.useCallback(() => {
    const idx = currentChart - 1

    setStartIdxChart(PAGE_SIZE * idx)
    setCurrentChart(idx)
  }, [currentChart])

  const _setChartTitle = () => {
    const data = query

    setChartTitle(` ${t('report:alarm statistics')}`)
    // setChartLabel(cTitle.xLabel)
    setStartIdxChart(0)
    setCurrentChart(0)
  }

  React.useEffect(() => {
    if (!chartData) {
      return
    }

    if (length(chartData.content) < 1) {
      return
    }

    setChartOptions(options => ({
      ...options,
      xAxis: {
        ...initDualChart.xAxis[0],
        categories: chartData.xAxisLabels,
      },
      series: [
        {
          name: t('light:title'),
          type: 'column',
          data: chartData.xAxis,
        },
      ],
    }))
  }, [chartData, currentChart]) // eslint-disable-line

  React.useEffect(() => {
    if (isEmpty(query)) {
      return
    }
    // console.log('[queryChart][query]', query)
    // queryChart(query)
    _setChartTitle()
  }, [query]) // eslint-disable-line

  const { totalPages } = dataSource

  return (
    <>
      <div className="flex mt-40 justify-end">
        {/* <Button
          color="secondary"
          icon="export"
          loading={exporting}
          onClick={onExportReport}>
          {t('report:export')}
        </Button> */}
      </div>
      <Pagination
        className=""
        page={currentChart + 1}
        totalPages={totalPages}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Box position="relative" css={{ marginLeft: -35 }}>
              <div style={{ width: '90%', margin: '0 auto' }}>
                <HighchartsReact highcharts={Highcharts} options={options} />
              </div>
              {query.reportType !== TYPE.DAY && (
                <Box
                  className="text-pagination"
                  fontSize="18px"
                  fontWeight="500"
                  position="absolute"
                  bottom="11px"
                  right="-37px">
                  {chartLabel}
                </Box>
              )}
            </Box>
          </Col>
        </Row>
      </Spin>
    </>
  )
}

export default React.memo(ReportChart)
