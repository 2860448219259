import React from 'react'
import { Select, SelectProps } from '@components/Select'

interface DeviceStatusSelectProps extends SelectProps<any> {
  queryAll?: boolean
  onChange?: (result: Record<string, boolean>) => void
}

export const DeviceStatusEnum: { [key: string]: string } = {
  isNormal: '正常',
  isAlarm: '警報',
}

export const DeviceStatusSelect = React.forwardRef<
  typeof Select,
  DeviceStatusSelectProps
>(({ queryAll, ...props }: DeviceStatusSelectProps, ref): any => {
  return (
    <Select ref={ref as any} {...props}>
      {queryAll && (
        <Select.Option key="all" value="">
          全部
        </Select.Option>
      )}
      {Object.keys(DeviceStatusEnum).map((i: any) => (
        <Select.Option key={i} value={i}>
          {DeviceStatusEnum[i]}
        </Select.Option>
      ))}
    </Select>
  )
})
