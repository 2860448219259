import React, { Suspense } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout } from 'antd'
import { path, contains } from 'ramda'
import { Exception, QueryErrorBoundary } from '@components/ErrorBoundary'
import { Loading } from '@components/Loading'
import { PageHeader } from '@components/Layout'
import GlobalStyles from '@assets/GlobalStyles'
import useTitle from '@/hooks/useTitle'
import { useProfile } from '@/system'
import { RouteItem, useAppRoutes, useRoutes } from '@/routes'
import SideMenu from 'src/components/SideMenu'
import Navbar from 'src/components/Navbar'
import Dashboard from 'src/containers/Dashboard'
import Login from 'src/containers/Login'
import * as routes from './routes'
import { useMineInfo } from '@lib/auth/useMineInfo'
import styled from 'styled-components'

const { compose } = require('ramda')

function App() {
  const { profile, isFetching } = useProfile()
  useTitle(profile?.name ?? '')

  const { refetch: getUserMineInfo } = useMineInfo()

  const { isAuthenticated, renderRoute } = useAppRoutes()

  const history = useHistory()
  const { i18n, t } = useTranslation()

  React.useEffect(() => {
    if (profile.language) {
      const lang = profile.language === 'zh-TW' ? 'zhTW' : 'en'
      i18n.changeLanguage(lang)
    }
  }, [profile.language]) //eslint-disable-line

  const { _routes, routeByUrl } = useRoutes(routes)

  React.useEffect(() => {
    isAuthenticated && getUserMineInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if (isFetching) {
    return <Loading />
  }

  const PageTitle = compose((route: RouteItem) => {
    if (contains(history.location.pathname, ['/', '/dashboard'])) {
      return <PageHeader sub="Dashboard" />
    }

    if (!route) {
      const subRoute = Object.keys(routeByUrl).find(x => {
        if (/(:\w*)/g.test(x)) {
          x = x.replaceAll(/(:\w*)/g, '')
        }
        return history.location.pathname.indexOf(x) > -1
      })
      if (subRoute) {
        const sub: any = path([subRoute])(routeByUrl)
        return <PageHeader sub={sub.parentName} title={sub.title} />
      }
      return null
    }

    return (
      <PageHeader
        sub={t(`menu:${route.mainKey}`)}
        title={t(`menu:${route.url}`) || route.title}
      />
    )
  }, path([history.location.pathname]))(routeByUrl)

  return (
    <QueryErrorBoundary>
      <GlobalStyles />
      {!isAuthenticated && (
        <Layout>
          <Login />
        </Layout>
      )}
      {isAuthenticated && (
        <Switch>
          <>
            <Route exact path="/login" component={Login} />
            <LayoutStyled>
              <SideMenu />
              <Layout.Content>
                <Suspense fallback={<Loading />}>
                  <Navbar title={PageTitle} />
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    {renderRoute({ data: _routes })}
                    <Route component={Exception} />
                  </Switch>
                </Suspense>
              </Layout.Content>
            </LayoutStyled>
          </>
        </Switch>
      )}
    </QueryErrorBoundary>
  )
}

const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  background-color: #e6edf1;
  @media only screen and (min-width: 768px) {
    min-width: 768px;
  }
`

export default App
