import React from 'react'
import { Popup, PopupProps } from 'react-leaflet'
import styled from 'styled-components'

function PopupComponent(
  { ...props }: PopupProps,
  ref: React.Ref<any>
) {
  return (
    <StylePopup ref={ref} {...props}>
      {props.children}
    </StylePopup>
  )
}

const StylePopup = styled(Popup)`
  .leaflet-popup-content {
    margin: 0px;
  }

  .leaflet-popup-content-wrapper {
    box-shadow: none;
    padding: 0px;
    background-color: transparent;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-close-button {
    display: none;
  }
`

export default React.forwardRef(PopupComponent)
