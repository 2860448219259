import request, { Mutation, MutationOptions, useMakeMutation } from '@/services'
import { Ammeter } from '../device.types'

export const useAddAmmeter = (options: MutationOptions = {}): Mutation =>
  useMakeMutation(
    (body: Ammeter) =>
      request('/device/ammeter', {
        method: 'POST',
        body,
      }),
    options
  )

export const useUpdateAmmeter = (options: MutationOptions = {}): Mutation =>
  useMakeMutation(
    (body: Ammeter) =>
      request('/device/ammeter/{id}', {
        method: 'PUT',
        body,
      }),
    options
  )

export const useDeleteAmmeter = (options: MutationOptions = {}): Mutation =>
  useMakeMutation(
    (body: Ammeter) =>
      request('/device/ammeter/{id}', {
        method: 'DELETE',
        body,
      }),
    options
  )
