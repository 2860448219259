import React from 'react'
import {
  Marker as MarkerComponent,
  MarkerProps,
  InfoBox,
  InfoWindow as MarkerInfoWindow,
} from '@react-google-maps/api'
import { eqProps, split } from 'ramda'
import { DeviceType } from '@/constants'
import {
  MapDeviceIdState,
  MapAlarmItemState,
  useGoogleMap,
} from './GoogleMapProvider'
import useVisible from '@/hooks/useVisible'
import InfoWindow from '../../components/InfoWindow'
import { ContextWindow, DeleteWindow } from '../../components/ContextWindow'
import { getMarkerIcon } from '../../components/Marker'
import { useRecoilState } from 'recoil'
import { ActionTypes, useUserColumns } from '@/auth'
import { Device } from '@lib/device'
import useRouteAuth from '@/routes/useRouteAuth'
import styled from 'styled-components'

export interface _MarkerProps extends Omit<MarkerProps, 'position'> {}

export type MapMarkerProps = _MarkerProps & {
  item: Device
  tooltip?: boolean
  toggle?: boolean
  onInfoBoxClick?: () => any
  draggable?: boolean
  deletable?: boolean
  readonly?: boolean
  isInGroup?: boolean
  showInfoWindow?: boolean
  showContextWindow?: boolean
  onLeftClick?: (v: any) => void
  onRightClick?: (v: any) => void
  onDrag?: (item: any) => any
  onDragEnd?: (item: any) => any
  onDelete?: (item: any) => any
  position?: {
    lat: number
    lng: number
  }
}

const infoboxOptions = {
  visible: true,
  alignBottom: false,
  disableAutoPan: false,
  enableEventPropagation: false,
  boxClass: 'style-info-box',
  boxStyle: {
    overflow: 'hidden',
  },
}

type MarkerControllerProps = {
  marker?: google.maps.Marker
  item: any
  handleClickMarker: () => void
}

// 避免 deviceId, alarmItem 異動時，造成 marker 重新 render，故獨立於一個 component 處理
const MarkerController = React.memo<MarkerControllerProps>(props => {
  const { marker, item, handleClickMarker } = props

  const { map } = useGoogleMap()

  const [deviceId] = useRecoilState(MapDeviceIdState)
  const [alarmItem] = useRecoilState(MapAlarmItemState)

  React.useEffect(() => {
    if (marker && alarmItem) {
      const matchMarker =
        eqProps('deviceType', item, alarmItem) && eqProps('id', item, alarmItem)

      if (matchMarker) {
        const latLng = marker.getPosition() as NonNullable<google.maps.LatLng>
        map?.setCenter(latLng)
        handleClickMarker()
      }
    }
  }, [marker, alarmItem]) //eslint-disable-line

  React.useEffect(() => {
    if (marker && deviceId) {
      const [deviceType, id] = split('-')(deviceId)

      const matchMarker =
        item.deviceType === deviceType && item.id === Number(id)

      if (matchMarker) {
        const latLng = marker.getPosition() as NonNullable<google.maps.LatLng>
        map?.setCenter(latLng)
        handleClickMarker()
      }
    }
  }, [marker, deviceId]) //eslint-disable-line

  return <></>
})

export function Marker({
  toggle = false,
  readonly = false,
  item,
  position,
  isInGroup = false,
  onInfoBoxClick,
  draggable = false,
  deletable = false,
  showInfoWindow = item.deviceType === DeviceType.LOCATION ? false : true,  //若是地點就不預設開啟,
  showContextWindow = false,
  tooltip = true,
  onDrag,
  onDragEnd,
  onDelete,
  onLeftClick,
  onRightClick,
  onMouseOver,
  onMouseOut,
  ...props
}: MapMarkerProps) {
  const [marker, setMarker] = React.useState<google.maps.Marker>()
  const [sampleDeviceInfoID] = React.useState<string>(
    `${item.deviceType}-${item.id}`
  )
  const [onMarkerDrag, setOnMarkerDrag] = React.useState(false)

  const { map, handleOpenedInfoBox, handleActivedMarker } = useGoogleMap()

  const [, checkIsAuthenticated] = useRouteAuth()

  const { userColumns } = useUserColumns()

  let intervalId = React.useRef<any>(null)

  let ref = React.useRef<InfoBox | null>(null)

  const infoWindow = useVisible()

  const contextWindow = useVisible()

  const tooltipWindow = useVisible()

  const icon = React.useMemo(
    () => getMarkerIcon(item, isInGroup),
    [item, isInGroup]
  )

  const addAnimation = React.useCallback((marker: any) => {
    const url = (marker.getIcon() as any)?.url
    const el = document.querySelector(`img[src="${url}"]`)
    if (el) {
      const _el = el.parentNode as HTMLElement
      setTimeout(() => _el.classList.add('icon-animation'))
    } else {
      setTimeout(() => addAnimation(marker), 500)
    }
  }, [])

  const renderMarker = React.useCallback((marker: any) => {
    const url = (marker.getIcon() as any)?.url
    const bindClass = (el: HTMLElement, className: any) =>
      setTimeout(() =>
        el.classList.add(
          'innmaxmap-marker',
          'googlemap',
          item.deviceType,
          className
        )
      )
    if (/(#normal)|(#alarm)$/.test(url)) {
      const el = document.querySelector(`#map img[src="${url}"]`)
      if (el) {
        clearInterval(intervalId.current)
        if (/#alarm$/.test(url)) {
          bindClass(el.parentNode as HTMLElement, 'alarm')
        } else {
          bindClass(el.parentNode as HTMLElement, 'normal')
        }
      }
    }
  }, []) // eslint-disable-line

  const handleLoad = React.useCallback(
    (marker: google.maps.Marker) => {
      if (marker) {
        setMarker(marker)
        // marker綁定class (renderMarker)
        renderMarker(marker)
        intervalId.current = setInterval(() => renderMarker(marker), 500)
      }
    },
    [renderMarker]
  )

  // 刪除marker綁定class (renderMarker)
  React.useEffect(() => {
    return () => clearInterval(intervalId.current)
  }, [])

  /**
   * 設備資訊
   */
  const handleClickMarker = React.useCallback(() => {
    onLeftClick && onLeftClick(item)
    if (showInfoWindow && !infoWindow.visible) {
      addAnimation(marker)
      // marker?.setAnimation(google.maps.Animation.BOUNCE)
      handleActivedMarker(marker)
      handleOpenedInfoBox(infoWindow)
      infoWindow.open()
    }
  }, [item, marker, contextWindow, infoWindow]) //eslint-disable-line

  /**
   * 路燈亮度調整
   */
  const hadnleRightClickMarker = React.useCallback(() => {
    onRightClick && onRightClick(item)
    const actionType = ActionTypes.UPDATABLE
    if (actionType && !checkIsAuthenticated(actionType)) {
      return null
    }
    if (deletable || showContextWindow) {
      handleActivedMarker(marker)
      handleOpenedInfoBox(contextWindow)
      contextWindow.open(sampleDeviceInfoID)
    }
  }, [item, marker, infoWindow, contextWindow]) //eslint-disable-line

  const handleMarkerDragEnd = React.useCallback(
    (e: any) => {
      const { latLng } = e
      setOnMarkerDrag(false)
      onDragEnd &&
        onDragEnd({
          ...item,
          lat: latLng.lat(),
          lon: latLng.lng(),
        })
    },
    [item] //eslint-disable-line
  )

  const handleMarkerDrag = React.useCallback(
    (e: any) => {
      const { latLng } = e
      setOnMarkerDrag(true)
      onDrag &&
      onDrag({
          ...item,
          lat: latLng.lat(),
          lon: latLng.lng(),
        })
    },
    [item] //eslint-disable-line
  )

  const handleMarkerDelete = React.useCallback(() => {
    onDelete && onDelete(item)
  }, [item]) //eslint-disable-line

  React.useEffect(() => {
    if (!ref || !ref?.current) {
      return
    }

    const onMouseLeave = () => {
      map?.setOptions({ gestureHandling: 'auto' })
    }

    const onMouseEnter = () => {
      map?.setOptions({ gestureHandling: 'none' })
    }

    const refElem = ref.current.containerElement

    refElem?.addEventListener('mouseleave', onMouseLeave)
    refElem?.addEventListener('mouseenter', onMouseEnter)

    return () => {
      if (ref?.current) { //eslint-disable-line
        refElem?.removeEventListener('mouseenter', onMouseEnter)
        refElem?.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  }, [ref, map, contextWindow.visible])

  const handleCancelInfoBox = React.useCallback(() => {
    map?.setOptions({ gestureHandling: 'auto', draggable: true })
    contextWindow.close()
  }, [contextWindow, map])

  return (
    <MarkerComponent
      position={{
        lat: item.lat,
        lng: item.lon,
      }}
      title={item?.name || item?.displayName}
      draggable={draggable}
      icon={{ url: icon, scaledSize: new google.maps.Size(28, 28) }}
      onLoad={handleLoad}
      onClick={handleClickMarker}
      onDrag={handleMarkerDrag}
      onDragEnd={handleMarkerDragEnd}
      onMouseOver={e => {
        !onMarkerDrag && tooltipWindow.open()
        onMouseOver && onMouseOver(e)
      }}
      onMouseOut={e => {
        tooltipWindow.close()
        onMouseOut && onMouseOut(e)
      }}
      onRightClick={readonly ? () => {} : hadnleRightClickMarker}
      {...props}>
      {tooltip && tooltipWindow.visible && (
        <MarkerInfoWindow
          ref={ref as any}
          anchor={undefined}
          options={{
            ...infoboxOptions,
            pixelOffset: new google.maps.Size(0, 5),
          }}>
          <StyledTooltipWrapper>
            <StyledTootipContent>{item?.name || item?.displayName}</StyledTootipContent>
            <StyledTooltipArrow></StyledTooltipArrow>
          </StyledTooltipWrapper>
        </MarkerInfoWindow>
      )}
      {infoWindow.visible && (
        <MarkerInfoWindow
          ref={ref as any}
          anchor={undefined}
          options={{
            ...infoboxOptions,
            pixelOffset: new google.maps.Size(90, 80),
          }}>
          <InfoWindow
            data={item}
            preference={userColumns[item?.deviceType]}
            onClose={() => {}}
          />
        </MarkerInfoWindow>
      )}
      {contextWindow.visible && (
        <MarkerInfoWindow
          ref={ref as any}
          options={{
            ...infoboxOptions,
            pixelOffset: deletable
              ? new google.maps.Size(100, 40)
              : new google.maps.Size(115, 70),
          }}>
          {deletable ? (
            <DeleteWindow
              data={item}
              onCancel={handleCancelInfoBox}
              deleteMarker={handleMarkerDelete}
            />
          ) : (
            <ContextWindow data={item} onCancel={handleCancelInfoBox} />
          )}
        </MarkerInfoWindow>
      )}
      <MarkerController
        marker={marker}
        handleClickMarker={handleClickMarker}
        item={item}
      />
    </MarkerComponent>
  )
}

const StyledTooltipWrapper = styled.div`
  position: relative;
`

const StyledTootipContent = styled.div`
  background-color: ${p => p.theme.yellow100};
  border-radius: 2px;
  font-size: 13px;
  font-weight: normal;
  padding: 3px 8px;
  min-width: 68px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
`

const StyledTooltipArrow = styled.div`
  background-color: transparent;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid ${p => p.theme.yellow100};
`

export default Marker
