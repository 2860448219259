import { useQuery } from 'react-query'
import { assocPath, keys, path, pathOr, toPairs, values } from 'ramda'
import React from 'react'
import {
  PAGE_SIZE,
  TYPE,
  xAxisName,
} from 'src/containers/ReportManagement/utils'
import { convertData } from '@/services'
import { AnalysisReport } from '.'
import { formatDate, isNil } from '@/utils/webHelper'
import { useTranslation } from 'react-i18next'

export const useAnalysisReport = (querys: { [key: string]: any }) => {
  // const { data, ...others } = useQuery(
  //   ['/report/powerplant/analysis', querys],
  //   {
  //     enabled: !isNil(querys),
  //   }
  // )

  const randomNumbers = () => {
    const numbers = []
    for (let i = 1; i < Number(new Date().getDate() + 1); i++) {
      numbers.push(
        {
          id: i,
          dateStr: `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${i}`,
          plantDatas: [
            {
              powerPlantSeq: 1,
              name: '向上店',
              value: {
                totalGeneration: Math.floor(Math.random() * 9999)
              }
            },
            {
              powerPlantSeq: 2,
              name: '明德店',
              value: {
                totalGeneration: Math.floor(Math.random() * 9999)
              }
            },
            {
              powerPlantSeq: 3,
              name: '虎林店',
              value: {
                totalGeneration: Math.floor(Math.random() * 9999)
              }
            }
          ]
        }
      )
    }
    return numbers
  }

  const data = React.useMemo(() => ({
    timestamp: 1656389212562,
    data: randomNumbers(),
    errorMsg: null
  }), [])
  const others = {
    isLoading: false
  }

  const { t } = useTranslation()

  const dataSource = React.useMemo(() => {
    const reportList = path(['data'], data) as AnalysisReport

    if (!reportList) {
      return { content: [], total: 0, totalPages: 0 }
    }
    const { reportType } = querys
    const currentTime = new Date().getTime()

    const powerPlantsData: any = {}
    const xAxis = reportList.filter(
      c => currentTime > new Date(c.dateStr).getTime()
    )
    xAxis.forEach(v => {
      v.plantDatas.forEach(data => {
        toPairs(data.value).forEach(([key, value]) => {
          const i18nText = t(`report:${key}`)
          const chartValues: any[] = pathOr(
            [],
            [i18nText, 'values'],
            powerPlantsData
          )
          chartValues.push({
            y: value,
            name: v.dateStr,
          })
          powerPlantsData[i18nText] = {
            name: i18nText,
            values: chartValues,
          }
        })
      })
    })

    let xLabels: (string | number)[] = []

    switch (reportType) {
      case TYPE.YEAR:
        xLabels = xAxis.map(i => {
          const m = new Date(i.dateStr).getMonth()
          return xAxisName(m)
        })
        break
      case TYPE.MONTH:
        xLabels = xAxis.map(i => new Date(i.dateStr).getDate())
        break
      case TYPE.DAY:
        xLabels = xAxis.map(i => formatDate(new Date(i.dateStr).valueOf()))
        break
      default:
    }

    const len = xAxis.length

    return {
      xAxisLabels: xLabels,
      total: len,
      totalPages: Math.ceil(len / PAGE_SIZE),
      powerPlantsData: values(powerPlantsData),
    }
  }, [data, querys, t])

  return {
    dataSource,
    ...others,
  }
}

export const useAnalysisList = (querys: { [key: string]: any }) => {
  // const { data, ...others } = useQuery(
  //   ['/report/powerplant/analysis', querys],
  //   {
  //     enabled: !isNil(querys),
  //   }
  // )

  const randomNumbers = () => {
    const numbers = []
    for (let i = 1; i < Number(new Date().getDate() + 1); i++) {
      numbers.push(
        {
          id: i,
          dateStr: `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${i}`,
          plantDatas: [
            {
              powerPlantSeq: 1,
              name: '向上店',
              value: {
                totalGeneration: Math.floor(Math.random() * 9999)
              }
            },
            {
              powerPlantSeq: 2,
              name: '明德店',
              value: {
                totalGeneration: Math.floor(Math.random() * 9999)
              }
            },
            {
              powerPlantSeq: 3,
              name: '虎林店',
              value: {
                totalGeneration: Math.floor(Math.random() * 9999)
              }
            }
          ]
        }
      )
    }
    return numbers
  }

  const data = React.useMemo(() => ({
    timestamp: 1656395698045,
    data: randomNumbers(),
    errorMsg: null
  }), [])
  const others = {
    isLoading: false
  }

  const { t } = useTranslation()

  const dataSource = React.useMemo(() => {
    const reportList = path(['data'], data) as AnalysisReport

    if (!reportList) {
      return {
        content: [],
        total: 0,
        totalPages: 0,
      }
    }

    const currentTime = new Date().getTime()

    let powerPlantsData: any = {}
    const availableReportValue = reportList.filter(
      r => currentTime > new Date(r.dateStr).getTime()
    )
    availableReportValue.forEach(v => {
      powerPlantsData = assocPath(
        [v.dateStr, 'createAtStr'],
        v.dateStr,
        powerPlantsData
      )

      v.plantDatas.forEach(plant => {
        toPairs(plant.value).forEach(([key, value]) => {
          powerPlantsData = assocPath([v.dateStr, key], value, powerPlantsData)
        })
      })
    })

    return convertData({
      converter: x => x,
    })({
      data: values(powerPlantsData),
    })
  }, [data])

  const columns = React.useMemo(() => {
    const reportList = path(['data'], data) as AnalysisReport

    return keys(pathOr({}, [0, 'plantDatas', 0, 'value'], reportList)).map(
      (key: string) => {
        return {
          title: t(`report:${key}`),
          dataIndex: key,
          key: key,
        }
      }
    )
  }, [data, t])

  return {
    dataSource,
    columns,
    ...others,
  }
}
