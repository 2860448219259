import { SVGProps } from 'react'
import cx from 'classnames'
import theme from '@assets/theme'

const EyeSVG = ({
  className,
  color = theme.icon.primary,
  ...props
}: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={cx('svg-icon icon-eye', className)}
    {...props}>
    <defs>
      <path
        id="a"
        d="M22.157 11.798c-.007-.132-.209-.78-.49-1.456-.288-.696-.776-1.704-1.45-2.545a9.632 9.632 0 0 0-3.24-2.623C15.423 4.394 13.605 4 11.575 4c-2.027 0-3.846.396-5.4 1.177a9.695 9.695 0 0 0-3.24 2.62c-.674.844-1.16 1.851-1.448 2.548-.28.675-.48 1.324-.487 1.455V11.809c.007.132.209.78.49 1.458.29.696.776 1.704 1.448 2.543a9.632 9.632 0 0 0 3.24 2.623c1.563.783 3.379 1.179 5.4 1.179 2.022 0 3.838-.396 5.4-1.18a9.688 9.688 0 0 0 3.238-2.62c.675-.84 1.163-1.849 1.451-2.545.281-.675.483-1.324.49-1.455v-.005-.01zm-1.739.004c-.134.408-.652 1.88-1.51 2.949l-.011.011a8 8 0 0 1-2.679 2.17c-1.324.664-2.89 1.002-4.65 1.002-1.763 0-3.326-.338-4.65-1.001a7.963 7.963 0 0 1-2.679-2.173c-.862-1.073-1.383-2.55-1.516-2.96.133-.408.654-1.882 1.516-2.96A8.01 8.01 0 0 1 6.92 6.67c1.327-.664 2.893-.999 4.655-.999 1.77 0 3.333.335 4.648.999a7.963 7.963 0 0 1 2.679 2.172c.862 1.078 1.382 2.553 1.516 2.96zm-8.843-4.08a4.067 4.067 0 0 0-4.062 4.062 4.068 4.068 0 0 0 4.062 4.061 4.068 4.068 0 0 0 4.062-4.061 4.067 4.067 0 0 0-4.062-4.062zm2.39 4.062c0 .637-.248 1.237-.7 1.69-.453.452-1.053.7-1.69.7a2.374 2.374 0 0 1-1.69-.7 2.374 2.374 0 0 1-.7-1.69c0-.638.248-1.238.7-1.69a2.374 2.374 0 0 1 1.69-.701c.638 0 1.238.248 1.69.7.452.453.7 1.053.7 1.69z"
      />
    </defs>
    <use fill={color} fillRule="nonzero" xlinkHref="#a" />
  </svg>
)

export default EyeSVG
