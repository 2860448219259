
const fake: any = {
  content: [
    {
      id: 1,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'xxx群組',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
    {
      id: 2,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'xxx群組',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
    {
      id: 3,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'invertor-113',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
    {
      id: 4,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'xxx群組',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
    {
      id: 5,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'xxx群組',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
    {
      id: 6,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'invertor-113',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
    {
      id: 7,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'invertor-113',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
    {
      id: 8,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'xxx群組',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
    {
      id: 9,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'invertor-113',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
    {
      id: 10,
      createAtStr: '2020.02.02 12:00',
      sessionName: '向上店',
      deviceType: '冰水主機',
      displayName: 'invertor-113',
      alarmMessage: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX…',
    },
  ]
}

export default fake
