import { SVGProps } from 'react'
import cx from 'classnames'

const SmallCardSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    className={cx('icon-smallcard', className)}
    {...props}>
    <g stroke="#4A4A4A" strokeWidth="1.2" fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <rect x=".6" y=".6" width="5.1" height="5.1" rx=".667" />
        <rect x="8.3" y=".6" width="5.1" height="5.1" rx=".667" />
      </g>
      <g transform="translate(1 8.7)">
        <rect x=".6" y=".6" width="5.1" height="5.1" rx=".667" />
        <rect x="8.3" y=".6" width="5.1" height="5.1" rx=".667" />
      </g>
    </g>
  </svg>
)

export default SmallCardSVG
