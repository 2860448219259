import { pathOr } from 'ramda'
import history from '@/routes/history'
import type { UserPermission } from './auth.types'
import { useAuth, ActionTypes } from '.'

export function useRouteCRUDAuthCheck() {
  const { user } = useAuth()

  const routeAuth = pathOr(
    {},
    ['authByUrl', history.location.pathname],
    user
  ) as UserPermission

  return [routeAuth, (actionType: ActionTypes) => routeAuth[actionType]] as [
    UserPermission,
    (act: string) => boolean
  ]
}
