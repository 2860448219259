import { useQuery } from 'react-query'
import { convertSessionData } from './session.utils'
import React from 'react'

export const useSessionStatistical = (query?: Record<string, any>) => {
  const { data, ...others } = useQuery(
    ['/sessionInfo/list/statistical', query],
    {
      // refetchInterval: 10 * 1000,
      // refetchOnMount: true,
      // refetchOnMount: true,
    }
  )
  const dataSource = React.useMemo(() => convertSessionData(data), [data])
  return {
    ...others,
    dataSource,
  }
}

export default useSessionStatistical
