import L, { Icon, IconOptions } from 'leaflet'
import { getMarkerIcon as _getMarkerIcon } from '../../components/Marker'

interface IconProps extends IconOptions {
  width?: number
  height?: number
}

export function createMarkerIcon({
  width = 28,
  height = 28,
  popupAnchor = [120, 140],
  iconUrl,
  ...props
}: IconProps) {
  return L.divIcon({
    iconSize: [width, height],
    iconAnchor: [width / 2, height],
    popupAnchor,
    html: `<div><img src="${iconUrl}"/></div>`,
  })
}

const getMarkerIcon = (
  d: any,
  iconOptions?: Record<string, any>,
  inDeviceList?: boolean
) => {
  const iconUrl = _getMarkerIcon(d, false, inDeviceList)

  return createMarkerIcon({
    ...iconOptions,
    iconUrl
  }) as Icon
}

export default getMarkerIcon
