import React from 'react'
import { always, cond, equals, path, pathOr, T } from 'ramda'
import { useAllSessions } from '@/session'
import { Device } from '@/device'
import { useLightEnums } from '@/light'
import { convertData } from '@/services'
import { toMoment, dateToYYYYMMDD } from '@/utils/webHelper'
import { useTranslation } from 'react-i18next'

export function useDeviceStatusEnums() {
  const { t } = useTranslation()
  const convertToActiveStr = React.useCallback(
    (active: number = -99) => {
      return cond([
        [equals(-1), always(t('common:delete'))],
        [equals(0), always(t('system:inactive'))],
        [equals(1), always(t('system:active'))],
        [T, always('')],
      ])(active)
    },
    [t]
  )

  const convertToWorkStatusStr = React.useCallback(
    (workStatus: number = -99) => {
      return cond([
        [equals(0), always(t('system:normal'))],
        [equals(1), always(t('system:alarm'))],
        [equals(2), always(t('system:broken'))],
        [T, always('')],
      ])(workStatus)
    },
    [t]
  )

  return {
    convertToActiveStr,
    convertToWorkStatusStr,
  }
}

export function useConvertData<T extends Device>() {
  const { convertToActiveStr, convertToWorkStatusStr } = useDeviceStatusEnums()
  const { byId, findStoreSession, getFullSessions } = useAllSessions()
  const { deviceTypeEnumByKey, getDeviceStatus } = useLightEnums()

  return (data: any) =>
    convertData<T>({
      converter: x => {
        const secondSessionId = getFullSessions(x.sessionId).length === 2 ? x.sessionId : pathOr('', [x.sessionId, 'parentSessionId'], byId)
        const session = pathOr({}, [secondSessionId], byId)
        const sessionName = pathOr('', ['name'], session)
        const prefix = pathOr(undefined, ['locationNs'], session)
        const locationNs = prefix ? `${prefix}/${sessionName}` : sessionName

        return {
          ...x,
          activeStr: convertToActiveStr(path(['active'], x)),
          workStatusStr: convertToWorkStatusStr(path(['workStatus'], x)),
          deviceStatusStr: getDeviceStatus(x),
          deviceTypeStr: pathOr('', [x.deviceType], deviceTypeEnumByKey),
          installTime: toMoment(x.installTime),
          installTimeStr: dateToYYYYMMDD(x.installTime),
          expiredDate: toMoment(x.expiredDate),
          expiredDateStr: dateToYYYYMMDD(x.expiredDate),
          sessionName,
          locationNs,
          storeSession: findStoreSession(x.sessionId),
          secondSessionId,
        }
      },
    })(data)
}
