import request, { Mutation, MutationOptions, useMakeMutation } from '@/services'
import { Chiller } from '../device.types'

export const useAddChiller = (options: MutationOptions = {}): Mutation =>
  useMakeMutation(
    (body: Chiller) =>
      request('/device/chiller', {
        method: 'POST',
        body,
      }),
    options
  )

export const useUpdateChiller = (options: MutationOptions = {}): Mutation =>
  useMakeMutation(
    (body: Chiller) =>
      request('/device/chiller/{id}', {
        method: 'PUT',
        body,
      }),
    options
  )

export const useDeleteChiller = (options: MutationOptions = {}): Mutation =>
  useMakeMutation(
    (body: Chiller) =>
      request('/device/chiller/{id}', {
        method: 'DELETE',
        body,
      }),
    options
  )
