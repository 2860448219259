import React, { CSSProperties } from 'react'
import { mergeRight } from 'ramda'
import { CRS, Map } from 'leaflet'
import { ImageOverlay, ZoomControl, MapContainer, useMapEvents } from 'react-leaflet'
import styled from 'styled-components'
import { MapCenter } from './map.types'
import { useLeafletMap } from './LeafletMapProvider'

export type MapProps = {
  mapUrl: string
  loading?: boolean
  renderLoading?: any
  whenMapReady?: (target: any) => any
  defaultCenter?: MapCenter
  center?: MapCenter
  zoom?: number
  minZoom?: number
  maxZoom?: number
  scrollWheelZoom?: boolean
  doubleClickZoom?: boolean
  children?: React.ReactNode
  style?: CSSProperties
  className?: string
  mapContainerStyle?: React.CSSProperties
  showControl?: boolean
  onClick?: (e: any) => void
}

export const defaultMinZoom = -1.0
export const defaultMaxZoom = 1.5

const InternalLeaftletMap: React.ForwardRefRenderFunction<HTMLElement, MapProps> = (
  (props, ref: React.Ref<any>) => {
    const {
      children,
      loading = false,
      renderLoading,
      mapUrl,
      whenMapReady,
      defaultCenter,
      center,
      zoom,
      minZoom = defaultMinZoom,
      maxZoom = defaultMaxZoom,
      doubleClickZoom = false,
      scrollWheelZoom = true,
      mapContainerStyle = {},
      showControl = true,
      onClick,
      ...restMapProps
    } = props;

    const mapRef = React.useRef<any>(null)
    const { handleMapLoad, } = useLeafletMap()
    const [mapCenter, setMapCenter] = React.useState<MapCenter | undefined>(defaultCenter)

    const [mapBounds, setMapBound] = React.useState<any>(null)

    React.useEffect(() => {
      const img = new Image()
      setMapBound(null)
      img.onload = function(this: any) {
        const scale = this.width >= 2000 ? 0.3 : this.width >= 3000 ? 0.2 : 0.4
        setMapBound([
          [0, 0],
          [this.height * scale, this.width * scale],
        ])
      }
      img.onerror = function() {
        setMapBound([
          [0, 0],
          [500, 500],
        ])
      }
      img.src = mapUrl
    }, [mapUrl])

    const handleWhenReady = ({ target: map }: { target: Map }) => {
      if (whenMapReady) {
        whenMapReady(map)
      }

      const _map = document.querySelectorAll<HTMLElement>('#map')!
      setTimeout(() => {
        _map.forEach(el => el.style.visibility = 'visible')
      }, 500)
      handleMapLoad(map)
      map.setMaxBounds(map.getBounds())
      mapRef.current = map
    }

    const eventHandlers = React.useMemo(
      () => ({
        load: (e: any) => {
          const el = document.getElementById("map")
          if (el) {
            el.style.visibility = 'visible'
          }
        },
      }),
      [] // eslint-disable-line
    )

    const MapEvents = () => {
      useMapEvents({
        click(e) {
          onClick && onClick(e)
        },
        dragend(e) {
          if (mapRef?.current) {
            const currentMapCenter = mapRef.current.getCenter()
            setMapCenter(currentMapCenter)
          }
        }
      })
      return null
    }

    if (loading) {
      return renderLoading ? renderLoading : <div>loading</div>
    }

    return (
      mapBounds ? (
        <StyleMapContainer
          {...restMapProps}
          id="map"
          crs={CRS.Simple}
          bounds={mapBounds}
          center={mapCenter}
          zoom={zoom}
          maxZoom={maxZoom}
          minZoom={minZoom}
          zoomDelta={0.5}
          zoomSnap={0.5}
          scrollWheelZoom={scrollWheelZoom}
          doubleClickZoom={doubleClickZoom}
          zoomControl={false}
          attributionControl={false}
          whenReady={handleWhenReady as any}
          style={mergeRight(mapStyle, mapContainerStyle)}>
          <MapEvents />
          <ImageOverlay url={mapUrl} bounds={mapBounds} eventHandlers={eventHandlers}/>
          {
            showControl && <ZoomControl position="bottomleft" />
          }
          {children}
        </StyleMapContainer>
      ) : <div></div>
    )
  }
)

const mapStyle = { height: '728px', width: '100%' }

const StyleMapContainer = styled(MapContainer)`
  visibility: hidden;

  .icon-animation {
    animation: bounce 0.9s;
    animation-iteration-count: infinite;
  }

  @keyframes bounce {
    0% {
      margin-top: -28px;
      animation-timing-function: ease-in;
    }

    50% {
      margin-top: -42px;
      animation-timing-function: ease-out;
    }

    100% {
      margin-top: -28px;
      animation-timing-function: ease-in;
    }
  }
`

export default React.forwardRef<HTMLElement, MapProps>(InternalLeaftletMap)
