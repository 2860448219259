import React from 'react'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import styled from 'styled-components'

const StyledTable: any = styled(Table)`
  .ant-table {
    background: transparent;
  }
  &.ant-table-wrapper {
    .ant-table table {
      border-spacing: 0 ${p => p.theme.table.spacing}px;
    }

    &.pagination--none .ant-table-pagination {
      display: none;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border-color: ${p => p.theme.pagination};
      border-radius: 4px;
      color: ${p => p.theme.pagination};
      background: ${p => p.theme.transparent};
    }

    .ant-pagination-item {
      border-color: ${p => p.theme.pagination};
      background: ${p => p.theme.transparent};
      border-radius: 4px;
    }

    .ant-pagination-item a {
      color: ${p => p.theme.pagination};
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active:focus,
    .ant-pagination-item:hover {
      border-color: ${p => p.theme.pagination};
      background: ${p => p.theme.pagination};

      a {
        color: #fff;
      }
    }

    .ant-table-pagination.ant-pagination {
      margin-top: 32px;
    }

    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: ${p => p.theme.pagination};
      background: ${p => p.theme.pagination};
      color: #fff;
    }

    .ant-pagination-simple-pager {
      color: ${p => p.theme.pagination};
    }

    &.bg--yellow {
      .ant-table-tbody {
        > tr:not(.ant-table-row-selected) > td {
          background: rgba(255, 241, 184, 0.2);
        }

        > tr:hover:not(.ant-table-row-selected) > td {
          background: rgba(255, 241, 184, 0.5);
        }
      }
    }
  }

  .ant-table-thead {
    line-height: 1em;

    > tr {
      background: transparent;
    }

    > tr > th {
      font-size: 13px;
      font-weight: normal;
      padding-bottom: 6px;
      color: ${p => p.theme.table.head};
      background: transparent;
      border-bottom-color: ${p => p.theme.table.head};
      &::before {
        display: none;
      }
    }

    > tr:first-child > th:first-child {
      border-top-left-radius: ${p => p.theme.table.shape};
      border-bottom-left-radius: ${p => p.theme.table.shape};
    }

    > tr:first-child > th:last-child {
      border-top-right-radius: ${p => p.theme.table.shape};
      border-bottom-right-radius: ${p => p.theme.table.shape};
    }
  }

  .ant-table-tbody {
    > tr.active > td {
      background: ${p => p.theme.table.bgHover};
    }

    > tr > td {
      height: 48px;
      padding-top: 7px;
      padding-bottom: 7px;
      line-height: 1em;
      background: transparent;
      color: ${p => p.theme.table.rowColor};
      border-top: 1px solid transparent;
      border-bottom: 1px solid ${p => p.theme.table.borderBottom};
    }

    > tr > td:first-child {
      border-left: 1px solid transparent;
    }

    > tr > td:last-child {
      border-right: 1px solid transparent;
    }

    > tr:hover:not(.ant-table-expanded-row) {
      background: ${p => p.theme.table.bgHover};

      > td {
        border-top: 1px solid ${p => p.theme.table.borderHover};
        border-bottom: 1px solid ${p => p.theme.table.borderBottom};
      }

      > td:first-child {
        border-left: 1px solid ${p => p.theme.table.borderHover};
      }

      > td:last-child {
        border-right: 1px solid ${p => p.theme.table.borderHover};
      }
    }
  }

  &.is--small .ant-table-tbody {
    > tr > td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &.is--xs {
    .ant-table-thead {
      > tr > th {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .ant-table-tbody {
      > tr > td {
        height: 32px;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .ant-pagination {
      margin-bottom: 0;
    }
  }

  &.pager--none {
    .ant-pagination {
      display: none;
    }
  }

  &.is--editable .ant-table-tbody {
    > tr > td {
      padding-top: 19px;
      padding-bottom: 19px;
    }
  }

  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: ${p => p.theme.table.bgHover};
  }

  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: ${p => p.theme.table.bgHover};
  }

  .ant-table-tbody > tr.ant-table-row-selected td {
    background: ${p => p.theme.yellow100};
  }

  .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${p => p.theme.checkbox.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${p => p.theme.checkbox.bg};
    border-color: ${p => p.theme.checkbox.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid ${p => p.theme.darkGrey};
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${p => p.theme.checkbox.indeterminate};
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 0 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }

  .ant-form-explain {
    font-size: 13px;
  }

  .has-error .ant-form-explain {
    position: absolute !important;
    font-size: 12px !important;
  }
`

class TableComponent<T extends any> extends React.Component<TableProps<T>> {
  static Column = Table.Column
  render() {
    return <StyledTable {...this.props}>{this.props.children}</StyledTable>
  }
}

export default TableComponent
