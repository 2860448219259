import React from 'react'
import { useQuery } from 'react-query'
import { pathOr, path, isEmpty } from 'ramda'
import { MutationOptions, request, useMakeMutation } from '@/services'
import { momentToString, toMoment } from '@/utils/webHelper'
import { publicUrl } from '@/env'
import { Profile } from '.'

export const profileAPI = async () => {
  return request('/system/profile')
}

export function useProfile() {
  const { data, ...others } = useQuery('system/profile', profileAPI, {
    cacheTime: Infinity,
  })

  const profile = React.useMemo(() => {
    if (!data || isEmpty(pathOr({}, ['data'], data))) {
      return {
        fileHash: '',
        filePash: '',
        name: '',
        url: `${publicUrl}/img/app-logo@3x.png`,
        expiryDate: '',
        expiryDateStr: '',
      } as Profile
    }

    const _data = path(['data'], data) as any

    return {
      ..._data,
      url: !!_data.url ? _data.url : `${publicUrl}/img/app-logo@3x.png`,
      language: _data.defaultLang,
      expiryDate: toMoment(_data.expirationAt),
      expiryDateStr: momentToString(toMoment(_data.expirationAt), 'YYYY/MM/DD'),
    } as Profile
  }, [data])

  return {
    ...others,
    profile,
    loading: others.isLoading,
  }
}

type EditProfileBody = {
  defaultLang: string
  fileHash: string
  name: string
}

export const editProfileAPI = (values: Partial<EditProfileBody>) =>
  request('/system/profile', { method: 'POST', body: values })

export const useEditProfile = (options: MutationOptions = {}) => {
  return useMakeMutation(editProfileAPI, options)
}
