import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultOptionType, SelectProps } from 'antd/lib/select'
import Select from '@components/ui/Select'
import { indexBy, pathOr, prop } from 'ramda'
import { Inverter, useDevices } from '@lib/device'
import { DeviceType } from '@lib/constants'

interface Props<T = any> extends SelectProps<T> {
  powerPlantId?: number
  gatewayId?: number
  placeholder?: string
  valueKey?: string
  onInverterSelected?: (inverter: Inverter) => void
}

const InverterSelect = React.memo(
  ({
    placeholder,
    valueKey = 'id',
    powerPlantId,
    gatewayId,
    onInverterSelected,
    onChange,
    ...props
  }: Props) => {
    const { t } = useTranslation()

    const [renderKey, setRenderKey] = React.useState(0)

    const { dataSource, loading } = useDevices<Inverter>(
      {
        deviceType: DeviceType.INVERTER,
        powerPlant: powerPlantId,
        gateway: gatewayId,
        pageNum: 0,
        pageSize: 999,
      },
      {
        enabled: !!powerPlantId || !!gatewayId,
      }
    )

    const inverterById = React.useMemo(
      () => indexBy(prop('id'), dataSource.content),
      [dataSource]
    )

    const handleOnChanged = (
      value: number,
      option: DefaultOptionType | DefaultOptionType[]
    ) => {
      onChange && onChange(value, option)
      onInverterSelected && onInverterSelected(inverterById[value])
    }

    React.useEffect(() => {
      setRenderKey(k => ++k)
    }, [powerPlantId, gatewayId])

    return (
      <Select<number>
        key={renderKey}
        {...props}
        onChange={handleOnChanged}
        loading={loading}
        notFoundContent={
          powerPlantId || gatewayId ? (
            <div className="text-white">無逆變器</div>
          ) : (
            <div className="text-white">請先選擇閘道器</div>
          )
        }
        placeholder={placeholder}>
        {powerPlantId || gatewayId
          ? dataSource.content.map(inverter => (
              <Select.Option
                key={inverter.id}
                value={pathOr('', [valueKey], inverter)}>
                {inverter.displayName}
              </Select.Option>
            ))
          : []}
      </Select>
    )
  }
)

export default InverterSelect
